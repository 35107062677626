<template>
  <v-app>
    <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition"
      v-model="snackbar" timeout="2000" :value="true" color="warning" absolute outlined top right>
      <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
      {{ errMsg }}
    </v-snackbar>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="px-sm-4">
        <v-toolbar dense class="elevation-0 mb-4 mt-6 pr-4">
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold headline">Clients</v-list-item-title>
                <v-list-item-subtitle class="body-1 mb-0 mt-1" color="#878B93">
                  Clients status and trade segments here.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field outlined dense class="mr-4 rounded-lg mt-9 d-none d-md-flex" style="max-width: 160px"
            color="gray" label="Search" v-model="searchText" prepend-inner-icon="mdi-magnify">
          </v-text-field>
          <v-card :color="`${tabval == 'oneday' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'oneday'))" width="40px"
            outlined class="mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center">
            <span :style="`color:${tabval == 'oneday' ? '#3B82F6' : 'black'};`" class="caption">1D</span>
          </v-card>
          <v-card :color="`${tabval == 'onemonth' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'onemonth'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'onemonth' ? '#3B82F6' : 'black'};`" class="caption">1M</span>
          </v-card>
          <v-card :color="`${tabval == 'threemonth' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'threemonth'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'threemonth' ? '#3B82F6' : 'black'};`" class="caption">3M</span>
          </v-card>
          <v-card :color="`${tabval == 'sixmonth' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'sixmonth'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'sixmonth' ? '#3B82F6' : 'black'};`" class="caption">6M</span>
          </v-card>
          <v-card :color="`${tabval == 'oneyear' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'oneyear'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'oneyear' ? '#3B82F6' : 'black'};`" class="caption">1Y</span>
          </v-card>
          <v-card :color="`${tabval == 'All' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'All'))" width="40px"
            outlined class="mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center">
            <span :style="`color:${tabval == 'All' ? '#3B82F6' : 'black'};`" class="caption">All</span>
          </v-card>
        </v-toolbar>

        <div class="px-4 pt-2">
          <!-- <div class="mb-4">
             <v-row>
              <v-col cols="6" md="2">
                <v-card @click="sortByall" height="146px" outlined width="100%">
                  <img src="@/assets/totids.svg" width="90px" class="mx-4 mb-2 mt-4">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Total Clients</v-list-item-subtitle>
                      <v-list-item-title>{{ tolcliids }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6" md="4">
                <v-row>
                  <v-col cols="12" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item @click="sortByActive">
                        <v-list-item-content>
                          <v-list-item-subtitle>Active Clients</v-list-item-subtitle>
                          <v-list-item-title>{{ Analysis_dataStatus["A"] > 0 ? Analysis_dataStatus["A"] : 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <img src="@/assets/totidsa.svg" width="30px" class="ml-4">
                        </v-list-item-icon>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined width="100%">
                      <v-list-item @click="sortByInactive">
                        <v-list-item-content>
                          <v-list-item-subtitle>Inactive Clients</v-list-item-subtitle>
                          <v-list-item-title>{{ Analysis_dataStatus["I"] > 0 ? Analysis_dataStatus["I"] : 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <img src="@/assets/totidsia.svg" width="28px" class="ml-4">
                        </v-list-item-icon>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="6" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Current month Reg.clients</v-list-item-subtitle>
                          <v-list-item-title>{{ Analysis_data["CURRENT_MONTH_CLIENTS"] > 0 ?
                            Analysis_data["CURRENT_MONTH_CLIENTS"] : 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Current month traded clients</v-list-item-subtitle>
                          <v-list-item-title>{{ lsttrdcounts["current_month_trader_count"] > 0 ?
                            lsttrdcounts["current_month_trader_count"] : 0 }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Last month Reg.clients</v-list-item-subtitle>
                          <v-list-item-title>{{ Analysis_data["PREVIOUS_MONTH_CLIENTS"] > 0 ?
                            Analysis_data["PREVIOUS_MONTH_CLIENTS"] : 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Last month traded clients</v-list-item-subtitle>
                          <v-list-item-title>{{ lsttrdcounts["previous_month_trader_count"] > 0 ?
                            lsttrdcounts["previous_month_trader_count"] : 0
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> 
          </div> -->
          <div class="pt-2">
            <v-data-table dense fixed-header class="dataTable" height="51vh" :sort-by="['REGISTRATION_DATE']"
              style="border: 1px solid rgba(0, 0, 0, 0.12)" sort-desc must-sort :items-per-page="100" :footer-props="{
                'items-per-page-options': [10, 30, 50, 100, -1],
              }" :loading="clientLoader" item-key="REGISTRATION_DATE" :headers="headers" :items="clientData"
              :search="searchText">
              <template v-slot:[`item.CLIENT_ID`]="{ item }">
                <span class="font-weight-bold">{{ item.CLIENT_ID }}</span>
              </template>
              <template v-slot:[`item.redirectdesk`]="{ item }">
                <v-btn :disabled="rediedload" icon @click="newtabfun(item)"><v-icon size="19">mdi-open-in-new</v-icon></v-btn>
              </template>
              <template v-slot:[`item.ACTIVE_INACTIVE`]="{ item }">
                <div style="color: #f43f5e" v-if="item.ACTIVE_INACTIVE == 'I'">
                  Inactive
                </div>
                <div style="color: #12b2a1" v-if="item.ACTIVE_INACTIVE == 'A'">
                  Active
                </div>
              </template>
              <template v-slot:[`item.COMPANY_CODE`]="{ item }">
                <span>
                  <span v-for="(tac, index) in item.EXCHANGE_ACTIVE_INACTIVE" :key="tac.A">
                    <!-- <span style="color: green;" > -->
                    &nbsp;
                    <v-chip class="px-2" v-if="tac == 'A'" x-small color="#F0FDFA" text-color="#12B2A1">
                      {{ index }}
                    </v-chip>
                    <v-chip class="px-2" v-else x-small color="#FEF2F2" text-color="#F43F5E">
                      {{ index }}
                    </v-chip>
                  </span>
                  <!-- </span> -->
                </span>
              </template>

            </v-data-table>
            <!-- <v-tabs v-model="pnltab" flat background-color="transparent" color="#3B82F6" active-class="font-weight-bold">
              <v-tab>
                <v-badge v-if="clientData.length" bordered color="#3B82F6" :content="clientData.length">
                  <span>My clients</span>
                </v-badge>
                <span v-else>My clients</span>
              </v-tab>
              <v-spacer></v-spacer>
              <v-btn text color="#878B93" class="mt-4">
                <img class="pr-2" src="@/assets/download icon.svg" width="25px" />Download
              </v-btn>
            </v-tabs>
            <v-tabs-items v-model="pnltab">
              <v-tab-item>
                <div class="trdditl">
                  
                </div>
              </v-tab-item>

               <v-tab-item>
                <div class="trdditl py-4">
                  <v-data-table dense fixed-header class="dataTable" :page.sync="page"
                    style="border: 1px solid rgba(0, 0, 0, 0.12)" :items-per-page="10" :loading="clientLoaderclient"
                    :headers="summaryhead" item-key="total" :items="clientWiseData" :search="searchText" must-sort
                    @page-count="pageCount = $event" hide-default-footer>
                    <template v-slot:[`footer`]>
                      <v-divider></v-divider>
                      <div class="text-center py-2">
                        <v-pagination :total-visible="7" v-model="page" :length="pageCount"></v-pagination>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item> 
            </v-tabs-items>-->
          </div>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { apiurl } from "../apiUrl";
import axios from "axios";
import { Base64 } from 'js-base64';

export default {
  data() {
    return {
      lastTradeloader: true,
      snackbar: false,
      clientData: [],
      topPerfom: [],
      worstPerfom: [],
      topPerfomClient: [],
      worstPerfomClient: [],
      topPerfomClientid: "",
      worstPerfomClientid: "",
      overalTotalClient: "",
      overalTotal: [],
      errMsg: "",

      clientidsindex: "",
      pnltab: null,
      usertotaldata: true,
      usernodata: false,

      summaryloader: true,
      daywiseloader: true,

      clientids: ["All"],
      emailDatafetch: [],

      newPnL: [],
      PnLSummary: [],
      PnLdaywise: [],
      tol_DW_mtm: 0,
      tol_Summ_mtm: 0,
      total_day_wise: 0,
      total_summary: 0,
      DW_qty: 0,
      Summ_qty: 0,
      // clientData:[]

      clientLoaderclient: false,
      clientLoader: true,
      clientDataRaw: [],
      Analysis_data: [],
      Analysis_dataState: [],
      Analysis_dataGender: [],
      Analysis_dataQual: [],
      Analysis_dataOccu: [],
      Analysis_dataCity: [],
      Analysis_dataAge: [],
      Analysis_dataStatus: [],
      compKey: [],
      compVey: [],
      lastTrade: [],

      datesummary: [],
      startDate: "",
      endDates: "",
      monthname: [],
      coractdata: true,
      nocoractdata: false,
      coractloader: false,
      values: [],
      dateselect: "",

      searchText: "",
      clientWiseData: [],

      chartval: "oneweek",
      tabval: "onemonth",
      tolcliids: 0,
      lsttrdcounts: {},
      rediedload:false
    };
  },

  mounted() {
    this.clientDetails();
    this.analyseData();
    this.getClifilterdata();
    this.lastTradecounr();
    //   this.getcalendar()
  },

  methods: {
    // chartFilter(val) {
    //   this.chartval = val;
    //   this.lastTradedApi();
    // },
    //     getcalendar() {
    //             this.coractdata = false;
    //             this.coractloader = true;
    //             this.values = [];
    //             this.monthname = [];
    //             this.colormis = [];
    //             let monthdate = (this.startDate, this.endDates);
    //             console.log('end year', this.startDate, this.endDates,)
    //             let newdate = [];
    //             console.log(this.monthname)
    //             for (let v = 0; v < monthdate.length; v++) {
    //                 this.database.push({ "counts": 0, "dates": monthdate[v] })
    //             }
    //             console.log("newdays", this.database)
    //             console.log("newdays", newdate)
    //             let val = this.database.sort(function (a, b) { return new Date(a.date) - new Date(b.date) })
    //             let nextval = val.filter((element) => new Date(element.dates).getFullYear() == this.dateselect)
    //             console.log('datevalues', val)

    //             let removedups = Object.values(nextval.reduce((object, item) => {
    //                 let key = item.dates;
    //                 if (!object[key]) {
    //                 object[key] = Object.assign(item);
    //                 } else {
    //                 object[key].counts += Object.assign(item.counts);
    //                 }
    //                 return object;
    //             }, {})
    //             );
    //             console.log(removedups)
    //             let newdayft = removedups.sort(function (c, d) { return new Date(c.dates) - new Date(d.dates) })
    //             let months = []

    //             for (let m = 0; m < newdayft.length; m++) {
    //                 months.push({ "date": new Date(newdayft[m].dates).toLocaleDateString("en-us", { month: "short", }) })
    //                 this.monthname = months.filter((v, i, a) => a.findIndex(v2 => (v2.date === v.date)) === i)
    //             }
    //             console.log("ft", newdayft)
    //             let ref = {};
    //             let res = newdayft.reduce(function (arr1, o) {
    //                 let m = new Date(o.dates).getMonth();
    //                 if (!(m in ref)) {
    //                 ref[m] = arr1.length;
    //                 arr1.push([]);
    //                 }
    //                 arr1[ref[m]].push(o);
    //                 return arr1;
    //             }, []);
    //             console.log("res", res)

    //             this.values = (res)
    //             if (this.values.length > 0) {
    //                 this.coractdata = true;
    //                 this.coractloader = false;
    //                 this.nocoractdata = false;
    //             }
    // },
    // lastTradedApi() {
    //   this.lastTradeloader = true;
    //   // this.clientLoader = true;
    //   let clientid = localStorage.getItem("clientid");
    //   let usession = localStorage.getItem('session')
    //   //   let d = new Date();
    //   //   let fyy = d.getFullYear();
    //   //   let fmm = (d.getMonth()+1);
    //   // //   let fdd = d.getDate();
    //   //   // let finYear = d.getFullYear()
    //   //   let formatedfrom = fyy+  "-" +fmm+"-" +"01";

    //   //   console.log("kolo",formatedfrom)

    //   //   let tod = new Date();
    //   //   let tyy = tod.getFullYear();
    //   //   let tmm = (tod.getMonth()+1);
    //   //   let tdd = tod.getDate();
    //   //   let formatedTo = tyy +  "-" +tmm+"-" +tdd;

    //   // console.log("kolo",formated)
    //   this.clientWiseData = [];
    //   this.lastTrade = [];
    //   // this.filterSeg = 'ALL'
    //   let data = JSON.stringify({
    //     // From_date: this.dateSelect + "-01",
    //     // From_date: formatedfrom,
    //     // To_date: formatedTo,
    //     // From_date: "2023-03-05",
    //     // To_date: "2023-04-05",
    //     'code': clientid,
    //     'filter': this.chartval
    //     // Year: finYear,
    //   });
    //   let axiosThis = this;
    //   let config = {
    //     method: "post",
    //     // url: "http://192.168.5.73:5512/fltr_last_trade_date",
    //     url: `${apiurl}/fltr_last_trade_date`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       'Authorization': usession
    //     },
    //     data: data,
    //   };

    //   axios(config)
    //     .then(function (response) {
    //       axiosThis.lastTradeloader = false;
    //       if (response.data.msg != "no data found") {
    //         console.log("kolo", (response.data));
    //         axiosThis.lastTrade = response.data;

    //         // axiosThis.clientWiseData = response.data.Client_wise_Remeshire_Brk;
    //         // axiosThis.topPerfomClient =
    //         //   response.data.Max_Client_wise_Remeshire_Brk;
    //         // axiosThis.topPerfomClientid =
    //         //   response.data.Max_Client_wise_Remeshire_Brk_client_id;

    //         // axiosThis.worstPerfomClient =
    //         //   response.data.Min_Client_wise_Remeshire_Brk;
    //         // axiosThis.worstPerfomClientid =
    //         //   response.data.Min_Client_wise_Remeshire_Brk_client_id;
    //       } else {
    //         axiosThis.errMsg = response.data.msg;
    //         axiosThis.snackbar = true;
    //       }
    //     })
    //     .catch(function (error) {
    //       axiosThis.clientLoader = false;
    //       console.log(error);
    //     });
    // },
    analyseData() {
      let clientCode = localStorage.getItem("clientid");
      let usession = localStorage.getItem("session");

      let data = JSON.stringify({
        code: clientCode,
      });
      let axiosThis = this;
      let config = {
        method: "post",
        url: `${apiurl}/analysis_data`,

        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log("analysis_data", response.data);
          if (response.data.msg != "No Data Found") {
            axiosThis.Analysis_data = response.data;
            axiosThis.Analysis_dataState = response.data.STATE;
            axiosThis.Analysis_dataGender = response.data.GENDER;
            axiosThis.Analysis_dataCity = response.data.CITY;
            axiosThis.Analysis_dataOccu = response.data.OCCUPATION;
            axiosThis.Analysis_dataQual = response.data.QUALIFICATION;
            axiosThis.Analysis_dataAge = response.data.AGE;
            axiosThis.Analysis_dataStatus =
              response.data.ACTIVE_INACTIVE_STATUS;

            console.log(axiosThis.Analysis_dataState);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sortByInactive() {
      const filteredList = this.clientDataRaw.filter(
        (obj) => obj.ACTIVE_INACTIVE === "I"
      );
      console.log(filteredList);
      this.clientData = filteredList;
    },
    sortByActive() {
      const filteredList = this.clientDataRaw.filter(
        (obj) => obj.ACTIVE_INACTIVE === "A"
      );
      console.log(filteredList);
      this.clientData = filteredList;
    },
    sortByall() {
      this.clientData = this.clientDataRaw;
      this.clientLoader = false;
    },
    clientDetails() {
      this.clientLoader = true;
      let axiosThis = this;
      let clientCode = localStorage.getItem("clientid");
      let usession = localStorage.getItem("session");

      let data = JSON.stringify({
        code: clientCode,
      });
      let config = {
        method: "post",
        url: `${apiurl}/Client_list`,
        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (
            response.data.msg == "Token is Expired." || response.data.msg == "This ID login in another device." ||
            response.data.msg == "Token is Invalid."
          ) {
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
            setTimeout(() => {
              axiosThis.$router.push("/");
            }, 2000);
          } else {
            if (response.data.msg != "No Data Found") {
              console.log(response.data);
              axiosThis.tolcliids = response.data.length;
              axiosThis.clientDataRaw = response.data;
              // let secset = [];
              // response.data.forEach(function (a) {
              //   if (!this[a.REGISTRATION_DATE.slice(0, 4)]) {
              //     this[a.REGISTRATION_DATE.slice(0, 4)] = { Year: a.REGISTRATION_DATE.slice(0, 4), Reg_clients: 1 };
              //     secset.push(this[a.REGISTRATION_DATE.slice(0, 4)]);
              //   }
              //   this[a.REGISTRATION_DATE.slice(0, 4)].Reg_clients += 1;
              // }, O         let secset = [];
              // response.data.forEach(function (a) {
              //   if (!this[a.REGISTRATION_DATE.slice(0, 4)]) {
              //     this[a.REGISTRATION_DATE.slice(0, 4)] = { Year: a.REGISTRATION_DATE.slice(0, 4), Reg_clients: 1 };
              //     secset.push(this[a.REGISTRATION_DATE.slice(0, 4)]);
              //   }
              //   this[a.REGISTRATION_DATE.slice(0, 4)].Reg_clients += 1;
              // }, Object.create(null));

              // console.log("firstset", firstset, secset)

              // for (let i=0;i < response.data.length;i++ ) {
              //     console.log(axiosThis.clientData[i].COMPANY_CODE)
              //     for(let x=0;x<axiosThis.clientData[i].COMPANY_CODE.length;x++) {
              // axiosThis.compKey.push(axiosThis.clientData[i]["COMPANY_CODE"])
              // axiosThis.compVal.push(axiosThis.clientData[i].Exchange_ACTIVE_INACTIVE)
              //     }
              // }
            } else {
              axiosThis.clientDataRaw = [];
            }
          }
          // axiosThis.Analysis_data = response.data.Analysis_data
          axiosThis.clientLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.clientLoader = false;
        });
    },
    lastTradecounr() {
      let clientCode = localStorage.getItem("clientid");
      let usession = localStorage.getItem("session");
      let data = JSON.stringify({
        code: clientCode,
      });

      let config = {
        method: "post",
        url: `${apiurl}/last_trade_date_counts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };
      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          console.log("last_trade_date_counts", response.data);
          axiosThis.lsttrdcounts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tableFilter(val) {
      if (val != this.tabval) {
        this.tabval = val;
        if (val == "All") {
          this.sortByall();
        } else {
          this.getClifilterdata();
        }
      }
    },
    getClifilterdata() {
      this.clientLoader = true;
      let clientCode = localStorage.getItem("clientid");
      let usession = localStorage.getItem("session");

      let data = JSON.stringify({
        code: clientCode,
        filter: this.tabval,
      });

      let config = {
        method: "post",
        url: `${apiurl}/Client_list_filter`,
        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };
      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          console.log("Client_list_filter", response.data);
          if (response.data) {
            axiosThis.clientData = response.data;
          }
          axiosThis.clientLoader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newtabfun(item) {
      this.rediedload = true
      const obj = { CLIENT_ID: item.CLIENT_ID };
      const jsonString = JSON.stringify(obj);
     var encodestr = Base64.encode(jsonString);
      const axios = require('axios');
      let data = JSON.stringify({
        data: encodestr
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
      url: `${apiurl}/session_gen`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
var axiosThis = this
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

axiosThis.rediedload = false;

if (response.data.msg === "success") {
  const decodedStr = Base64.decode(response.data.data);

const parsedResponse = JSON.parse(decodedStr);
const newvalue = JSON.parse(parsedResponse);

// console.log('parsedResponse (type):', typeof parsedResponse ,typeof newvalue);
const clientid = newvalue.clientid;
const token = newvalue.token;
// console.log("clientid:", clientid, "parsedResponse.clientid:", newvalue.clientid);
const url = `https://profile.mynt.in/?uid=${clientid}&token=${token}`;
window.open(url, '_blank');
   
} else {
    axiosThis.errMsg = response.data.msg;
    axiosThis.snackbar = true;
}
        })
        .catch((error) => {
          console.log(error);
          axiosThis.rediedload = false
          axiosThis.errMsg = error
          axiosThis.snackbar = true;
        });


    }
  },

  computed: {
    headers() {
      return [
        { text: "Client ID", value: "CLIENT_ID", sortable: false },
        { text: "Client Name", value: "CLIENT_NAME", width: "30%" },
        { text: "Status", value: "ACTIVE_INACTIVE", sortable: false },
        { text: "Exchange", value: "COMPANY_CODE", sortable: false },
        { text: "Reg date", value: "REGISTRATION_DATE", width: "20%" },
        { text: "Redirect", value: "redirectdesk",sortable: false  },

      ];
    },
    summaryhead() {
      return [
        // { text: "Name", value: "name" },
        { text: "ClientId", value: "Client_ID", width: "20%" },
        { text: "EQ", value: "NSE_CASH", width: "20%" },
        { text: "FNO", value: "NSE_FNO" },
        { text: "CDS", value: "CD_NSE" },
        { text: "MCX", value: "MCX", width: "20%" },
        { text: "TOTAL", value: "total" },
      ];
    },
  },
};
</script>

<style>
.colums {
  position: static;
  float: left;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.dataTable {
  overflow-y: scroll;
}

.dataTable thead.v-data-table-header {
  position: relative;
  z-index: 1 !important;
}

/* .headline { */
/* font-family: 'DM Sans' !important; */
/* font-weight: 500; */
/* } */
.trdditl::-webkit-scrollbar {
  width: 6px;
}

.trdditl::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.trdditl::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 16px;
}

.toolbar .v-toolbar__content {
  padding: 0 !important;
}

.datedetailshead .v-data-table-header__sort-badge {
  display: none !important;
}
</style>
<style lang="scss">
.colums {
  position: static;
  float: left;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0 !important;
}
</style>