<template>
  <v-app>
    <v-snackbar
      class="pt-6 pr-6"
      style="z-index: 6 !important"
      transition="slide-x-reverse-transition"
      v-model="snackbar"
      timeout="2000"
      :value="true"
      color="warning"
      absolute
      outlined
      top
      right
    >
      <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
      {{ errMsg }}
    </v-snackbar>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="px-sm-4">
        <v-toolbar dense class="elevation-0 mb-4 mt-6 pr-4">
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold headline"
                  >Statement</v-list-item-title
                >
                <v-list-item-subtitle class="body-1 mb-0 mt-1" color="#878B93">
                  A summary of activity over a particular financial year.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            style="max-width: 160px"
            dense
            outlined
            class="mt-9 rounded-lg d-none d-md-flex"
            color="gray"
            label="Search"
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
          >
          </v-text-field>
          <v-select
            style="max-width: 160px"
            outlined
            class="ml-4 mt-2 rounded-lg"
            dense
            color="gray"
            @change="perfomaceDataClientWise()"
            :items="allyears"
            label="Financial Year"
            v-model="ayfy"
            hide-details=""
          ></v-select>
        </v-toolbar>
        <div class="px-4">
          <!-- <v-row class="pt-2">
            <v-col cols="12" md="4">
              <v-card outlined width="100%">
                <v-list-item>
                  <v-list-item-icon>
                    <img src="@/assets/wallet.svg" width="60px">
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle>Ledger</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">&#x20B9; {{ topLedger > 0 ? topLedger.toLocaleString() :
                      0.00 }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="8" align-self="end">
              <v-toolbar flat>
                <v-spacer></v-spacer>
               
                <v-menu v-model="menu22" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field flat v-model="dateSelect2" label="From" hide-details dense class="ml-6 mt-10"
                    style="max-width:160px;" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  <p class="ma-0 px-8">{{date}} - Today</p>
                </template>
                <v-date-picker v-model="dateSelect2" :max="maxDate2"
                  @input="(menu22 = false), perfomaceDataClientWise()"></v-date-picker>
              </v-menu>
              <v-menu v-model="menu2to" :close-on-content-click="false" :nudge-left="150" transition="scale-transition"
                offset-y min-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field flat v-model="dateSelectto" label="To" hide-details dense class="ml-4 mt-8"
                    style="max-width:160px;" readonly v-bind="attrs" v-on="on"></v-text-field>
                  <p class="ma-0 px-8">{{date}} - Today</p>
                </template>
                <v-date-picker v-model="dateSelectto" :max="maxDate2"
                  @input="(menu2to = false), perfomaceDataClientWise()"></v-date-picker>
              </v-menu>

              </v-toolbar>
            </v-col>
          </v-row> -->
          <div class="trdditl py-4">
            <v-data-table
              height="51vh"
              dense
              fixed-header
              class="dataTable"
              style="border: 1px solid rgba(0, 0, 0, 0.12)"
              :items-per-page="100"
              :footer-props="{
                'items-per-page-options': [10, 30, 50, 100, -1],
              }"
              :loading="clientLoader"
              disable-sort
              :headers="headers"
              :items="overalTotalClient"
              :search="searchText"
            >
              <template v-slot:[`item.BILL_DATE`]="{ item }">
                <div v-if="item.BILL_DATE">
                  {{ item.BILL_DATE.slice(0, 10) }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.NET_RUNNING`]="{ item }">
                <div v-if="item.NET_RUNNING">
                  {{ item.NET_RUNNING?.toFixed(2) }}
                </div>
              </template>
              <template v-slot:[`item.VOUCHERDATE`]="{ item }">
                <div v-if="item.VOUCHERDATE">
                  {{ item.VOUCHERDATE.slice(0, 10) }}
                </div>
              </template>
              <template v-slot:[`item.CHQNO`]="{ item }">
                <div v-if="item.CHQNO">{{ item.CHQNO }}</div>
                <div v-else>-</div>
              </template>
              <!-- <template v-slot:[`item.DR_AMT`]="{ item }">
                      <div v-if="item.DR_AMT"> {{ item.DR_AMT?.toFixed(2) }}</div><div v-else>0.00</div>
                    </template> -->
              <!-- <template v-slot:[`item.CR_AMT`]="{ item }">
                      <div v-if="item.CR_AMT">{{ item.CR_AMT?.toFixed(2) }}</div><div v-else>0.00</div>
                    </template>
                 -->
            </v-data-table>
          </div>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { apiurl } from "../apiUrl";
import axios from "axios";
export default {
  data() {
    return {
      snackbar: false,
      clientData: [],
      topPerfom: [],
      worstPerfom: [],
      topPerfomClient: [],
      worstPerfomClient: [],
      topPerfomClientid: "",
      worstPerfomClientid: "",
      overalTotalClient: [],
      overalTotal: [],
      errMsg: "",
      menu2: false,
      menu22: false,
      menu2to: false,

      segments: ["ALL", "NSE_FNO", "NSE_CASH", "MCX", "CD_NSE"],
      dateSelect: new Date().toISOString().substr(0, 7),
      maxDate: new Date().toISOString().substr(0, 7),

      dateSelectto: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      dateSelect2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      maxDate2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      clientidsindex: "",
      pnltab: null,
      usertotaldata: true,
      usernodata: false,

      summaryloader: true,
      daywiseloader: true,

      clientids: ["All"],
      emailDatafetch: [],

      newPnL: [],
      PnLSummary: [],
      PnLdaywise: [],
      tol_DW_mtm: 0,
      tol_Summ_mtm: 0,
      total_day_wise: 0,
      total_summary: 0,
      DW_qty: 0,
      Summ_qty: 0,
      // clientData:[]
      clientLoaderclient: false,

      datesummary: [],
      startDate: "",
      endDates: "",
      monthname: [],
      coractdata: false,
      nocoractdata: false,
      coractloader: true,
      values: [],
      dateselect: "",
      years: [],
      fromdate: "",
      todate: "",
      searchText: "",

      topLedger: [],
      allyears: [],
      ayfy: "",
    };
  },

  created() {
    let getCurrentYear = new Date().getFullYear() + 1; // current year
    let listOfYears = Array.from({ length: 6 }, (_, i) => getCurrentYear - i);
    let lastyears = listOfYears.slice(0, 5);
    let firstyears = listOfYears.slice(-5);
    let allyears = [];
    // console.log("listOfYears", allyears, firstyears, lastyears);
    for (let i = 0; i < firstyears.length; i++) {
      allyears.push(`${firstyears[i]}-${lastyears[i]}`);
    }
    this.allyears = allyears.sort();
    this.ayfy = this.allyears[4];
  },

  methods: {
    perfomaceDataClientWise() {
      this.clientLoader = true;
      this.overalTotalClient = [];
      var clientid = localStorage.getItem("clientid");
      var usession = localStorage.getItem("session");
      // var d = new Date(this.dateSelect2);
      // var fyy = d.getFullYear();
      // var fmm = (d.getMonth() + 1);
      // // var fdd = d.getDate();
      // // var finYear = d.getFullYear()
      // var formatedfrom;
      // if (fmm < 4) {
      //   var finYear = fyy - 1;
      //   // formatedfrom = fdd + "-" + fmm + "-" + fyy;
      //   formatedfrom = "01" + "-" + "04" + "-" + finYear;
      //   this.dateSelect2 = finYear + "-04-01"
      // } else {
      //   finYear = d.getFullYear();
      //   // formatedfrom = fdd + "-" + fmm + "-" + fyy;
      //   formatedfrom = "01" + "-" + "04" + "-" + fyy;
      //   this.dateSelect2 = finYear + "-04-01"
      // }
      // var tod = new Date(this.dateSelectto);
      // var tyy = tod.getFullYear();
      // var tmm = (tod.getMonth() + 1);
      // var tdd = tod.getDate();
      // var formatedTo = tdd + "-" + tmm + "-" + tyy;
      // console.log(this.ayfy.slice(0,4), this.ayfy.slice(-4));

      let frmdate = `01-04-${this.ayfy.slice(0, 4)}`;
      let todate = `31-03-${this.ayfy.slice(-4)}`;

      // console.log("kolo",formated)
      // this.filterSeg = 'ALL'
      var data = JSON.stringify({
        // From_date: this.dateSelect + "-01",
        From_date: frmdate,
        To_date: todate,
        code: clientid,
        Year: this.ayfy.slice(0, 4),
      });
      var axiosThis = this;
      var config = {
        method: "post",
        // url: "http://192.168.5.163:5512/ledger",
        url: `${apiurl}/ledger`,
        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (
            response.data.msg == "Token is Expired." ||
            response.data.msg == "Token is Invalid."
          ) {
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
            setTimeout(() => {
              axiosThis.$router.push("/");
            }, 2000);
          } else {
            if (response.data.msg != "no data found") {
              console.log("ledger", response.data);
              for (let st = 0; st < response.data.values.length; st++) {
                if (
                  response.data.values[st].BILL_DATE != "" ||
                  response.data.values[st].DR_AMT != "" ||
                  response.data.values[st].CR_AMT != ""
                ) {
                  axiosThis.overalTotalClient.push(response.data.values[st]);
                }
              }
              // var lengthofarr = (response.data.length - 1)
              axiosThis.topLedger =
                response.data.max_ledger_balance?.toFixed(2);
            } else {
              axiosThis.overalTotalClient = [];
              axiosThis.errMsg = response.data.msg;
              axiosThis.snackbar = true;
            }
          }
          axiosThis.clientLoader = false;
        })
        .catch(function (error) {
          axiosThis.clientLoader = false;

          console.log(error);
        });
    },
    timesetting() {
      var d = new Date();
      this.dateSelect2 = new Date(d - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },

  mounted() {
    this.timesetting();
    // this.perfomaceData();
    this.perfomaceDataClientWise();
  },

  computed: {
    headers() {
      return [
        { text: "Bill date", value: "BILL_DATE" },
        { text: "V type", value: "TRANS_TYPE1" },
        { text: "V no", value: "VOUCHERNO" },
        { text: "Segments", value: "COCD" },
        { text: "Narration", value: "CTRNAME" },
        { text: "Dr amt", value: "DR_AMT", align: "right" },
        { text: "Cr amt", value: "CR_AMT", align: "right" },
        { text: "Net", value: "NET_RUNNING", align: "right" },
      ];
    },
    summaryhead() {
      return [
        { text: "ClientId", value: "Client_ID", width: "20%" },
        { text: "EQ", value: "NSE_CASH", width: "20%" },
        { text: "FNO", value: "NSE_FNO" },
        { text: "CDS", value: "CD_NSE" },
        { text: "MCX", value: "MCX", width: "20%" },
        { text: "TOTAL", value: "total" },
      ];
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px !important;
}

.dataTable::-webkit-scrollbar {
  width: 0;
  display: none;
}

.dataTable .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  /* color of the tracking area */
}

.dataTable .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6) !important;
  border-radius: 16px !important;
}

.dataTable .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px !important;
}

/* .trdditl::-webkit-scrollbar {
  width: 6px !important;
}

.trdditl::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.trdditl::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6) !important;
  border-radius: 16px !important;
} */

.toolbar .v-toolbar__content {
  padding: 0 !important;
}

.datedetailshead .v-data-table-header__sort-badge {
  display: none !important;
}
</style>
<style lang="scss">
.colums {
  position: static;
  float: left;
}
</style>
