var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-snackbar',{staticClass:"pt-6 pr-6",staticStyle:{"z-index":"6 !important"},attrs:{"transition":"slide-x-reverse-transition","timeout":"2000","value":true,"color":"warning","absolute":"","outlined":"","top":"","right":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-alert-outline")]),_vm._v(" "+_vm._s(_vm.errMsg)+" ")],1),_c('v-container',{staticClass:"pa-0 pa-sm-3 pa-md-3"},[_c('div',{staticClass:"px-sm-4"},[_c('v-toolbar',{staticClass:"elevation-0 mb-4 mt-6 pr-4",attrs:{"dense":""}},[_c('v-toolbar-title',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold headline"},[_vm._v("Revenue")]),_c('v-list-item-subtitle',{staticClass:"body-1 mb-0 mt-1",attrs:{"color":"#878B93"}},[_vm._v(" Sum of day and client wise brokerage here. ")])],1)],1)],1),_c('v-spacer'),(_vm.pnltab == 1)?_c('v-text-field',{staticClass:"mr-4 mt-9 rounded-lg d-none d-md-flex",staticStyle:{"max-width":"160px"},attrs:{"dense":"","outlined":"","flat":"","color":"gray","label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),(_vm.pnltab == 0)?_c('div',[_c('v-tooltip',{attrs:{"open-delay":"400","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'currentmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.daytableFilter((_vm.val = 'currentmonth'))}}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'currentmonth' ? '#3B82F6' : 'black'
                  };`)},[_vm._v("CM")])])]}}],null,false,3849245472)},[_c('span',[_vm._v("Current month")])])],1):_vm._e(),(_vm.pnltab == 0)?_c('div',[_c('v-tooltip',{attrs:{"open-delay":"400","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'lastmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.daytableFilter((_vm.val = 'lastmonth'))}}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'lastmonth' ? '#3B82F6' : 'black'
                  };`)},[_vm._v("LM")])])]}}],null,false,1257870920)},[_c('span',[_vm._v("Last month")])])],1):_vm._e(),(_vm.pnltab == 0)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'threemonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.daytableFilter((_vm.val = 'threemonth'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'threemonth' ? '#3B82F6' : 'black'
            };`)},[_vm._v("3M")])]):_vm._e(),(_vm.pnltab == 0)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'sixmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.daytableFilter((_vm.val = 'sixmonth'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'sixmonth' ? '#3B82F6' : 'black'};`)},[_vm._v("6M")])]):_vm._e(),(_vm.pnltab == 0)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'oneyear' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.daytableFilter((_vm.val = 'oneyear'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'oneyear' ? '#3B82F6' : 'black'};`)},[_vm._v("1Y")])]):_vm._e(),(_vm.pnltab == 0)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":250,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center",attrs:{"color":`${_vm.daytabval == 'custom' ? '#EFF6FF' : ''}`,"width":"48px","outlined":""}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.daytabval == 'custom' ? '#3B82F6' : 'black'
                };`)},[_vm._v("CUST")])])]}}],null,false,3281093628),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate,"type":"month"},on:{"input":function($event){(_vm.menu2 = false), _vm.dayCustom()}},model:{value:(_vm.dateSelect),callback:function ($$v) {_vm.dateSelect=$$v},expression:"dateSelect"}})],1):_vm._e(),(_vm.pnltab == 1)?_c('div',[_c('v-tooltip',{attrs:{"open-delay":"400","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'currentmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.clitableFilter((_vm.val = 'currentmonth'))}}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'currentmonth' ? '#3B82F6' : 'black'
                  };`)},[_vm._v("CM")])])]}}],null,false,932315034)},[_c('span',[_vm._v("Current month")])])],1):_vm._e(),(_vm.pnltab == 1)?_c('div',[_c('v-tooltip',{attrs:{"open-delay":"400","color":"black","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'lastmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.clitableFilter((_vm.val = 'lastmonth'))}}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'lastmonth' ? '#3B82F6' : 'black'
                  };`)},[_vm._v("LM")])])]}}],null,false,479991666)},[_c('span',[_vm._v("Last month")])])],1):_vm._e(),(_vm.pnltab == 1)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'threemonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.clitableFilter((_vm.val = 'threemonth'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'threemonth' ? '#3B82F6' : 'black'
            };`)},[_vm._v("3M")])]):_vm._e(),(_vm.pnltab == 1)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'sixmonth' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.clitableFilter((_vm.val = 'sixmonth'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'sixmonth' ? '#3B82F6' : 'black'};`)},[_vm._v("6M")])]):_vm._e(),(_vm.pnltab == 1)?_c('v-card',{staticClass:"mt-2 py-2 elevation-0 rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'oneyear' ? '#EFF6FF' : ''}`,"width":"40px","outlined":""},on:{"click":function($event){_vm.clitableFilter((_vm.val = 'oneyear'))}}},[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'oneyear' ? '#3B82F6' : 'black'};`)},[_vm._v("1Y")])]):_vm._e(),(_vm.pnltab == 1)?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center",attrs:{"color":`${_vm.clitabval == 'custom' ? '#EFF6FF' : ''}`,"width":"48px","outlined":""}},'v-card',attrs,false),on),[_c('span',{staticClass:"caption",style:(`color:${_vm.clitabval == 'custom' ? '#3B82F6' : 'black'
                };`)},[_vm._v("CUST")])])]}}],null,false,3424614908),model:{value:(_vm.menu22),callback:function ($$v) {_vm.menu22=$$v},expression:"menu22"}},[_c('v-card',{attrs:{"width":"580px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate2},model:{value:(_vm.dateSelect2),callback:function ($$v) {_vm.dateSelect2=$$v},expression:"dateSelect2"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate2},on:{"input":function($event){(_vm.menu22 = false), (_vm.menu2to = false), _vm.cliCustom()}},model:{value:(_vm.dateSelectto),callback:function ($$v) {_vm.dateSelectto=$$v},expression:"dateSelectto"}})],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"px-4 pt-2"},[_c('div',{staticClass:"pt-2"},[_c('v-tabs',{attrs:{"flat":"","background-color":"transparent","color":"#3B82F6","active-class":"font-weight-bold"},model:{value:(_vm.pnltab),callback:function ($$v) {_vm.pnltab=$$v},expression:"pnltab"}},[_c('v-tab',{on:{"click":function($event){(_vm.menu22 = false), (_vm.menu2to = false)}}},[(_vm.clientData.length)?_c('v-badge',{attrs:{"bordered":"","color":"#3B82F6","content":_vm.clientData.length}},[_c('span',[_vm._v("Day wise")])]):_c('span',[_vm._v("Day wise")])],1),_c('v-tab',{on:{"click":function($event){_vm.menu2 = false}}},[(_vm.clientWiseData.length)?_c('v-badge',{attrs:{"bordered":"","color":"#3B82F6","content":_vm.clientWiseData.length}},[_c('span',[_vm._v("Client wise")])]):_c('span',[_vm._v("Client wise")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.pnltab),callback:function ($$v) {_vm.pnltab=$$v},expression:"pnltab"}},[_c('v-tab-item',[_c('div',{staticClass:"trdditl"},[_c('v-data-table',{staticClass:"dataTable",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"height":"51vh","dense":"","fixed-header":"","sort-by":['Trade_Date'],"sort-desc":"","must-sort":"","items-per-page":100,"footer-props":{
                    'items-per-page-options': [10, 30, 50, 100, -1],
                  },"loading":_vm.clientLoader,"headers":_vm.headers,"items":_vm.clientData},scopedSlots:_vm._u([{key:`item.Trade_Date`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Trade_Date.slice(0, 10)))])]}},{key:`item.total`,fn:function({ item }){return [(item.total)?_c('div',[_vm._v(_vm._s(item.total?.toFixed(2)))]):_c('div',[_vm._v("0.00")])]}},{key:`item.NSE_CASH`,fn:function({ item }){return [(item.NSE_CASH)?_c('div',[_vm._v(" "+_vm._s(item.NSE_CASH?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.NSE_FNO`,fn:function({ item }){return [(item.NSE_FNO)?_c('div',[_vm._v(" "+_vm._s(item.NSE_FNO?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.CD_NSE`,fn:function({ item }){return [(item.CD_NSE)?_c('div',[_vm._v(" "+_vm._s(item.CD_NSE?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.MCX`,fn:function({ item }){return [(item.MCX)?_c('div',[_vm._v(_vm._s(item.MCX?.toFixed(2)))]):_c('div',[_vm._v("0.00")])]}}],null,true)})],1)]),_c('v-tab-item',[_c('div',{staticClass:"trdditl"},[_c('v-data-table',{staticClass:"dataTable",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"height":"51vh","dense":"","fixed-header":"","items-per-page":100,"footer-props":{
                    'items-per-page-options': [10, 30, 50, 100, -1],
                  },"loading":_vm.clientLoaderclient,"headers":_vm.summaryhead,"items":_vm.clientWiseData,"search":_vm.searchText,"must-sort":""},scopedSlots:_vm._u([{key:`item.Client_ID`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Client_ID))])]}},{key:`item.total`,fn:function({ item }){return [(item.total)?_c('div',[_vm._v(_vm._s(item.total?.toFixed(2)))]):_c('div',[_vm._v("0.00")])]}},{key:`item.NSE_CASH`,fn:function({ item }){return [(item.NSE_CASH)?_c('div',[_vm._v(" "+_vm._s(item.NSE_CASH?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.NSE_FNO`,fn:function({ item }){return [(item.NSE_FNO)?_c('div',[_vm._v(" "+_vm._s(item.NSE_FNO?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.CD_NSE`,fn:function({ item }){return [(item.CD_NSE)?_c('div',[_vm._v(" "+_vm._s(item.CD_NSE?.toFixed(2))+" ")]):_c('div',[_vm._v("0.00")])]}},{key:`item.MCX`,fn:function({ item }){return [(item.MCX)?_c('div',[_vm._v(_vm._s(item.MCX?.toFixed(2)))]):_c('div',[_vm._v("0.00")])]}}],null,true)})],1)])],1)],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }