<template>
  <v-app>
    <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition" v-model="snackbar"
      timeout="2000" :value="true" color="warning" absolute outlined top right>
      <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
      {{ errMsg }}
    </v-snackbar>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="px-sm-4">
        <v-toolbar dense class="elevation-0 mb-4 mt-6 pr-4">
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold headline">Account opening</v-list-item-title>
                <v-list-item-subtitle class="body-1 mb-0 mt-1" color="#878B93">
                  New openings of clients and process pending details here.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field outlined dense class="mt-9 rounded-lg mr-4 d-none d-md-flex" style="max-width: 160px" color="gray"
            label="Search" v-model="searchText" prepend-inner-icon="mdi-magnify">
          </v-text-field>

          <v-tooltip open-delay="400" color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs" v-on="on" :color="`${tabval == 'currentmonth' ? '#EFF6FF' : ''}`"
                @click="tableFilter((val = 'currentmonth'))" width="40px" outlined
                class="mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center">
                <span :style="`color:${tabval == 'currentmonth' ? '#3B82F6' : 'black'
                  };`" class="caption">CM</span>
              </v-card>
            </template>
            <span>Current month</span>
          </v-tooltip>

          <v-tooltip open-delay="400" color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs" v-on="on" :color="`${tabval == 'lastmonth' ? '#EFF6FF' : ''}`"
                @click="tableFilter((val = 'lastmonth'))" width="40px" outlined
                class="mt-2 py-2 elevation-0 rounded-0 text-center">
                <span :style="`color:${tabval == 'lastmonth' ? '#3B82F6' : 'black'
                  };`" class="caption">LM</span>
              </v-card>
            </template>
            <span>Last month</span>
          </v-tooltip>

          <v-card :color="`${tabval == 'threemonth' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'threemonth'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'threemonth' ? '#3B82F6' : 'black'};`" class="caption">3M</span>
          </v-card>
          <v-card :color="`${tabval == 'sixmonth' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'sixmonth'))"
            width="40px" outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${tabval == 'sixmonth' ? '#3B82F6' : 'black'};`" class="caption">6M</span>
          </v-card>
          <v-card :color="`${tabval == 'oneyear' ? '#EFF6FF' : ''}`" @click="tableFilter((val = 'oneyear'))" width="40px"
            outlined class="mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center">
            <span :style="`color:${tabval == 'oneyear' ? '#3B82F6' : 'black'};`" class="caption">1Y</span>
          </v-card>
        </v-toolbar>
        <div class="px-4 pt-2">
          <v-tabs v-model="pnltab" flat background-color="transparent" color="#3B82F6" active-class="font-weight-bold">
            <v-tab>
              <v-badge v-if="clientpending.length" bordered color="#3B82F6" :content="clientpending.length">
                <span>Client Pending</span>
              </v-badge>
              <span v-else>Client Pending</span>
            </v-tab>
            <v-tab>
              <v-badge v-if="processpending.length" bordered color="#3B82F6" :content="processpending.length">
                <span>Process Pending</span>
              </v-badge>
              <span v-else>Process Pending</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="pnltab">
            <v-tab-item>
              <div class="trdditl">
                <v-data-table height="51vh" dense fixed-header class="dataTable" style="border: 1px solid #efeef3"
                  sort-desc must-sort item-key="Serial_no" :items-per-page="100" :footer-props="{
                    'items-per-page-options': [10, 30, 50, 100, -1],
                  }" :loading="clientLoader" :headers="headers" :items="clientpending" :search="searchText">
                  <template v-slot:[`item.days_difference`]="{ item }">
                    {{ item.days_difference }} days ago
                  </template>
                  <!--  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-stepper flat class="mx-5 pa-0 elevation-0">
                        <v-stepper-step complete class="mx-5 pa-0 inlinebb" step="1">
                          Mobile otp
                        </v-stepper-step>

                        <v-divider class="inlinebb"></v-divider>

                        <v-stepper-step :complete="item.emailVerifyTime" class="mx-5 pa-0 inlinebb" step="2">
                          Email otp
                        </v-stepper-step>

                        <v-divider class="inlinebb"></v-divider>

                        <v-stepper-step :complete="item.panVerifyTime" class="mx-5 pa-0 inlinebb" step="3">
                          PAN verify
                        </v-stepper-step>
                        <v-divider class="inlinebb"></v-divider>
                        <v-stepper-step v-if="item.bank_verify.bankverify" :complete="item.bank_verify.bankverify"
                          class="mx-5 pa-0 inlinebb" step="3">
                          Bank verify
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Bank verify
                        </v-stepper-step>
                        <v-stepper-step v-if="item.documentUploadVerifyTime" :complete="item.documentUploadVerifyTime"
                          class="mx-5 pa-0 inlinebb" step="3">
                          Document Upload
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Document Upload
                        </v-stepper-step>

                        <v-stepper-step v-if="item.activestatus == 'reviewcomplete'"
                          :complete="item.documentUploadVerifyTime" class="mx-5 pa-0 inlinebb" step="3">
                          Completed
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Not Completed
                        </v-stepper-step>
                      </v-stepper>
                    </td>
                  </template>
                  <template v-slot:[`item.pan`]="{ item }">
                    <v-tooltip v-if="item.panVerifyTime && item.pan" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.pan }}</span>
                        <v-icon v-if="item.panVerifyTime && item.pan" color="success"
                          size="small">mdi-check-circle</v-icon>

                      </template><span>
                        <div class="text-center">Pan verified: </div>{{ item.panVerifyTime }}
                      </span>
                    </v-tooltip>

                    <v-tooltip v-else-if="item.pan" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.pan }}</span>
                        <v-icon color="error" size="small">mdi-close-circle</v-icon>

                      </template><span>
                        <div class="text-center">Pan Not verified</div>
                      </span>
                    </v-tooltip>

                  </template>
                  <template v-slot:[`item.mobile`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.mobile }}</span>
                        <v-icon v-if="item.mobileVerifyTime" color="success" size="small">mdi-check-circle</v-icon>

                      </template><span>
                        <div class="text-center">Mobile verified: </div>{{ item.mobileVerifyTime }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.aadhar_no`]="{ item }">
                    <div v-if="item.aadhar_no">
                      <v-tooltip v-if="item.aadhar_seeding_status == 'yes'" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1">{{ item.aadhar_no }}</span>
                          <v-icon v-if="item.aadhar_seeding_status && item.aadhar_no" color="success"
                            size="small">mdi-check-circle</v-icon>
                        </template><span>
                          <div class="text-center">Aadhar verified</div>
                        </span>
                      </v-tooltip>
                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1">{{ item.aadhar_no }}</span>
                          <v-icon color="error" size="small">mdi-close-circle</v-icon>

                        </template><span>
                          <div class="text-center">Aadhar Not verified</div>
                        </span>
                      </v-tooltip>
                    </div>

                    <span class="mr-1">{{ item.aadhar_no }}</span>
                  </template> -->
                </v-data-table>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="trdditl">
                <v-data-table height="51vh" dense fixed-header class="dataTable" style="border: 1px solid #efeef3"
                  sort-desc must-sort item-key="Serial_no" :items-per-page="100" :footer-props="{
                    'items-per-page-options': [10, 30, 50, 100, -1],
                  }" :loading="processLoader" :headers="headers" :items="processpending" :search="searchText">
                  <template v-slot:[`item.days_difference`]="{ item }">
                    {{ item.days_difference }} days ago
                  </template>
                  <!-- <template v-slot:[`item.activestatus`]="{ item }">
                    {{ item.pan }}
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-stepper flat class="mx-5 pa-0 elevation-0">
                        <v-stepper-step complete class="mx-5 pa-0 inlinebb" step="1">
                          Mobile otp
                        </v-stepper-step>

                        <v-divider class="inlinebb"></v-divider>

                        <v-stepper-step :complete="item.emailVerifyTime" class="mx-5 pa-0 inlinebb" step="2">
                          Email otp
                        </v-stepper-step>

                        <v-divider class="inlinebb"></v-divider>

                        <v-stepper-step :complete="item.panVerifyTime" class="mx-5 pa-0 inlinebb" step="3">
                          PAN verify
                        </v-stepper-step>
                        <v-divider class="inlinebb"></v-divider>
                        <v-stepper-step v-if="item.bank_verify.bankverify" :complete="item.bank_verify.bankverify"
                          class="mx-5 pa-0 inlinebb" step="3">
                          Bank verify
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Bank verify
                        </v-stepper-step>
                        <v-stepper-step v-if="item.documentUploadVerifyTime" :complete="item.documentUploadVerifyTime"
                          class="mx-5 pa-0 inlinebb" step="3">
                          Document Upload
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Document Upload
                        </v-stepper-step>

                        <v-stepper-step v-if="item.activestatus == 'reviewcomplete'"
                          :complete="item.documentUploadVerifyTime" class="mx-5 pa-0 inlinebb" step="3">
                          Completed
                        </v-stepper-step>
                        <v-stepper-step v-else :rules="[() => false]" class="mx-5 pa-0 inlinebb" step="3">
                          Not Completed
                        </v-stepper-step>
                      </v-stepper>
                    </td>
                  </template>
                  <template v-slot:[`item.pan`]="{ item }">
                    <v-tooltip v-if="item.panVerifyTime && item.pan" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.pan }}</span>
                        <v-icon v-if="item.panVerifyTime && item.pan" color="success"
                          size="small">mdi-check-circle</v-icon>

                      </template><span>
                        <div class="text-center">Pan verified: </div>{{ item.panVerifyTime }}
                      </span>
                    </v-tooltip>

                    <v-tooltip v-else-if="item.pan" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.pan }}</span>
                        <v-icon color="error" size="small">mdi-close-circle</v-icon>

                      </template><span>
                        <div class="text-center">Pan Not verified</div>
                      </span>
                    </v-tooltip>

                  </template>
                  <template v-slot:[`item.mobile`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mr-1">{{ item.mobile }}</span>
                        <v-icon v-if="item.mobileVerifyTime" color="success" size="small">mdi-check-circle</v-icon>

                      </template><span>
                        <div class="text-center">Mobile verified: </div>{{ item.mobileVerifyTime }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.aadhar_no`]="{ item }">
                    <div v-if="item.aadhar_no">
                      <v-tooltip v-if="item.aadhar_seeding_status == 'yes'" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1">{{ item.aadhar_no }}</span>
                          <v-icon v-if="item.aadhar_seeding_status && item.aadhar_no" color="success"
                            size="small">mdi-check-circle</v-icon>
                        </template><span>
                          <div class="text-center">Aadhar verified</div>
                        </span>
                      </v-tooltip>
                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1">{{ item.aadhar_no }}</span>
                          <v-icon color="error" size="small">mdi-close-circle</v-icon>

                        </template><span>
                          <div class="text-center">Aadhar Not verified</div>
                        </span>
                      </v-tooltip>
                    </div>

                    <span class="mr-1">{{ item.aadhar_no }}</span>
                  </template> -->
                </v-data-table>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
  
<script>
import { apiurl } from "../apiUrl";
import axios from "axios";

export default {
  data() {
    return {
      snackbar: false,
      clientData: [],
      topPerfom: [],
      worstPerfom: [],
      topPerfomClient: [],
      worstPerfomClient: [],
      topPerfomClientid: "",
      worstPerfomClientid: "",
      overalTotalClient: "",
      overalTotal: [],
      errMsg: "",

      clientidsindex: "",
      pnltab: null,
      usertotaldata: true,
      usernodata: false,

      clientids: ["All"],
      emailDatafetch: [],

      newPnL: [],
      PnLSummary: [],
      PnLdaywise: [],
      tol_DW_mtm: 0,
      tol_Summ_mtm: 0,
      total_day_wise: 0,
      total_summary: 0,
      DW_qty: 0,
      Summ_qty: 0,
      // clientData:[]

      clientLoaderclient: false,
      clientLoader: true,
      processLoader: true,
      processpending: [],
      clientpending: [],

      clientDataRaw: [],
      Analysis_data: [],
      Analysis_dataState: [],
      Analysis_dataGender: [],
      Analysis_dataQual: [],
      Analysis_dataOccu: [],
      Analysis_dataCity: [],
      Analysis_dataAge: [],
      Analysis_dataStatus: [],
      compKey: [],
      compVey: [],
      lastTrade: [],

      datesummary: [],
      startDate: "",
      endDates: "",
      monthname: [],
      coractdata: true,
      nocoractdata: false,
      coractloader: false,
      values: [],
      dateselect: "",

      searchText: "",
      clientWiseData: [],

      expanded: [],
      pexpanded: [],
      singleExpand: false,

      tabval: "currentmonth",
    };
  },

  mounted() {
    this.clientDetails();

    //   this.getcalendar()
  },

  methods: {
    tableFilter(val) {
      if (val != this.tabval) {
        this.tabval = val;
        this.clientDetails();
      }
    },

    clientDetails() {
      this.clientLoader = true;
      this.processLoader = true;

      this.clientpending = [];
      this.processpending = [];

      var axiosThis = this;
      var clientCode = localStorage.getItem("clientid");
      var usession = localStorage.getItem("session");
      var data = JSON.stringify({
        code: clientCode,
        status: "processpending",
        values: this.tabval,
      });
      var config = {
        method: "post",
        url: `${apiurl}/account_opening_filter`,

        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (
            response.data.msg != "Token is Expired." ||
            response.data.msg != "Token is Invalid."
          ) {
            if (response.data.emsg != "Invalid user rmcode") {
              axiosThis.processpending = response.data;
            } else {
              axiosThis.processpending = [];
              axiosThis.errMsg = "Data Not Found";
              axiosThis.snackbar = true;
            }
            axiosThis.processLoader = false;
          } else {
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
            setTimeout(() => {
              axiosThis.$router.push("/");
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.processLoader = false;
        });

      var pdata = JSON.stringify({
        code: clientCode,
        status: "clientpending",
        values: this.tabval,
      });
      var pconfig = {
        method: "post",
        url: `${apiurl}/account_opening_filter`,

        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: pdata,
      };

      axios(pconfig)
        .then(function (response) {
          if (response.data.emsg != "Invalid user rmcode") {
            axiosThis.clientpending = response.data;
          } else {
            axiosThis.clientpending = [];
            axiosThis.errMsg = "Data Not Found";
            axiosThis.snackbar = true;
          }
          axiosThis.clientLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosThis.clientLoader = false;
        });

      console.log("clientDataRaw", axiosThis.clientDataRaw);
    },
  },

  computed: {
    headers() {
      return [
        { text: "Created", value: "starttime" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "PAN", value: "pan", sortable: false },
        { text: "Status", value: "activestatus", sortable: false },
        { text: "Last Update", value: "days_difference", sortable: false },
      ];
    },
  },
};
</script>
  
<style>
.inlinebb {
  display: inline-flex;
}

.colums {
  position: static;
  float: left;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.dataTable {
  overflow-y: scroll;
}

.dataTable thead.v-data-table-header {
  position: relative;
  z-index: 1 !important;
}

/* .headline { */
/* font-family: 'DM Sans' !important; */
/* font-weight: 500; */
/* } */
.trdditl::-webkit-scrollbar {
  width: 6px;
}

.trdditl::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.trdditl::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 16px;
}

.toolbar .v-toolbar__content {
  padding: 0 !important;
}

.datedetailshead .v-data-table-header__sort-badge {
  display: none !important;
}
</style>
<style lang="scss">
.colums {
  position: static;
  float: left;
}
</style>
  