<template>
    <div>
        <!-- <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="2000" :value="true" :color="snaclr" absolute outlined top right>
            <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
            {{ errMsg }}
        </v-snackbar> -->
        <v-app-bar height="50px" elevation="0" style="border-bottom: 1px solid #bebebe; background-color: white" app
            fixed>
            <img src="@/assets/zebulogo.svg" style="max-width: 80px" alt="zebulogo" />
            <v-spacer></v-spacer>
        </v-app-bar>
        <div class="ml-14 mt-13 mr-14">
            <v-row>
                <v-col cols="12">
                    <v-card elevation="0" class="pa-3">

                        <div v-for="(post, index) in fullfeedata" :key="index" class="ma-0 mt-3  pa-0">
                            <v-card elevation="0" outlined class=" mt-4 pa-2 ">
                                <v-row>
                                    <v-col cols="12" lg="6" sm="12" md="12">
                                        <v-carousel cycle v-if="Object.keys(post.images).length > 0"
                                            :show-arrows="Object.keys(post.images).length > 1" hide-delimiters
                                            prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right">

                                            <v-carousel-item v-for="(image, key) in post.images" :key="key">
                                                <div class="text-center mx-auto">
                                                    <img class="px-10" width="100%" height="100%"
                                                        :src="getImageUrl(image)" />
                                                </div>
                                            </v-carousel-item>
                                        </v-carousel>

                                        <div class="text-center mx-auto"><video v-for="(image, key) in post.videos"
                                                :key="key" controls style="max-width: 100%; height: auto;">
                                                <source :src="getvideoUrl(image)" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video></div>


                                        <div class="text-center mx-auto"> <iframe
                                                v-for="(link, key) in post.youtube_links" :key="key" width="100%"
                                                height="415" :src="getIframeUrl(link)" frameborder="0" allowfullscreen>
                                            </iframe></div>


                                    </v-col>


                                    <v-divider vertical class="mt-5 mb-5"></v-divider>
                                    <v-col cols="12" lg="6" sm="12" md="12" style="height:100vh">
                                        <div style="height:70vh;overflow-x: scroll" v-html="post.text_data" ref="text"
                                            class="mb-0 mt-4 font-weight-medium ml-4 subtitle-1"> </div>

                                        <v-divider class="ml-4 mt-2 mr-4"></v-divider>


                                        <div class="d-flex mt-1 mb-1 ml-5">

                                            <v-btn @click="post.showComment = !post.showComment" icon
                                                class="text-none ml-2"><v-icon size="22">mdi-comment-outline</v-icon>
                                            </v-btn><span class="mt-2 caption text-none">Comment</span>


                                            <v-btn x-small @click="morewayslink(post)" icon class="ml-2 text-none mt-2">
                                                <v-icon size="27">mdi-share-outline</v-icon>

                                            </v-btn>
                                            <span class=" mt-2 ml-1 caption text-none">Share</span>

                                        </div>


                                        <v-text-field v-if="post.showComment" v-model="usercomment" outlined dense
                                            hide-details label="Comment" class="mb-2 mr-5 mt-3 ml-5"
                                            @click:append="commetfun(post)">
                                            <template v-slot:append>
                                                <v-btn icon color="primary" :loading="comtload" class=" ml-4 text-none"
                                                    text elevation="0" x-small @click="commetfun(post)">
                                                    <v-icon>mdi-send</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                        <div v-if="post.showComment">

                                            <div class="d-flex " v-for="(comment, i) in post.comments" :key="i">
                                                <div>
                                                    <v-icon size="18" class="ml-5" color="black">
                                                        mdi-account
                                                    </v-icon>
                                                </div>
                                                <div class="lineheistyle">
                                                    <p class="caption ml-1 mt-1 mb-0 font-weight-medium lineheistyle"
                                                        color="black">{{
                                                            post.comments_client_id[i] }}</p>
                                                    <span class="caption ml-1 mt-0  lineheistyle" color="black">{{
                                                        comment }}</span>
                                                </div>
                                            </div>
                                        </div>


                                        <!-- <v-divider class="ml-4 mt-2 mr-4"></v-divider> -->
                                    </v-col>
                                </v-row>





                            </v-card>




                        </div>
                    </v-card>

                </v-col>
            </v-row>
        </div>
    </div>
</template>


<script>
import { feedsurl } from "../apiUrl";

export default {
    data() {
        return {
            snackbar: false,
            errMsg: "",
            snaclr: "#000",
            pagefullresp: [],
            fullfeedata: [], // Add this line to initialize fullfeedata
            imageloadd: false,
            usercomment: "",
            hearloaddis: false,
            comtload: false,
            parterpostid: "",
            paertnerclientid: "",
            shareurl: "https://zebu-pd.web.app/",
            padless: false,
            variant: 'absolute',
        };
    },


    methods: {
        getIframeUrl(link) {
            const videoId = link.split('v=')[1] || link.split('/').pop();
            return `https://www.youtube.com/embed/${videoId}`;
        },
        getvideoUrl(image) {
            return `${feedsurl}/${image}`;

        },
        feedresposdata() {
            const axios = require('axios');
            let data = '';
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/share_view?id=` + this.parterpostid,
                headers: {},
                data: data
            };
            var axiosThis = this;
            axios.request(config)
                .then((response) => {
                    axiosThis.imageloadd = false;

                    // Map the new posts to add necessary fields like showComment
                    const newFeedData = response.data.data.map(post => ({
                        ...post,
                        showComment: false,
                        showFullText: false,
                        // Format text_data with line breaks
                        text_data: post.text_data.replace(/\r\n/g, '<br>')
                    }));

                    // Iterate through the new data and update or add posts
                    newFeedData.forEach(newPost => {
                        const existingIndex = axiosThis.fullfeedata.findIndex(existingPost => existingPost.post_id === newPost.post_id);

                        // If the post exists, update it reactively
                        if (existingIndex !== -1) {
                            axiosThis.$set(axiosThis.fullfeedata, existingIndex, newPost);
                        } else {
                            // Otherwise, append the new post to the array
                            axiosThis.fullfeedata.push(newPost);
                        }
                    });

                    console.log(axiosThis.fullfeedata);
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.imageloadd = false;
                });
        },

        commetfun(post) {
            // console.log(post);
            this.comtload = true
            this.comentcon = false
            var comentpost = post
            const axios = require('axios');
            let data = JSON.stringify({
                "comments": this.usercomment,
                "comments_client_id": this.paertnerclientid,
                "post_id": comentpost.post_id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/comments_post`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.comtload = false
                    this.usercomment = ""
                    this.feedresposdata()
                })
                .catch((error) => {
                    console.log(error);
                    this.comtload = false
                    this.usercomment = ""
                });

        },

        morewayslink(post) {
            navigator.share({
                title: "Zebu",
                text: post.text_data,
                url: this.shareurl + "/singlepage?" + "postcode=" + post.post_id + "&clientid=" + this.paertnerclientid

            });
            // this.sharedail = false

        },



        getImageUrl(image) {
            return `${feedsurl}/${image}`;

        },
    },
    computed: {
        localAttrs() {
            const attrs = {}

            if (this.variant === 'default') {
                attrs.absolute = false
                attrs.fixed = false
            } else {
                attrs[this.variant] = true
            }
            return attrs
        },
    },

    mounted() {
        this.parterpostid = this.$route.query.postcode
        this.paertnerclientid = this.$route.query.clientid
        this.feedresposdata();
    },
};
</script>
