<template>
    <v-app>
        <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" v-model="snackbar" timeout="2000" :value="true"
            :color="snaclr" outlined>
            <v-icon class="mr-2" :color="snaclr">mdi-alert-outline</v-icon>
            {{ errMsg }}
        </v-snackbar>
        <v-container class="pa-0 pa-sm-3 pa-md-3">
            <div class="px-sm-4 ">
                <div class="  d-none d-lg-block d-xll-block" style="position:fixed;top:0;width:80%;z-index:10;">
                    <v-toolbar dense class="elevation-0 mb-4 pr-4 pt-6"
                        style="position:fixed;width:77%;border:1px soild black">
                        <v-toolbar-title>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold headline">Feeds</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <div>
                            <v-autocomplete outlined style="width:225px; z-index:1000;" dense class="rounded-lg"
                                hide-details append-icon="" v-model="selectedTitle" :items="titles" single-line
                                label="Search" item-text="title" item-value="title" clearable @change="filterPosts"
                                prepend-inner-icon="mdi-magnify">
                            </v-autocomplete>
                        </div>

                    </v-toolbar>
                    <v-divider></v-divider>

                </div>

                 <v-toolbar dense class="elevation-0 d-block d-lg-none mb-4 pr-4 pt-6"
                       >
                        <v-toolbar-title>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold headline">Feeds</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <div>
                            <v-autocomplete outlined style="width:225px; z-index:1000;" dense class="rounded-lg"
                                hide-details append-icon="" v-model="selectedTitle" :items="titles" single-line
                                label="Search" item-text="title" item-value="title" clearable @change="filterPosts"
                                prepend-inner-icon="mdi-magnify">
                            </v-autocomplete>
                        </div>

                    </v-toolbar>


                <div class=" trdditl px-4 pt-2 mt-10">
                    <v-row class="mt-2" v-if="collectload">
                        <v-col class="mb-2" cols="12" lg="12" med="12" sm="12" v-for="i in 6" :key="i">
                            <v-skeleton-loader type="image" width="50%"
                                class="mx-auto custom-skeleton-loader"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col cols="12" lg="3" md="0" sm="0"></v-col>
                      

                        <v-col cols="12" lg="6" md="12" sm="12" v-if="filteredPosts.length">
                            <v-card :loading="imageloadd" elevation="0" class="pa-3">

                                <div v-for="(post, index) in filteredPosts" :key="index" class="ma-0 mt-3  pa-0">
                                    <v-card elevation="0" outlined
                                        class=" mt-4 pa-2 ">
                                        <v-carousel cycle v-if="Object.keys(post.images).length > 0"
                                            :show-arrows="Object.keys(post.images).length > 1" hide-delimiters
                                            height="350px">

                                            <v-carousel-item v-for="(image, key) in post.images" :key="key">
                                                <div class="text-center mx-auto">
                                                    <img class="px-10" :src="getImageUrl(image)"   @click="openImageInPopup(getImageUrl(image))"
                                                        style="width: 100%; height: 350px; cursor: pointer ;object-fit: contain;" />
                                                </div>
                                            </v-carousel-item>
                                        </v-carousel>

                                        <video  v-for="(image, key) in post.videos" :key="key" controls style="max-width: 100%; height: auto;">
                                            <source :src="getvideoUrl(image)" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>

                                   
                                        <div class="text-center mx-auto">
                                            <iframe width="100%" height="315" v-for="(link, key) in post.youtube_links" :key="key" class="pl-11 pr-11 "
                                                :src="getIframeUrl(link)" frameborder="0" allowfullscreen>
                                            </iframe>
                                        </div>

                                       

                                

                                        <div :class="{ 'heightset': post.showFullText }">
    <p ref="text" class="mb-0 font-weight-medium ml-4 caption"
        v-html="post.text_data"
        :class="{ 'clamp-text': !post.showFullText }">
    </p>
    
</div>
<v-btn text x-small class="text-none" elevation="0" color="primary" @click="toggleText(post)">
        {{ post.showFullText ? 'Show Less' : 'Show More' }}
    </v-btn>

                                        <v-divider class="ml-4 mt-2 mr-4"></v-divider>

                                        <div class="d-flex mt-1 mb-1 ml-5">
                                            <v-btn :disabled="hearloaddis" @click="likereaction(post)"
                                                v-if="post.emoji == 'False' || post.emoji == ''" icon>
                                                <v-icon size="22">mdi-thumb-up-outline</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="hearloaddis" @click="likereaction(post)" v-else icon>
                                                <v-icon color="primary" size="22">mdi-thumb-up</v-icon>
                                            </v-btn>
                                            <span class="mt-2 caption text-none">{{ post.liked_counts }} Like</span>

                                            <v-btn @click="post.showComment = !post.showComment" icon
                                                class="text-none ml-2">
                                                <v-icon size="22">mdi-comment-outline</v-icon>
                                            </v-btn>
                                            <span class="mt-2 caption text-none">Comment</span>

                                            <v-btn x-small @click="morewayslink(post)" icon class="ml-2 text-none mt-2">
                                                <v-icon size="27">mdi-share-outline</v-icon>
                                            </v-btn>
                                            <span class="mt-2 ml-1 caption text-none">Share</span>
                                            <span class="mt-2 ml-1 ml-auto mr-5 caption text-none">{{ formatTimeAgo(post.difference_in_days) }}</span>

                                        </div>

                                        <v-text-field v-if="post.showComment" v-model="usercomment" outlined dense
                                            hide-details label="Comment" class="mb-5 mr-5 mt-3 ml-5"
                                            @click:append="commetfun(post)">
                                            <template v-slot:append>
                                                <v-btn color="primary" :loading="comtload" class="ml-4 text-none" text
                                                    elevation="0" x-small @click="commetfun(post)">
                                                    Post Comment
                                                </v-btn>
                                            </template>
                                        </v-text-field>

                                        <div v-if="post.showComment">
                                            <div class="d-flex mt-4" v-for="(comment, i) in post.comments" :key="i">
                                                <div>
                                                    <v-icon size="18" class="ml-5" color="black">
                                                        mdi-account
                                                    </v-icon>
                                                </div>
                                                <div class="lineheistyle">
                                                    <p class="body-2 ml-1 mt-1 mb-0 font-weight-bold lineheistyle"
                                                        color="black">{{
                                                            post.comments_client_id[i] }}</p>
                                                    <span class="caption ml-1 mt-0 lineheistyle" color="black">{{
                                                        comment }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>



                                </div>
                            </v-card>

                        </v-col>

                        <v-col v-else cols="12" lg="6" md="12" sm="12">
                            <v-card elevation="0" class="text-center pa-5 mt-16">
                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M90.0699 49.7871C89.5137 48.9418 88.6027 48.4371 87.6356 48.4371H84.3371C83.5137 28.1246 67.0277 12.498 46.8746 12.498C26.1965 12.498 9.37305 29.3215 9.37305 49.9996C9.37305 70.6777 26.1965 87.5012 46.8746 87.5012C56.6668 87.5012 65.9309 83.7512 72.9621 76.9402L70.7887 74.6934C64.3434 80.9371 55.8496 84.3762 46.8746 84.3762C27.9199 84.3762 12.498 68.9543 12.498 49.9996C12.498 31.0449 27.9199 15.623 46.8746 15.623C65.3059 15.623 80.3902 31.2496 81.2105 48.4371H77.9902C77.023 48.4371 76.1121 48.9418 75.5543 49.7871C74.934 50.7324 74.8262 51.9699 75.2777 53.0199L78.8215 61.2746C79.559 62.9934 81.0871 64.0605 82.8121 64.0605C84.5371 64.0605 86.0652 62.9918 86.8027 61.2746L90.3465 53.0199C90.798 51.9715 90.6918 50.7324 90.0699 49.7871ZM87.4746 51.7887L83.9309 60.0434C83.4418 61.1793 82.1824 61.1793 81.6934 60.0434L78.1496 51.7887C78.1137 51.7012 78.1246 51.6168 78.1402 51.5621H87.4855C87.5027 51.6168 87.5121 51.7012 87.4746 51.7887Z"
                                        fill="#CCCCCC" />
                                    <path
                                        d="M46.6625 38.5064C47.2172 38.8721 47.875 39.0596 48.5359 39.0596C48.9984 39.0596 49.4625 38.9689 49.8937 38.783L58.15 35.2392C59.8703 34.5033 60.9375 32.9736 60.9375 31.2502C60.9375 29.5267 59.8687 27.9971 58.1516 27.2596L49.8953 23.7158C48.8469 23.2658 47.6078 23.3705 46.6641 23.9924C45.8172 24.5486 45.3125 25.458 45.3125 26.4267V29.7674C34.375 30.5689 26.5625 39.3267 26.5625 50.0002C26.5625 61.2002 35.675 70.3127 46.875 70.3127C58.075 70.3127 67.1875 60.9377 67.1875 50.0002H64.0625C64.0625 59.3752 56.3516 67.1877 46.875 67.1877C37.3984 67.1877 29.6875 59.4767 29.6875 50.0002C29.6875 41.0502 35.9375 33.6846 45.3125 32.8908V36.0721C45.3125 37.0408 45.8172 37.9502 46.6625 38.5064ZM48.4375 26.5768C48.4375 26.558 48.5734 26.5502 48.6625 26.5877L56.9188 30.1314C57.4859 30.3752 57.8125 30.783 57.8125 31.2502C57.8125 31.7174 57.4859 32.1252 56.9188 32.3689L48.6625 35.9127C48.5734 35.9486 48.4375 35.9408 48.4375 35.9221V26.5768Z"
                                        fill="#CCCCCC" />
                                    <path
                                        d="M46.875 43.75C45.2174 43.75 43.6277 44.4085 42.4556 45.5806C41.2835 46.7527 40.625 48.3424 40.625 50C40.625 51.6576 41.2835 53.2473 42.4556 54.4194C43.6277 55.5915 45.2174 56.25 46.875 56.25C48.5326 56.25 50.1223 55.5915 51.2944 54.4194C52.4665 53.2473 53.125 51.6576 53.125 50C53.125 48.3424 52.4665 46.7527 51.2944 45.5806C50.1223 44.4085 48.5326 43.75 46.875 43.75Z"
                                        fill="#CCCCCC" />
                                </svg>
                                <p>No Feeds  Available</p>
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="3" md="0" sm="0"></v-col>

                    </v-row>

                    <v-dialog v-model="isImageDialogOpen" width="80%" >
                        <v-card elevation="0">
          <v-img :src="currentImage" @click="isImageDialogOpen = false" class="full-screen-image"></v-img>
        </v-card>
        </v-dialog>


                    <v-dialog v-model="dialog" persistent max-width="590" class="pa-0 rounded-xl">
                        <v-card elevation="0" outlined class="rounded-xl ">
                            <v-toolbar color="#F7F7F7" dense class="elevation-0 pl-3 pr-3 pt-1 pa-0">
                                <v-toolbar-title>
                                    <v-list-item class="pa-0">
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-title class="font-weight-bold title pa-0">Create a
                                                Post</v-list-item-title>

                                        </v-list-item-content>
                                    </v-list-item>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>

                                <v-btn @click="dialog = false" icon elevation="0">
                                    <v-icon color="black" class="mr-6">mdi-close-circle-outline</v-icon> </v-btn>
                            </v-toolbar>
                            <v-divider class="mb-4 ml-2 mr-2"></v-divider>
                            <v-card elevation="0" class="pa-0 mt-1 ml-3 mr-3">
                                <quill-editor class="mb-1 custom-editor" v-model="editorContent"
                                    :options="editorOptions" ref="myQuillEditor"></quill-editor>

                            </v-card>
                            <div class="mb-3 d-flex">
                                <p class="ml-6 mt-5  grey--text">Add : </p>
                                <v-file-input hide-input truncate-length="15"></v-file-input>
                            </div>

                            <div class="d-flex ml-3 mb-5 ">
                                <v-btn outlined elevation="0" @click="dialog = false" class="text-none"> Cancel</v-btn>
                                <v-btn elevation="0" color="black" class="text-none white--text ml-3"> Send</v-btn>

                            </div>
                        </v-card>
                    </v-dialog>


                </div>
            </div>
        </v-container>
    </v-app>
</template>

<script>
import { feedsurl } from "../apiUrl";

export default {
    data() {
        return {
            snackbar: false,
            radioGroup: "image",
            errMsg: "",
            selectedItem: 0,
            dialog: false,
            editorContent: '',
            fullfeedata: [],
            snaclr: "#000",
            comentcon: false,
            usercomment: "",
            comtload: false,
            display: 0,
            playshort: null,
            shortdialog: false,
            shortpage: 1,
            shortpagecount: 0,
            allshort: [],
            playvideo: null,
            videodialog: false,
            page: 1,


            pagecount: 0,
            allvideos: [],
            imageloadd: true,
            sharedail: false,
            sharetextweb:
                "https://wa.me/?text= ",
            sharetexturl: [],
            shareurl: "https://zebu-pd.web.app",
            whatappdata: "",
            hearloaddis: false,
            posts: [],
            selectedTitle: null,
            filteredPosts: [],
            collectload: true,
            isImageDialogOpen:false,
            currentImage: '',

        };
    },



    methods: {
        openImageInPopup(imageUrl) {
      this.currentImage = imageUrl;
      this.isImageDialogOpen = true; // Open the popup
    },
        getIframeUrl(link) {
            const videoId = link.split('v=')[1] || link.split('/').pop();
            return `https://www.youtube.com/embed/${videoId}`;
        },
        formatTimeAgo(differenceInDays) {
            if(differenceInDays){
                const timeParts = differenceInDays.split(','); 
    const days = parseInt(timeParts[0].split(' ')[0], 10); 
    const hours = parseInt(timeParts[1].split(' ')[1], 10);
    const minutes = parseInt(timeParts[2].split(' ')[1], 10); 
    if (days === 0 && hours === 0 && minutes > 0) {
      return `${minutes} min ago`;
    } else if (days === 0 && hours > 0) {
      return `${hours} hrs ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }

    return 'Just now'; 
            }
   
  },
        feedresposdata() {
            // this.imageloadd = true;
            const axios = require('axios');
            let data = JSON.stringify({
                "client_id": localStorage.getItem("clientid")
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/show_feeds`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this;
            axios.request(config)
                .then((response) => {
                    axiosThis.imageloadd = false;
                    axiosThis.collectload = false

                    const newFeedData = response.data.data.map(post => ({
                        ...post,
                        showComment: false,
                        showFullText: false,
                        text_data: post.text_data

                    }));

                    newFeedData.forEach(newPost => {
                        const existingIndex = axiosThis.fullfeedata.findIndex(existingPost => existingPost.post_id === newPost.post_id);

                        if (existingIndex !== -1) {
                            axiosThis.$set(axiosThis.fullfeedata, existingIndex, newPost);
                        } else {

                            axiosThis.fullfeedata.push(newPost);
                        }
                    });

                    axiosThis.fullfeedata = newFeedData;
                    axiosThis.filteredPosts = newFeedData;

                    const ipoPost = newFeedData.find(post => post.title === 'ipo');
                    if (ipoPost) {
                        axiosThis.selectedTitle = 'ipo';
                        axiosThis.filterPosts();
                    }


                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.imageloadd = false;
                    axiosThis.collectload = false

                    axiosThis.snackbar = true;
                    axiosThis.errMsg = error;
                    axiosThis.snaclr = "error";
                });
        },
        filterPosts() {
            if (this.selectedTitle) {
                this.filteredPosts = this.fullfeedata.filter(
                    (post) => post.title === this.selectedTitle
                );
            } else {
                this.filteredPosts = this.fullfeedata;
            }
        },

        likereaction(post) {
            this.hearloaddis = true
            var reactres = post
            const axios = require('axios');
            let data = JSON.stringify({
                "post_id": reactres.post_id,
                "client_id": localStorage.getItem("clientid"),
                "emoji": reactres.emoji == 'True' ? "False" : 'True'
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/reactions`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    axiosThis.hearloaddis = false
                    axiosThis.feedresposdata()
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.hearloaddis = false
                    axiosThis.snackbar = true;
                    axiosThis.errMsg = error;
                    axiosThis.snaclr = "error";
                });

        },
        getImageUrl(imagePath) {
            return `${feedsurl}/${imagePath}`;
        },
        getvideoUrl(image) {
            return `${feedsurl}/${image}`;

        },
        commetfun(post) {
            // console.log(post);
            this.comtload = true
            this.comentcon = false
            var comentpost = post
            const axios = require('axios');
            let data = JSON.stringify({
                "comments": this.usercomment,
                "comments_client_id": localStorage.getItem("clientid"),
                "post_id": comentpost.post_id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/comments_post`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.comtload = false
                    this.usercomment = ""
                    this.feedresposdata()
                })
                .catch((error) => {
                    console.log(error);
                    this.comtload = false
                    this.usercomment = ""
                    this.snackbar = true;
                    this.errMsg = error;
                    this.snaclr = "error";
                });

        },

        sharefun(post) {
            this.sharetexturl = post
            this.sharedail = true
            this.whatappdata = this.sharetextweb + post.text_data + " " +
                (post.images && post.images["1"] ? post.images["1"].slice(1) :
                    (post.videos && post.videos["1"] ? post.videos["1"].slice(1) : ""));
            console.log(this.whatappdata);

        },
        morewayslink(post) {
            navigator.share({
                title: "Zebu",
                text: post.text_data,
                url: this.shareurl + "/singlepage?" + "postcode=" + post.post_id + "&clientid=" + localStorage.getItem("clientid")

            });


        },
        copylink(sharetexturl) {
            var copylink = this.shareurl + (sharetexturl.images && sharetexturl.images["1"] ? sharetexturl.images["1"].slice(1) :
                (sharetexturl.videos && sharetexturl.videos["1"] ? sharetexturl.videos["1"].slice(1) : ""))
            navigator.clipboard.writeText(copylink);
            this.sharedail = false
            this.snackbar = true;
            this.snaclr = "#2A2A2A";
            this.errMsg = 'Link as copied.';
        },



        toggleText(post) {
            post.showFullText = !post.showFullText;
        },
    },
    computed: {

        titles() {
            return [...new Set(this.fullfeedata.map(post => post.title))];
        },
    },

    mounted() {
        this.feedresposdata()
        //   this.getShort();
        //  this.shortClose();
        //  this.scrollBehavior();
        //  this.getVideos();
        //  this.videoClose();
        //  this.scrollBehavior();
    },


};
</script>

<style scoped>
.custom-skeleton-loader>>>.v-skeleton-loader__image {
    height: 398px !important;
}
.full-screen-image {
  width: 100%;
  height: 80%;
  object-fit: contain;
  cursor: pointer;
}
.clamp-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.lineheistyle {
    line-height: 70%
}

.no-hover {
    background-color: transparent;
    /* Remove background color */
    border: none;
    /* Remove border */
    box-shadow: none;
    /* Remove shadow */
    color: inherit;
    /* Ensure text color remains unchanged */
}

.no-hover:hover {
    background-color: white;
    /* Remove background color on hover */
    box-shadow: none;
    /* Remove shadow on hover */
}

.heightset{
    overflow: scroll;
    height:180px
}

</style>

<!--  -->