<template>
  <div id="app1">
    <!-- Mobile -->
    <div class="mblappbar d-md-none">
      <v-app-bar class="appbar elevation-1 px-4" absolute width="100%" permanent color="white">
        <v-row class="d-flex">
          <v-app-bar-nav-icon class="my-auto" color="#797E8A" @click.stop="drawer = !drawer">
          </v-app-bar-nav-icon>
          <v-col class="d-flex justify-center">
            <!-- <img src="@/assets/wyselylogo.svg" width="100px" alt="wyselylogo"  /> -->
          </v-col>
          <v-menu open-on-hover down offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="my-auto" icon v-bind="attrs" v-on="on">
                <v-avatar size="40" color="#3B82F6">
                  <span class="white--text font-weight-bold title text-uppercase">{{
                    clientCode.slice(0, 1)
                  }}</span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card class="pa-2 rounded-lg elevation-0">
              <p class="lstctntit mb-0">{{ clientCode }}</p>
              <p>{{ clientEmail }}</p>

              <v-btn @click="handleLogout()" class="elevation-0" small text color="#3B82F6"
                style="background-color: aliceblue !important">
                <span class="font-weight-bold">Logout</span>
              </v-btn>
            </v-card>
          </v-menu>
        </v-row>
      </v-app-bar>
      <v-navigation-drawer class="white pt-2" v-model="drawer" app>
        <v-list nav link>
          <v-list-item-group v-model="sideOptionsDesktop" color="#3B82F6">
            <v-list-item @click="listval(vals = 'dash')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7334 12H20.0001" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 4V8.26667" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.27946 14.5557L6.15039 17.4559" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M11.9999 15.7332C14.0618 15.7332 15.7333 14.0617 15.7333 11.9999C15.7333 9.93801 14.0618 8.26654 11.9999 8.26654C9.93807 8.26654 8.2666 9.93801 8.2666 11.9999C8.2666 14.0617 9.93807 15.7332 11.9999 15.7332Z"
                    :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'dash' ? '#3B82F6' : '#334051'};`">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="listval(vals = 'cliacc')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.1111 4.88889H8.26667C6.77319 4.88889 6.02646 4.88889 5.45603 5.17954C4.95426 5.4352 4.54631 5.84315 4.29065 6.34491C4 6.91535 4 7.66208 4 9.15556V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20H14.8444C16.3379 20 17.0847 20 17.6551 19.7094C18.1569 19.4537 18.5648 19.0457 18.8205 18.544C19.1111 17.9735 19.1111 17.2268 19.1111 15.7333V12.8889M19.219 4.78105C20.2603 5.82245 20.2603 7.51089 19.219 8.55228C18.1776 9.59368 16.4891 9.59368 15.4477 8.55228C14.4063 7.51089 14.4063 5.82245 15.4477 4.78105C16.4891 3.73965 18.1776 3.73965 19.219 4.78105Z"
                    :stroke="activeitem == 'cliacc' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'cliacc' ? '#3B82F6' : '#334051'};`">Accounts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="listval(vals = 'clilst')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.3742 15.6872H9.67434C8.21677 15.6872 7.48799 15.6872 6.89497 15.8689C5.55977 16.278 4.51491 17.3334 4.10988 18.6821C3.92999 19.2811 3.92999 20.0172 3.92999 21.4895M16.9853 7.24739C16.9853 9.8693 14.8811 11.9948 12.2854 11.9948C9.6897 11.9948 7.58548 9.8693 7.58548 7.24739C7.58548 4.62548 9.6897 2.5 12.2854 2.5C14.8811 2.5 16.9853 4.62548 16.9853 7.24739Z"
                    :stroke="activeitem == 'clilst' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M9.62686 15.6977H14.3268C15.7843 15.6977 16.5131 15.6977 17.1061 15.8794C18.4413 16.2886 19.4862 17.344 19.8912 18.6927C20.0711 19.2917 20.0711 20.0277 20.0711 21.5"
                    :stroke="activeitem == 'clilst' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'clilst' ? '#3B82F6' : '#334051'};`">Clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item to="/analysis">
                <v-list-item-avatar tile size="22">
                   <img src="@/assets/analysis.png" alt="" /> 

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >Leads</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item @click="listval(vals = 'per')">
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.44444 12.8889V16.4444M15.5556 11.1111V16.4444M12 7.55556V16.4444M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7094C19.0457 19.4537 19.4537 19.0457 19.7094 18.544C20 17.9735 20 17.2268 20 15.7333V8.26667C20 6.77319 20 6.02646 19.7094 5.45603C19.4537 4.95426 19.0457 4.54631 18.544 4.29065C17.9735 4 17.2268 4 15.7333 4H8.26667C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20Z"
                      :stroke="activeitem == 'per' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Revenue</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
  
              <v-list-item @click="listval(vals = 'stat')">
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4004 8.26666H13.829" :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.4004 11.4667H16.8004" :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M15.7333 17.8667V16.8H4V17.8667C4 19.0453 4.95467 20 6.13333 20H17.8667C16.688 20 15.7333 19.0453 15.7333 17.8667Z"
                      :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M7.2002 14.6667V4H20.0002V17.8667C20.0002 19.0453 19.0455 20 17.8669 20"
                      :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Statement</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'feed')">
                <v-list-item-avatar tile size="22">
                 <svg width="24" height="20" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99809" cy="7.18625" rx="1.8" ry="1.75" stroke="#677A8E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1624 3.11756C13.9643 2.92518 13.6478 2.92981 13.4554 3.12791C13.263 3.32601 13.2676 3.64256 13.4657 3.83494L14.1624 3.11756ZM13.4657 10.5463C13.2676 10.7387 13.263 11.0552 13.4554 11.2533C13.6478 11.4514 13.9643 11.4561 14.1624 11.2637L13.4657 10.5463ZM5.83374 11.2549C6.03184 11.4473 6.34839 11.4427 6.54077 11.2446C6.73315 11.0465 6.72852 10.7299 6.53042 10.5376L5.83374 11.2549ZM6.53042 3.82619C6.72852 3.63381 6.73315 3.31726 6.54077 3.11916C6.34839 2.92106 6.03184 2.91643 5.83374 3.10881L6.53042 3.82619ZM16.7097 0.641555C16.5117 0.449032 16.1952 0.453443 16.0026 0.651407C15.8101 0.84937 15.8145 1.16592 16.0125 1.35844L16.7097 0.641555ZM16.0125 13.0141C15.8145 13.2066 15.8101 13.5231 16.0026 13.7211C16.1952 13.9191 16.5117 13.9235 16.7097 13.7309L16.0125 13.0141ZM3.28648 13.7309C3.48445 13.9235 3.801 13.9191 3.99352 13.7211C4.18605 13.5231 4.18164 13.2066 3.98367 13.0141L3.28648 13.7309ZM3.98367 1.35844C4.18164 1.16592 4.18605 0.84937 3.99352 0.651407C3.801 0.453443 3.48445 0.449032 3.28648 0.641555L3.98367 1.35844ZM13.4657 3.83494C14.3844 4.72705 14.8978 5.93455 14.8978 7.19062H15.8978C15.8978 5.6599 15.2718 4.19492 14.1624 3.11756L13.4657 3.83494ZM14.8978 7.19062C14.8978 8.4467 14.3844 9.6542 13.4657 10.5463L14.1624 11.2637C15.2718 10.1863 15.8978 8.72135 15.8978 7.19062H14.8978ZM6.53042 10.5376C5.6118 9.64545 5.09833 8.43795 5.09833 7.18187H4.09833C4.09833 8.7126 4.72437 10.1776 5.83374 11.2549L6.53042 10.5376ZM5.09833 7.18187C5.09833 5.9258 5.6118 4.7183 6.53042 3.82619L5.83374 3.10881C4.72437 4.18617 4.09833 5.65115 4.09833 7.18187H5.09833ZM16.0125 1.35844C19.324 4.57899 19.324 9.79351 16.0125 13.0141L16.7097 13.7309C20.425 10.1177 20.425 4.25476 16.7097 0.641555L16.0125 1.35844ZM3.98367 13.0141C0.67211 9.79351 0.67211 4.57899 3.98367 1.35844L3.28648 0.641555C-0.428828 4.25476 -0.428828 10.1177 3.28648 13.7309L3.98367 13.0141Z" fill="#677A8E"/>
</svg>

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Feeds</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'download')">
                <v-list-item-avatar tile size="22">
                  <v-icon :color="activeitem == 'download' ? '#3B82F6' : '#334051'">mdi-arrow-down</v-icon>

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Downloads</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'query')">
                <v-list-item-avatar tile size="22">
                  <v-icon :color="activeitem == 'query' ? '#3B82F6' : '#334051'"  >mdi-headset</v-icon>

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Support</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


            <!-- <v-list-group v-model="permode" :value="false" no-action aria-hidden="false">
              <template v-slot:activator>
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.44444 12.8889V16.4444M15.5556 11.1111V16.4444M12 7.55556V16.4444M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7094C19.0457 19.4537 19.4537 19.0457 19.7094 18.544C20 17.9735 20 17.2268 20 15.7333V8.26667C20 6.77319 20 6.02646 19.7094 5.45603C19.4537 4.95426 19.0457 4.54631 18.544 4.29065C17.9735 4 17.2268 4 15.7333 4H8.26667C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20Z"
                      :stroke="permode ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-title :style="`color:${permode ? '#3B82F6' : '#334051'};`">Performance</v-list-item-title>
              </template>

              <div class="ml-4 pl-5" style="border-left: 3px solid #d9d9d9">
                <v-list-item @click="listval(vals = 'per')">
                  <v-list-item-content class="pl-4">
                    <v-list-item-title :style="`color:${activeitem == 'per' ? '#3B82F6' : '#334051'};`">Revenue</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="listval(vals = 'stat')">
                  <v-list-item-content class="pl-4">
                    <v-list-item-title :style="`color:${activeitem == 'stat' ? '#3B82F6' : '#334051'};`">Statement</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group> -->



            <!-- <v-list-item @click="listval(vals = 'accs')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7116 9.33333H6.22222C4.99492 9.33333 4 8.33841 4 7.11111C4 5.88381 4.99492 4.88889 6.22222 4.88889H14.7116M9.28844 19.1111H17.7778C19.0051 19.1111 20 18.1162 20 16.8889C20 15.6616 19.0051 14.6667 17.7778 14.6667H9.28844M4 16.8889C4 18.6071 5.39289 20 7.11111 20C8.82933 20 10.2222 18.6071 10.2222 16.8889C10.2222 15.1707 8.82933 13.7778 7.11111 13.7778C5.39289 13.7778 4 15.1707 4 16.8889ZM20 7.11111C20 8.82933 18.6071 10.2222 16.8889 10.2222C15.1707 10.2222 13.7778 8.82933 13.7778 7.11111C13.7778 5.39289 15.1707 4 16.8889 4C18.6071 4 20 5.39289 20 7.11111Z"
                    :stroke="activeitem == 'accs' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>User info</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <!-- Desktop -->
    <div class="dsknavdraw d-none d-md-block">
      <!-- style="width: 200px !important;" -->
      <v-navigation-drawer class="white pt-6 pl-2" app permanent>
        <template v-slot:prepend>
          <v-list-item class="mb-10">
          <img src="@/assets/zebulogo.svg" alt="zebulogo" width="110px" />

            <!-- <img width="110px" src="https://www.zebuetrade.com/static/logo/Logo.svg" alt="zebulogo" /> -->
          </v-list-item>
        </template>
        <!-- {{ sideOptionsDesktop }} -->
        <v-list nav link>
          <v-list-item-group v-model="sideOptionsDesktop" color="#3B82F6">
            <v-list-item @click="listval(vals = 'dash')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7334 12H20.0001" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 4V8.26667" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9.27946 14.5557L6.15039 17.4559" :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M11.9999 15.7332C14.0618 15.7332 15.7333 14.0617 15.7333 11.9999C15.7333 9.93801 14.0618 8.26654 11.9999 8.26654C9.93807 8.26654 8.2666 9.93801 8.2666 11.9999C8.2666 14.0617 9.93807 15.7332 11.9999 15.7332Z"
                    :stroke="activeitem == 'dash' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'dash' ? '#3B82F6' : '#334051'};`">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="listval(vals = 'cliacc')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.1111 4.88889H8.26667C6.77319 4.88889 6.02646 4.88889 5.45603 5.17954C4.95426 5.4352 4.54631 5.84315 4.29065 6.34491C4 6.91535 4 7.66208 4 9.15556V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20H14.8444C16.3379 20 17.0847 20 17.6551 19.7094C18.1569 19.4537 18.5648 19.0457 18.8205 18.544C19.1111 17.9735 19.1111 17.2268 19.1111 15.7333V12.8889M19.219 4.78105C20.2603 5.82245 20.2603 7.51089 19.219 8.55228C18.1776 9.59368 16.4891 9.59368 15.4477 8.55228C14.4063 7.51089 14.4063 5.82245 15.4477 4.78105C16.4891 3.73965 18.1776 3.73965 19.219 4.78105Z"
                    :stroke="activeitem == 'cliacc' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'cliacc' ? '#3B82F6' : '#334051'};`">Accounts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="listval(vals = 'clilst')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.3742 15.6872H9.67434C8.21677 15.6872 7.48799 15.6872 6.89497 15.8689C5.55977 16.278 4.51491 17.3334 4.10988 18.6821C3.92999 19.2811 3.92999 20.0172 3.92999 21.4895M16.9853 7.24739C16.9853 9.8693 14.8811 11.9948 12.2854 11.9948C9.6897 11.9948 7.58548 9.8693 7.58548 7.24739C7.58548 4.62548 9.6897 2.5 12.2854 2.5C14.8811 2.5 16.9853 4.62548 16.9853 7.24739Z"
                    :stroke="activeitem == 'clilst' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M9.62686 15.6977H14.3268C15.7843 15.6977 16.5131 15.6977 17.1061 15.8794C18.4413 16.2886 19.4862 17.344 19.8912 18.6927C20.0711 19.2917 20.0711 20.0277 20.0711 21.5"
                    :stroke="activeitem == 'clilst' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :style="`color:${activeitem == 'clilst' ? '#3B82F6' : '#334051'};`">Clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item to="/analysis">
                <v-list-item-avatar tile size="22">
                   <img src="@/assets/analysis.png" alt="" /> 

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >Leads</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item @click="listval(vals = 'per')">
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.44444 12.8889V16.4444M15.5556 11.1111V16.4444M12 7.55556V16.4444M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7094C19.0457 19.4537 19.4537 19.0457 19.7094 18.544C20 17.9735 20 17.2268 20 15.7333V8.26667C20 6.77319 20 6.02646 19.7094 5.45603C19.4537 4.95426 19.0457 4.54631 18.544 4.29065C17.9735 4 17.2268 4 15.7333 4H8.26667C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20Z"
                      :stroke="activeitem == 'per' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Revenue</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
  
              <v-list-item @click="listval(vals = 'stat')">
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.4004 8.26666H13.829" :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.4004 11.4667H16.8004" :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M15.7333 17.8667V16.8H4V17.8667C4 19.0453 4.95467 20 6.13333 20H17.8667C16.688 20 15.7333 19.0453 15.7333 17.8667Z"
                      :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M7.2002 14.6667V4H20.0002V17.8667C20.0002 19.0453 19.0455 20 17.8669 20"
                      :stroke="activeitem == 'stat' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Statement</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'feed')">
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="20" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="9.99809" cy="7.18625" rx="1.8" ry="1.75" 
    :stroke="activeitem === 'feed' ? '#3B82F6' : '#334051'" 
    stroke-linecap="round" 
    stroke-linejoin="round" 
  />
  <path 
    d="M14.1624 3.11756C13.9643 2.92518 13.6478 2.92981 13.4554 3.12791C13.263 3.32601 13.2676 3.64256 13.4657 3.83494L14.1624 3.11756ZM13.4657 10.5463C13.2676 10.7387 13.263 11.0552 13.4554 11.2533C13.6478 11.4514 13.9643 11.4561 14.1624 11.2637L13.4657 10.5463ZM5.83374 11.2549C6.03184 11.4473 6.34839 11.4427 6.54077 11.2446C6.73315 11.0465 6.72852 10.7299 6.53042 10.5376L5.83374 11.2549ZM6.53042 3.82619C6.72852 3.63381 6.73315 3.31726 6.54077 3.11916C6.34839 2.92106 6.03184 2.91643 5.83374 3.10881L6.53042 3.82619ZM16.7097 0.641555C16.5117 0.449032 16.1952 0.453443 16.0026 0.651407C15.8101 0.84937 15.8145 1.16592 16.0125 1.35844L16.7097 0.641555ZM16.0125 13.0141C15.8145 13.2066 15.8101 13.5231 16.0026 13.7211C16.1952 13.9191 16.5117 13.9235 16.7097 13.7309L16.0125 13.0141ZM3.28648 13.7309C3.48445 13.9235 3.801 13.9191 3.99352 13.7211C4.18605 13.5231 4.18164 13.2066 3.98367 13.0141L3.28648 13.7309ZM3.98367 1.35844C4.18164 1.16592 4.18605 0.84937 3.99352 0.651407C3.801 0.453443 3.48445 0.449032 3.28648 0.641555L3.98367 1.35844ZM13.4657 3.83494C14.3844 4.72705 14.8978 5.93455 14.8978 7.19062H15.8978C15.8978 5.6599 15.2718 4.19492 14.1624 3.11756L13.4657 3.83494ZM14.8978 7.19062C14.8978 8.4467 14.3844 9.6542 13.4657 10.5463L14.1624 11.2637C15.2718 10.1863 15.8978 8.72135 15.8978 7.19062H14.8978ZM6.53042 10.5376C5.6118 9.64545 5.09833 8.43795 5.09833 7.18187H4.09833C4.09833 8.7126 4.72437 10.1776 5.83374 11.2549L6.53042 10.5376ZM5.09833 7.18187C5.09833 5.9258 5.6118 4.7183 6.53042 3.82619L5.83374 3.10881C4.72437 4.18617 4.09833 5.65115 4.09833 7.18187H5.09833ZM16.0125 1.35844C19.324 4.57899 19.324 9.79351 16.0125 13.0141L16.7097 13.7309C20.425 10.1177 20.425 4.25476 16.7097 0.641555L16.0125 1.35844ZM3.98367 13.0141C0.67211 9.79351 0.67211 4.57899 3.98367 1.35844L3.28648 0.641555C-0.428828 4.25476 -0.428828 10.1177 3.28648 13.7309L3.98367 13.0141Z"
    :fill="activeitem === 'feed' ? '#3B82F6' : '#334051'" 
  />
</svg>

                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Feeds</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'download')">
                <v-list-item-avatar tile size="22">
                <v-icon :color="activeitem == 'download' ? '#3B82F6' : '#334051'"  >mdi-arrow-down</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Downloads </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="listval(vals = 'query')">
                <v-list-item-avatar tile size="22">
                <v-icon :color="activeitem == 'query' ? '#3B82F6' : '#334051'"  >mdi-headset</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>  Support </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            <!-- <v-list-group v-model="permode" :value="false" no-action aria-hidden="false">
              <template v-slot:activator>
                <v-list-item-avatar tile size="22">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.44444 12.8889V16.4444M15.5556 11.1111V16.4444M12 7.55556V16.4444M8.26667 20H15.7333C17.2268 20 17.9735 20 18.544 19.7094C19.0457 19.4537 19.4537 19.0457 19.7094 18.544C20 17.9735 20 17.2268 20 15.7333V8.26667C20 6.77319 20 6.02646 19.7094 5.45603C19.4537 4.95426 19.0457 4.54631 18.544 4.29065C17.9735 4 17.2268 4 15.7333 4H8.26667C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667V15.7333C4 17.2268 4 17.9735 4.29065 18.544C4.54631 19.0457 4.95426 19.4537 5.45603 19.7094C6.02646 20 6.77319 20 8.26667 20Z"
                      :stroke="permode ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-title :style="`color:${permode ? '#3B82F6' : '#334051'};`">Performance</v-list-item-title>
              </template>

              <div class="ml-4 pl-5" style="border-left: 3px solid #d9d9d9">
                <v-list-item @click="listval(vals = 'per')">
                  <v-list-item-content class="pl-4">
                    <v-list-item-title :style="`color:${activeitem == 'per' ? '#3B82F6' : '#334051'};`">Revenue</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="listval(vals = 'stat')">
                  <v-list-item-content class="pl-4">
                    <v-list-item-title :style="`color:${activeitem == 'stat' ? '#3B82F6' : '#334051'};`">Statement</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group> -->



            <!-- <v-list-item @click="listval(vals = 'accs')">
              <v-list-item-avatar tile size="22">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7116 9.33333H6.22222C4.99492 9.33333 4 8.33841 4 7.11111C4 5.88381 4.99492 4.88889 6.22222 4.88889H14.7116M9.28844 19.1111H17.7778C19.0051 19.1111 20 18.1162 20 16.8889C20 15.6616 19.0051 14.6667 17.7778 14.6667H9.28844M4 16.8889C4 18.6071 5.39289 20 7.11111 20C8.82933 20 10.2222 18.6071 10.2222 16.8889C10.2222 15.1707 8.82933 13.7778 7.11111 13.7778C5.39289 13.7778 4 15.1707 4 16.8889ZM20 7.11111C20 8.82933 18.6071 10.2222 16.8889 10.2222C15.1707 10.2222 13.7778 8.82933 13.7778 7.11111C13.7778 5.39289 15.1707 4 16.8889 4C18.6071 4 20 5.39289 20 7.11111Z"
                    :stroke="activeitem == 'accs' ? '#3B82F6' : '#334051'" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>User info</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

          </v-list-item-group>
        </v-list>
        <template v-slot:append>
          <v-divider></v-divider>
          <div>
            <v-list-item two-line @click="listval(vals = 'accs')">
              <v-list-item-avatar>
                <v-avatar color="#3B82F6">
                  <span class="white--text font-weight-bold title text-uppercase mr-2">{{
                    clientCode.slice(0, 1)
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="lstctntit">{{ clientCode }}</v-list-item-title>
                <v-list-item-subtitle>{{ clientEmail }}</v-list-item-subtitle>
              </v-list-item-content>
              <button id="logout-button" @click="handleLogout()">
                <v-icon size="24" color="#3B82F6">mdi-logout </v-icon>
              </button>
            </v-list-item>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
  </div>
</template>
  
<script>
//   import Vue from "vue";
//   import VueCryptojs from "vue-cryptojs";
//   Vue.use(VueCryptojs);
export default {
  data() {
    return {
      permode: null,
      clientCode: "",
      clientEmail: '',
      emailId: '',
      sideOptionsDesktop: 0,
      activeitem: 'dash',
      sideOptionsMobile: '',
      // signinemailname: "",
      drawer: false,
    };
  },
  mounted() {
    this.clientCode = localStorage.getItem("clientid")
    this.clientEmail = localStorage.getItem("email")
    this.session = localStorage.getItem("session")
    let valsto = localStorage.getItem("activeitem")
    if ((valsto == 'dash') || (valsto == 'clilst') || (valsto == 'per') || (valsto == 'stat') || (valsto == 'accs') || (valsto == 'cliacc')) {
      this.activeitem = valsto;
    } else {
      this.activeitem = 'dash';
    }
    if (this.clientCode && this.session) {
      this.emailId = localStorage.getItem("email")
      // console.log("declocalstore mounted", this.clientCode);
    } else {
      this.$router.push("/")
    }

    if (this.activeitem == 'dash') {
      this.$router.push("/dashboard");
      this.sideOptionsDesktop = 0;
    } else if (this.activeitem == 'cliacc') {
      this.$router.push("/accounts");
      this.sideOptionsDesktop = 1;
    } else if (this.activeitem == 'clilst') {
      this.$router.push("/clients");
      this.sideOptionsDesktop = 2;
    } else if (this.activeitem == 'per') {
      this.$router.push("/revenue");
      this.sideOptionsDesktop = 3;
    } else if (this.activeitem == 'stat') {
      this.$router.push("/statement");
      this.sideOptionsDesktop = 4;
    }else if (this.activeitem == 'feed') {
      this.$router.push("/feed");
      this.sideOptionsDesktop = 5;
    }
    else if (this.activeitem == 'download') {
      this.$router.push("/download");
      this.sideOptionsDesktop = 5;
    }
      else if (this.activeitem == 'accs') {
      this.$router.push("/user");
      this.sideOptionsDesktop = 6;
    }
    else if (this.activeitem == 'query') {
      this.$router.push("/support");
      this.sideOptionsDesktop = 6;
    }
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    handleLogout() {
      localStorage.clear();
      this.$router.push("/");
    },
    listval(vals) {
      this.activeitem = vals;
      console.log("listval", vals, this.sideOptionsDesktop);
      if (this.activeitem == 'dash') {
        this.$router.push("/dashboard")
        this.sideOptionsDesktop = 0;
      } else if (this.activeitem == 'cliacc') {
        this.$router.push("/accounts")
        this.sideOptionsDesktop = 1;
      } else if (this.activeitem == 'clilst') {
        this.$router.push("/clients")
        this.sideOptionsDesktop = 2;
      } else if (this.activeitem == 'per') {
        this.$router.push("/revenue")
        this.sideOptionsDesktop = 3;
      } else if (this.activeitem == 'stat') {
        this.$router.push("/statement")
        this.sideOptionsDesktop = 4;
      }else if (this.activeitem == 'feed') {
      this.$router.push("/feed");
      this.sideOptionsDesktop = 5;
    } 
    else if (this.activeitem == 'download') {
      this.$router.push("/download");
      this.sideOptionsDesktop = 5;
    } 
      else if (this.activeitem == 'accs') {
        this.$router.push("/user")
        this.sideOptionsDesktop = 6;
      }
      else if (this.activeitem == 'query') {
      this.$router.push("/support");
      this.sideOptionsDesktop = 6;
    }
      localStorage.setItem('activeitem', vals)
    }
  },
};
</script>
  
<style>
.lstctntit {
  font-weight: 700 !important;
}

.ctnbtl {
  font-weight: 500 !important;
}

.trmnucolsl {
  background-color: #f9fbfc !important;
  background-size: 80% !important;
}

.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 4px !important;
}

.v-data-table .v-data-table__wrapper table thead th {
  z-index: 0 !important;
  background-color: #f9fbfc !important;
  color: #878b93 !important;
  border-color: #eceef2 !important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 5px;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  border-radius: 10px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.v-main {
  padding-left: 256px !important;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6) !important;
  border-radius: 16px !important;
}

@media only screen and (max-width: 959px) {
  .appbar {
    position: fixed !important;
    z-index: 1 !important;
  }

  .v-main {
    padding-left: 0px !important;
  }

  .v-menu__content {
    box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08) !important;
  }
}
</style>