import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyCSREEWhQ-t8S9KcIRxt2h4yB97rHqooYk",
  authDomain: "zebu-pd.firebaseapp.com",
  projectId: "zebu-pd",
  storageBucket: "zebu-pd.appspot.com",
  messagingSenderId: "621084255985",
  appId: "1:621084255985:web:4899d81d5c5fe85c532b50",
  measurementId: "G-493RGSXR9Z"
};

 initializeApp(firebaseConfig);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
