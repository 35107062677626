import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BrokerageView from '../views/ClientsView.vue'
import Analysis from '../views/AnalysisView.vue'
import PerfomanceView from '../views/RevenueView.vue'
import AccountOpening from '../views/AccountOpening.vue'
import StatementVIew from '../views/StatementVIew.vue'
import AccountsPage from '../views/ProfileView.vue'
import LogIn from '../components/HelloWorld.vue'
import SideBar from '../views/SideBar.vue'
import FeedSrc from '../views/FeedSrc.vue'
import AdminPage from '../views/AdminPage.vue'
import SinglePage from '../views/SinglePage.vue'
import DownloadSrc from '../views/DownloadSrc.vue'
import AdminLogin from '../views/AdminLogin.vue'
import QuerySrc from '../views/QuerySrc.vue'







Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'LogIn',
        component: LogIn
    },
    {
        path: '/sidebar',
        name: 'sidebar',
        component: SideBar
    },

    {
        path: '/',
        redirect: 'signin',
        name: 'Homeview',
        component: HomeView,
        children: [{
                path: '/dashboard',
                name: 'dashboard',
                component: () =>
                    import ('../views/DashboardView.vue')
            },
            {
                path: '/clients',
                name: 'clients',
                component: BrokerageView
            },
            {
                path: '/analysis',
                name: 'analysis',
                component: Analysis
            },
            {
                path: '/revenue',
                name: 'perfomance',
                component: PerfomanceView
            },
            {
                path: '/statement',
                name: 'statement',
                component: StatementVIew
            },
            {
                path: '/accounts',
                name: 'accounts',
                component: AccountOpening
            },
            {
                path: '/user',
                name: 'settings',
                component: AccountsPage
            },
            {
                path: '/feed',
                name: 'FeedSrc',
                component: FeedSrc
            },
            {
                path: '/download',
                name: 'DownloadSrc',
                component: DownloadSrc
            },
            {
                path: '/support',
                name: 'QuerySrc',
                component: QuerySrc
            },

        ]
    },
    {
        path: '/adminpage',
        name: 'AdminPage',
        component: AdminPage
    },
    {
        path: '/singlepage',
        name: 'SinglePage',
        component: SinglePage
    },
    {
        path: '/adminlogin',
        name: 'AdminLogin',
        component: AdminLogin
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router