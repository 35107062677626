<template>
  <div>
    <div>
      <SideMenubar />
    </div>
    <v-main>
      <div class="mt-12 mt-md-0">
        <router-view />
      </div>
    </v-main>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import SideMenubar from './SideBar.vue';

export default {
  name: 'HomeW',

  components: {
    SideMenubar,
  },
}
</script>
<style>
/* #mainapp {
  background-color: rgb(245, 245, 245);
} */
</style>
