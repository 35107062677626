<template>
    <div class="">
      <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important;" transition="slide-x-reverse-transition"
        v-model="snackbar" timeout="3000" :value="true" :color="snackbarclr" absolute outlined top right>
        <v-icon class="mr-2" :color="snackbarclr">mdi-alert-outline</v-icon>
        {{ snackmsgbar }}
        <v-icon @click="snackbar = false" class="float-right" :color="snackbarclr">mdi-close-circle</v-icon>
  
      </v-snackbar>
      <v-container fill-height class="SsoView">
        <v-card max-width="440px" class="mx-auto pa-7 my-auto py-8 pb-1 rounded-xl   body-temp">
       
            <div class="text-none " style="text-align: left;"><img           src="https://zebu.mynt.in/static/logo/mainlogo.svg"
 style="max-width: 90px;" alt="zebulogo"></div> 
        <!-- <p class="font-weight-bold display-1 mb-0 black--text">Admin</p> -->
        <p class="subtitle-2 font-weight-bold mb-5 text-666 " style="text-align: left;color:#666666">Welcome to Zebu</p>
        <p class="headline pb-4" style="font-weight: 800;">Login to Feeds Admin</p>

        <div class=" ">
          <!-- <img src="@/assets/BD.svg" width="70%"> -->
          <!-- <v-card-subtitle class="body-1">
          </v-card-subtitle> -->
          <p class="font-weight-medium  pb-4" style="font-size: 17px;">Sign in your account using with Google</p>
          <v-btn style="background-color:#020202" @click="score()"  rounded  block large class="btn  mt-8" elevation="0"><span class="text-none white--text"><v-icon class="mr-2">mdi-google</v-icon><b>Sign in with Google</b></span></v-btn>
          <v-card-subtitle class="text-center mb-3">
            <p style="color: #666" class="mt-8 font-weight-medium subtitle-2">
              By continuing with Google, you agree our Terms and
              Privacy Policy.
            </p>
          </v-card-subtitle>
          </div>
        </v-card>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      </v-container>
    </div>
  </template>
  
  <script>
//   import axios from "axios";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";

  
  export default {
    data() {
      return {
        overlay:false,
        snackbar: false,
        snackmsgbar: "",
      
        snackbarclr:"#2a2a2a",
      nameRules: [(v) => !!v || "Required"],

      };
    },  
    methods: {
       
    score() {
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      provider.setCustomParameters({
        prompt: "select_account",
      });
      signInWithPopup(getAuth(), provider)
        .then((result) => {
            localStorage.clear();
          if (result.user.email.split('@')[1] == 'zebuetrade.com') {
          
            localStorage.setItem("adminname", result.user.displayName);
            localStorage.setItem("adminemail", result.user.email);
          localStorage.setItem("photo", result.user.photoURL);

            this.email = result.user.email;
            this.snackbarclr = "#43a833";
            window.location.assign("/adminpage");


            this.branchdialog = true;
           
            this.subbtn = false;

          } else {
            this.snackbar = true;
            this.snackbarclr = "#f23645";
            this.snackmsgbar = 'invalid email address';
          }
         

     
    })
    .catch(function (error) {
          console.error(error);
        })
    }
 
  }
}
  </script>
  
  <style>
  /* @import '../assets/style.css'; */
  .body-temp {
    background-color: transparent !important;
    border: 1px solid #f1f6f5 !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 255, 0.03),
      0px 5px 22px rgba(0, 85, 255, 0.02) !important;
    /* height: 90vh !important;
    width: 40% !important; */
    }



::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #94949490;
  border-radius: 16px;
}

.v-slide-group__next,
.v-slide-group__prev {
  min-width: 32px !important;
}







.SsoView {
  height: 100vh !important;
  overflow-y: scroll !important;
}






.cards {
  /* height: 80%; */
  /* background-image: linear-gradient(to right top, #3b82f6, #4086f8, #468afb, #4b8ffd, #5093ff) !important; */
  box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 0.25) inset !important;
  background: #ffffffce !important;
  /* box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.2) !important; */
  backdrop-filter: blur(16px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}



  </style>