<template>
  <v-app>
    <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition" v-model="snackbar"
      timeout="2000" :value="true" color="warning" absolute outlined top right>
      <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
      {{ errMsg }}
    </v-snackbar>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="px-sm-4">
        <v-toolbar dense class="elevation-0 mb-4 mt-6 pr-4">
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold headline">Revenue</v-list-item-title>
                <v-list-item-subtitle class="body-1 mb-0 mt-1" color="#878B93">
                  Sum of day and client wise brokerage here.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field class="mr-4 mt-9 rounded-lg d-none d-md-flex" style="max-width: 160px" dense outlined
            v-if="pnltab == 1" flat color="gray" label="Search" v-model="searchText" prepend-inner-icon="mdi-magnify">
          </v-text-field>

          <div v-if="pnltab == 0">
            <v-tooltip open-delay="400" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card v-bind="attrs" v-on="on" :color="`${daytabval == 'currentmonth' ? '#EFF6FF' : ''}`"
                  @click="daytableFilter((val = 'currentmonth'))" width="40px" outlined
                  class="mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center">
                  <span :style="`color:${daytabval == 'currentmonth' ? '#3B82F6' : 'black'
                    };`" class="caption">CM</span>
                </v-card>
              </template>
              <span>Current month</span>
            </v-tooltip>
          </div>
          <div v-if="pnltab == 0">
            <v-tooltip open-delay="400" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card v-bind="attrs" v-on="on" :color="`${daytabval == 'lastmonth' ? '#EFF6FF' : ''}`"
                  @click="daytableFilter((val = 'lastmonth'))" width="40px" outlined
                  class="mt-2 py-2 elevation-0 rounded-0 text-center">
                  <span :style="`color:${daytabval == 'lastmonth' ? '#3B82F6' : 'black'
                    };`" class="caption">LM</span>
                </v-card>
              </template>
              <span>Last month</span>
            </v-tooltip>
          </div>
          <v-card v-if="pnltab == 0" :color="`${daytabval == 'threemonth' ? '#EFF6FF' : ''}`"
            @click="daytableFilter((val = 'threemonth'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${daytabval == 'threemonth' ? '#3B82F6' : 'black'
              };`" class="caption">3M</span>
          </v-card>
          <v-card v-if="pnltab == 0" :color="`${daytabval == 'sixmonth' ? '#EFF6FF' : ''}`"
            @click="daytableFilter((val = 'sixmonth'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${daytabval == 'sixmonth' ? '#3B82F6' : 'black'};`" class="caption">6M</span>
          </v-card>
          <v-card v-if="pnltab == 0" :color="`${daytabval == 'oneyear' ? '#EFF6FF' : ''}`"
            @click="daytableFilter((val = 'oneyear'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${daytabval == 'oneyear' ? '#3B82F6' : 'black'};`" class="caption">1Y</span>
          </v-card>
          <v-menu v-if="pnltab == 0" v-model="menu2" :close-on-content-click="false" :nudge-left="250"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-tooltip v-bind="attrs" v-on="on" open-delay="400" color="black" bottom>
                <template v-slot:activator="{ on, attrs }"> -->
              <v-card v-bind="attrs" v-on="on" :color="`${daytabval == 'custom' ? '#EFF6FF' : ''}`" width="48px" outlined
                class="mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center">
                <span :style="`color:${daytabval == 'custom' ? '#3B82F6' : 'black'
                  };`" class="caption">CUST</span>
              </v-card>
              <!-- </template>
                <span>Custom date</span>
              </v-tooltip> -->
            </template>
            <v-date-picker v-model="dateSelect" :max="maxDate" type="month"
              @input="(menu2 = false), dayCustom()"></v-date-picker>
          </v-menu>

          <div v-if="pnltab == 1">
            <v-tooltip open-delay="400" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card v-bind="attrs" v-on="on" :color="`${clitabval == 'currentmonth' ? '#EFF6FF' : ''}`"
                  @click="clitableFilter((val = 'currentmonth'))" width="40px" outlined
                  class="mt-2 py-2 elevation-0 rounded-l-lg rounded-0 text-center">
                  <span :style="`color:${clitabval == 'currentmonth' ? '#3B82F6' : 'black'
                    };`" class="caption">CM</span>
                </v-card>
              </template>
              <span>Current month</span>
            </v-tooltip>
          </div>
          <div v-if="pnltab == 1">
            <v-tooltip open-delay="400" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-card v-bind="attrs" v-on="on" :color="`${clitabval == 'lastmonth' ? '#EFF6FF' : ''}`"
                  @click="clitableFilter((val = 'lastmonth'))" width="40px" outlined
                  class="mt-2 py-2 elevation-0 rounded-0 text-center">
                  <span :style="`color:${clitabval == 'lastmonth' ? '#3B82F6' : 'black'
                    };`" class="caption">LM</span>
                </v-card>
              </template>
              <span>Last month</span>
            </v-tooltip>
          </div>
          <v-card v-if="pnltab == 1" :color="`${clitabval == 'threemonth' ? '#EFF6FF' : ''}`"
            @click="clitableFilter((val = 'threemonth'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${clitabval == 'threemonth' ? '#3B82F6' : 'black'
              };`" class="caption">3M</span>
          </v-card>
          <v-card v-if="pnltab == 1" :color="`${clitabval == 'sixmonth' ? '#EFF6FF' : ''}`"
            @click="clitableFilter((val = 'sixmonth'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${clitabval == 'sixmonth' ? '#3B82F6' : 'black'};`" class="caption">6M</span>
          </v-card>
          <v-card v-if="pnltab == 1" :color="`${clitabval == 'oneyear' ? '#EFF6FF' : ''}`"
            @click="clitableFilter((val = 'oneyear'))" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span :style="`color:${clitabval == 'oneyear' ? '#3B82F6' : 'black'};`" class="caption">1Y</span>
          </v-card>
          <v-menu v-if="pnltab == 1" v-model="menu22" :close-on-content-click="false" transition="scale-transition"
            offset-y>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-tooltip v-bind="attrs" v-on="on" open-delay="400" color="black" bottom>
                <template v-slot:activator="{ on, attrs }"> -->
              <v-card v-bind="attrs" v-on="on" :color="`${clitabval == 'custom' ? '#EFF6FF' : ''}`" width="48px" outlined
                class="mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center">
                <span :style="`color:${clitabval == 'custom' ? '#3B82F6' : 'black'
                  };`" class="caption">CUST</span>
              </v-card>
              <!-- </template>
                <span>custom date</span>
              </v-tooltip> -->
            </template>
            <v-card width="580px">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-date-picker v-model="dateSelect2" :max="maxDate2"></v-date-picker>
                </v-col>
                <v-col cols="6">
                  <v-date-picker v-model="dateSelectto" :max="maxDate2"
                    @input="(menu22 = false), (menu2to = false), cliCustom()"></v-date-picker>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <!-- <v-card
            :color="`${pnltab == 1 ? (clitabval == 'threemonth' ? '#EFF6FF' : '') : (daytabval == 'threemonth' ? '#EFF6FF' : '')}`"
            @click="pnltab == 1 ? clitableFilter(val = 'threemonth') : daytableFilter(val = 'threemonth')" width="40px"
            outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span
              :style="`color:${pnltab == 1 ? (clitabval == 'threemonth' ? '#3B82F6' : 'black') : (daytabval == 'threemonth' ? '#3B82F6' : 'black')};`"
              class="caption">3M</span>
          </v-card>
          <v-card
            :color="`${pnltab == 1 ? (clitabval == 'sixmonth' ? '#EFF6FF' : '') : (daytabval == 'sixmonth' ? '#EFF6FF' : '')}`"
            @click="pnltab == 1 ? clitableFilter(val = 'sixmonth') : daytableFilter(val = 'sixmonth')" width="40px"
            outlined class="mt-2 py-2 elevation-0 rounded-0 text-center">
            <span
              :style="`color:${pnltab == 1 ? (clitabval == 'sixmonth' ? '#3B82F6' : 'black') : (daytabval == 'sixmonth' ? '#3B82F6' : 'black')};`"
              class="caption">6M</span>
          </v-card>
          <v-card
            :color="`${pnltab == 1 ? (clitabval == 'oneyear' ? '#EFF6FF' : '') : (daytabval == 'oneyear' ? '#EFF6FF' : '')}`"
            @click="pnltab == 1 ? clitableFilter(val = 'oneyear') : daytableFilter(val = 'oneyear')" width="40px" outlined
            class="mt-2 py-2 elevation-0 rounded-r-lg rounded-0 text-center">
            <span
              :style="`color:${pnltab == 1 ? (clitabval == 'oneyear' ? '#3B82F6' : 'black') : (daytabval == 'oneyear' ? '#3B82F6' : 'black')};`"
              class="caption">1Y</span>
          </v-card> -->

          <!-- <v-card class="rounded-lg ml-4 mt-2" outlined>
            <p class="mb-0 pa-2 body-2">
             <v-icon>mdi-filter-variant</v-icon> Filter actions
            </p>
           </v-card> -->

          <!-- <v-menu v-model="menu2to" :close-on-content-click="false" :nudge-left="150" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="gray" flat v-model="dateSelectto" label="To" hide-details dense class="ml-4"
                max-width="100" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
           
          </v-menu> -->
        </v-toolbar>
        <div class="px-4 pt-2">
          <!-- <div class="mb-4">
            <v-row>
              <v-col cols="6" md="2">
                <v-card height="146px" outlined width="100%">
                  <img src="@/assets/totper.svg" width="60px" class="mx-4 mt-4">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Total Business</v-list-item-subtitle>
                      <v-list-item-title>{{ pnltab == 0 ? overalTotal > 0 ? overalTotal.toLocaleString() :
                        0 : overalTotalClient > 0 ? overalTotalClient : 0 }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6" md="4">
                <v-row>
                  <v-col cols="12" class="pb-1">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Top Perfomance</v-list-item-subtitle>
                          <v-list-item-title>{{ pnltab == 0 ? topPerfom.total > 0 ? topPerfom.total.toLocaleString() :
                            0 : topPerfomClient > 0 ? topPerfomClient : 0 }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <img src="@/assets/totperf.svg" width="32px" class="ml-2">
                        </v-list-item-icon>
                      </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined width="100%">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle>Worst Perfomance</v-list-item-subtitle>
                          <v-list-item-title>{{ pnltab == 0 ? worstPerfom.total > 0 ? worstPerfom.total.toLocaleString() :
                            0 : worstPerfomClient > 0 ? worstPerfomClient.toLocaleString() : 0 }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <img src="@/assets/lowperf.svg" width="32px" class="ml-2 mt-2">
                        </v-list-item-icon>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined width="100%" height="146px">
                  <v-row no-gutters>
                    <v-col>
                      <div class="pa-4" style="overflow:hidden;">
                        <v-row no-gutters>
                          <v-col>
                            <p class="mb-2">Performance Map</p>
                          </v-col>
                          <v-col class="text-right">
                            <img src="@/assets/permap.svg" width="28px" class="mr-2" alt="">
                          </v-col>
                        </v-row>
                        <div class="trdditl" style="height:100px; overflow-y:auto;">
                          <v-row no-gutters>
                            <v-col>
                              <div v-if="pnltab == 0">
                                <div v-for="n in clientData" :key="n" outlined class="pa-0 ma-0 " :data-index="n">
                                  <div>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                          <v-card v-if="n.total == 0" width="4px" class="colums" color="#E8EBED"
                                            style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                         
                                         
                                          <v-card  width="4px" class="colums" color="#cce1fa"
                            style="margin: 2px; padding: 6px" outlined tile>
                          </v-card>
                          <v-card  width="4px" class="colums" color="#95bef3"
                            style="margin: 2px; padding: 6px" outlined tile>
                          </v-card>
                          <v-card  width="4px" class="colums" color="#5c9ef1"
                            style="margin: 2px; padding: 6px" outlined tile>
                          </v-card>
                          <v-card  width="4px" class="colums" color="#3B82F6"
                            style="margin: 2px; padding: 6px" outlined tile>
                          </v-card>


                                          <v-card v-else-if="n.total < colormis" width="4px" class="colums"
                                            color="#cce1fa" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="n.total < colormis * 2" width="4px" class="colums"
                                            color="#95bef3" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="n.total < colormis * 3" width="4px" class="colums"
                                            color="#5c9ef1" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="n.total > colormis * 3" width="4px" class="colums"
                                            color="#3B82F6" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                        </div>
                                      </template>
                                      <p class="mb-0 white--text">
                                        <span class="font-weight-bold">{{ n.total?.toFixed(2) }} </span>on {{
                                          n.Trade_Date.slice(0,
                                            10)
                                        }}
                                     
                                     
                                        {{
                          new Date(n.dates).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        }}

                                      </p>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>

                              <div v-if="pnltab == 1">
                                <div v-for="na in clientWiseData" :key="na" outlined class="pa-0 ma-0 " :data-index="n">
                                  <div>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                          <v-card v-if="na.total == 0" width="4px" class="colums" color="#E8EBED"
                                            style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                         
                                         
                                          <v-card  width="4px" class="colums" color="#cce1fa"
                               style="margin: 2px; padding: 6px" outlined tile>
                             </v-card>
                             <v-card  width="4px" class="colums" color="#95bef3"
                               style="margin: 2px; padding: 6px" outlined tile>
                             </v-card>
                             <v-card  width="4px" class="colums" color="#5c9ef1"
                               style="margin: 2px; padding: 6px" outlined tile>
                             </v-card>
                             <v-card  width="4px" class="colums" color="#3B82F6"
                               style="margin: 2px; padding: 6px" outlined tile>
                             </v-card>


                                          <v-card v-else-if="na.total < colormiss" width="4px" class="colums"
                                            color="#cce1fa" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="na.total < colormiss * 2" width="4px" class="colums"
                                            color="#95bef3" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="na.total < colormiss * 3" width="4px" class="colums"
                                            color="#5c9ef1" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                          <v-card v-else-if="na.total > colormiss * 3" width="4px" class="colums"
                                            color="#3B82F6" style="margin: 2px; padding: 6px" outlined tile>
                                          </v-card>
                                        </div>
                                      </template>
                                      <p class="mb-0 white--text">
                                        <span class="font-weight-bold">{{ na.Client_ID }} Traded</span> {{ na.total }}

                                        
                                        {{
                             new Date(n.dates).toLocaleDateString("en-us", {
                               year: "numeric",
                               month: "short",
                               day: "numeric",
                             })
                           }}

                                      </p>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-col>

                  </v-row>
                </v-card>

              </v-col>
            </v-row>
          </div> -->
          <div class="pt-2">
            <v-tabs v-model="pnltab" flat background-color="transparent" color="#3B82F6" active-class="font-weight-bold">
              <v-tab @click="(menu22 = false), (menu2to = false)">
                <v-badge v-if="clientData.length" bordered color="#3B82F6" :content="clientData.length">
                  <span>Day wise</span>
                </v-badge>
                <span v-else>Day wise</span>
              </v-tab>
              <v-tab @click="menu2 = false">
                <v-badge v-if="clientWiseData.length" bordered color="#3B82F6" :content="clientWiseData.length">
                  <span>Client wise</span>
                </v-badge>
                <span v-else>Client wise</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="pnltab">
              <v-tab-item>
                <div class="trdditl">
                  <v-data-table height="51vh" dense fixed-header class="dataTable"
                    style="border: 1px solid rgba(0, 0, 0, 0.12)" :sort-by="['Trade_Date']" sort-desc must-sort
                    :items-per-page="100" :footer-props="{
                      'items-per-page-options': [10, 30, 50, 100, -1],
                    }" :loading="clientLoader" :headers="headers" :items="clientData">
                    <template v-slot:[`item.Trade_Date`]="{ item }">
                      <span class="font-weight-bold">{{
                        item.Trade_Date.slice(0, 10)
                      }}</span>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                      <div v-if="item.total">{{ item.total?.toFixed(2) }}</div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.NSE_CASH`]="{ item }">
                      <div v-if="item.NSE_CASH">
                        {{ item.NSE_CASH?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.NSE_FNO`]="{ item }">
                      <div v-if="item.NSE_FNO">
                        {{ item.NSE_FNO?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.CD_NSE`]="{ item }">
                      <div v-if="item.CD_NSE">
                        {{ item.CD_NSE?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.MCX`]="{ item }">
                      <div v-if="item.MCX">{{ item.MCX?.toFixed(2) }}</div>
                      <div v-else>0.00</div>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="trdditl">
                  <v-data-table height="51vh" dense fixed-header class="dataTable"
                    style="border: 1px solid rgba(0, 0, 0, 0.12)" :items-per-page="100" :footer-props="{
                      'items-per-page-options': [10, 30, 50, 100, -1],
                    }" :loading="clientLoaderclient" :headers="summaryhead" :items="clientWiseData"
                    :search="searchText" must-sort>
                    <!-- <template v-slot:[`item.total`]="{ item }">
                      {{ item.total?.toFixed(2) }}
                    </template> -->
                    <template v-slot:[`item.Client_ID`]="{ item }">
                      <span class="font-weight-bold">{{ item.Client_ID }}</span>
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                      <div v-if="item.total">{{ item.total?.toFixed(2) }}</div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.NSE_CASH`]="{ item }">
                      <div v-if="item.NSE_CASH">
                        {{ item.NSE_CASH?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.NSE_FNO`]="{ item }">
                      <div v-if="item.NSE_FNO">
                        {{ item.NSE_FNO?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.CD_NSE`]="{ item }">
                      <div v-if="item.CD_NSE">
                        {{ item.CD_NSE?.toFixed(2) }}
                      </div>
                      <div v-else>0.00</div>
                    </template>
                    <template v-slot:[`item.MCX`]="{ item }">
                      <div v-if="item.MCX">{{ item.MCX?.toFixed(2) }}</div>
                      <div v-else>0.00</div>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { apiurl } from "../apiUrl";
import axios from "axios";
export default {
  data() {
    return {
      snackbar: false,
      clientData: [],
      topPerfom: [],
      worstPerfom: [],
      topPerfomClient: [],
      worstPerfomClient: [],
      topPerfomClientid: "",
      worstPerfomClientid: "",
      overalTotalClient: "",
      overalTotal: [],
      // errMsg: "",
      menu2: false,
      menu22: false,
      menu2to: false,
      colormis: 15580,
      colormiss: 97,

      segments: ["ALL", "NSE_FNO", "NSE_CASH", "MCX", "CD_NSE"],
      dateSelect: new Date().toISOString().substr(0, 7),
      maxDate: new Date().toISOString().substr(0, 7),

      dateSelectto: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      dateSelect2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      maxDate2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      clientidsindex: "",
      pnltab: 0,
      usertotaldata: true,
      usernodata: false,

      summaryloader: true,
      daywiseloader: true,

      clientids: ["All"],
      emailDatafetch: [],

      newPnL: [],
      PnLSummary: [],
      PnLdaywise: [],
      tol_DW_mtm: 0,
      tol_Summ_mtm: 0,
      total_day_wise: 0,
      total_summary: 0,
      DW_qty: 0,
      Summ_qty: 0,
      // clientData:[]
      clientLoaderclient: false,
      clientLoader: false,
      errMsg: "",
      datesummary: [],
      startDate: "",
      endDates: "",
      monthname: [],
      coractdata: false,
      nocoractdata: false,
      coractloader: true,
      values: [],
      dateselect: "",
      years: [],
      fromdate: "",
      todate: "",
      searchText: "",

      clitabval: "currentmonth",
      daytabval: "currentmonth",

      clientWiseData: [],
    };
  },

  mounted() {
    this.perfomaceData();
    this.perfomaceDataClientWise();
  },

  methods: {
    clitableFilter(val) {
      if (val != this.clitabval) {
        this.clitabval = val;
        this.perfomaceDataClientWise();
      }
    },
    cliCustom() {
      this.clitabval = "custom";
      this.perfomaceDataClientWise();
    },
    daytableFilter(val) {
      if (val != this.daytabval) {
        this.daytabval = val;
        this.perfomaceData();
      }
    },
    dayCustom() {
      this.daytabval = "custom";
      this.perfomaceData();
    },
    perfomaceData() {
      this.clientLoader = true;
      var clientid = localStorage.getItem("clientid");
      var usession = localStorage.getItem("session");

      this.clientData = [];
      let setdata;
      if (this.daytabval == "custom") {
        setdata = {
          code: clientid,
          values: this.daytabval,
          From_date: this.dateSelect,
        };
      } else {
        setdata = {
          code: clientid,
          values: this.daytabval,
        };
      }
      var data = JSON.stringify(setdata);
      var axiosThis = this;
      var config = {
        method: "post",
        url: `${apiurl}/day_wise`,

        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (
            response.data.msg == "Token is Expired." || response.data.msg == "This ID login in another device." ||
            response.data.msg == "Token is Invalid."
          ) {
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
            setTimeout(() => {
              axiosThis.$router.push("/");
            }, 2000);
          } else {
            axiosThis.clientLoader = false;
            if (response.data.msg != "no data found") {
              console.log(JSON.stringify(response.data));
              axiosThis.overalTotal = response.data.Over_all_totall;

              axiosThis.clientData = response.data.Remeshire_Brk_calc;
              axiosThis.topPerfom = response.data.Maximum_Remeshire_Brk;
              axiosThis.worstPerfom = response.data.Minimum_Remeshire_Brk;
            } else {
              axiosThis.clientData = [];
              axiosThis.errMsg = response.data.msg;
              axiosThis.snackbar = true;
            }
          }
          // axiosThis.clientDataRaw = response.data
        })
        .catch(function (error) {
          axiosThis.clientLoader = false;
          axiosThis.errMsg = "Server Down";
          axiosThis.snackbar = true;

          console.log(error);
        });
    },
    perfomaceDataClientWise() {
      this.clientLoaderclient = true;
      var clientid = localStorage.getItem("clientid");
      var usession = localStorage.getItem("session");

      this.clientWiseData = [];
      let setdata;
      if (this.clitabval == "custom") {
        setdata = {
          code: clientid,
          values: this.clitabval,
          From_date: this.dateSelect2,
          To_date: this.dateSelectto,
        };
      } else {
        setdata = {
          code: clientid,
          values: this.clitabval,
        };
      }
      var data = JSON.stringify(setdata);
      var axiosThis = this;
      var config = {
        method: "post",
        url: `${apiurl}/client_wise`,
        headers: {
          "Content-Type": "application/json",
          Authorization: usession,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          axiosThis.clientLoaderclient = false;
          if (response.data.msg != "no data found") {
            var res = response.data.Client_wise_Remeshire_Brk;
            console.log(JSON.stringify(response.data));
            axiosThis.overalTotalClient = response.data.Over_All_Total;

            axiosThis.clientWiseData = response.data.Client_wise_Remeshire_Brk;
            axiosThis.topPerfomClient =
              response.data.Max_Client_wise_Remeshire_Brk;
            axiosThis.topPerfomClientid =
              response.data.Max_Client_wise_Remeshire_Brk_client_id;

            for (var i = 0; i < res.length; i++) {
              // console.log(res[i])
            }

            axiosThis.worstPerfomClient =
              response.data.Min_Client_wise_Remeshire_Brk;
            axiosThis.worstPerfomClientid =
              response.data.Min_Client_wise_Remeshire_Brk_client_id;
          } else {
            axiosThis.clientWiseData = [];
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
          }
          // axiosThis.clientDataRaw = response.data
        })
        .catch(function (error) {
          axiosThis.clientLoaderclient = false;
          axiosThis.errMsg = "Server Down";
          axiosThis.snackbar = true;
          console.log(error);
        });
    },
    timesetting() {
      // var time1 =  new Date().toISOString().slice(0,10)
      var d = new Date();
      d.setDate(d.getDate() - 1);
      console.log("time1,time2,d", d);

      this.dateSelect2 = new Date(d - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      console.log("time1,time2,d", this.dateSelect2);
    },
    //         perfomaceDataClickClient() {
    //             var clientid = localStorage.getItem('clientid');
    //    var usession = localStorage.getItem('session')

    //             var d = new Date();
    //             d.setMonth(d.getMonth() - 1);
    //             this.dateSelect2 =  (new Date(d - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

    //             this.clientWiseData = []
    //             this.clientLoaderclient = true;
    //             var data = JSON.stringify({
    //                 "From_date": this.dateSelect2,
    //                 "To_date": this.dateSelectto,
    //                 code: clientid,
    //             });
    //             console.log("xx", data);

    //             var axiosThis = this;
    //             var config = {
    //                 method: "post",
    //                 url: "http://192.168.5.163:5512/client_wise",
    //                  url: `${apiurl}/client_wise`,

    //                 headers: {
    //                     "Content-Type": "application/json",
    //                       'Authorization': usession
    //                 },
    //                 data: data,
    //             };

    //             axios(config)
    //                 .then(function (response) {
    //                     // console.log(response.data);
    //                     axiosThis.clientLoaderclient = false;

    //                     if(!response.data.msg) {
    //                     console.log(JSON.stringify(response.data));
    //                     axiosThis.overalTotalClient = response.data.Over_All_Total;

    // axiosThis.clientWiseData = response.data.Client_wise_Remeshire_Brk;
    // axiosThis.topPerfomClient = response.data.Max_Client_wise_Remeshire_Brk;
    // axiosThis.topPerfomClientid = response.data.Max_Client_wise_Remeshire_Brk_client_id;

    // axiosThis.worstPerfomClient = response.data.Min_Client_wise_Remeshire_Brk;
    // axiosThis.worstPerfomClientid = response.data.Min_Client_wise_Remeshire_Brk_client_id;
    //                     } else {
    //                         axiosThis.errMsg = response.data.msg
    //                         axiosThis.snackbar = true
    //                     }
    //                     // axiosThis.clientDataRaw = response.data
    //                 })
    //                 .catch(function (error) {
    //                     axiosThis.clientLoaderclient = false;

    //                     console.log(error);
    //                 });
    //         },
  },

  computed: {
    headers() {
      return [
        { text: "Date", value: "Trade_Date" },
        { text: "EQ", value: "NSE_CASH", sortable: false },
        { text: "FNO", value: "NSE_FNO", sortable: false },
        { text: "CDS", value: "CD_NSE", sortable: false },
        { text: "MCX", value: "MCX", sortable: false },
        { text: "TOTAL", value: "total" },
        // { text: "Trade Date", value: "Trade_Date" },
      ];
    },
    summaryhead() {
      return [
        // { text: "Name", value: "name" },
        { text: "ClientId", value: "Client_ID" },
        { text: "EQ", value: "NSE_CASH", sortable: false },
        { text: "FNO", value: "NSE_FNO", sortable: false },
        { text: "CDS", value: "CD_NSE", sortable: false },
        { text: "MCX", value: "MCX", sortable: false },
        { text: "TOTAL", value: "total" },
      ];
    },
  },
};
</script>

<style>
/* .headline { */
/* font-family: 'DM Sans' !important; */
/* font-weight: 500; */
/* } */
.trdditl::-webkit-scrollbar {
  width: 6px;
}

.trdditl::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.trdditl::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 16px;
}

.toolbar .v-toolbar__content {
  padding: 0 !important;
}

.datedetailshead .v-data-table-header__sort-badge {
  display: none !important;
}

.v-menu__content.theme--light.menuable__content__active {
  overflow-y: hidden !important;
}
</style>
<style lang="scss">
.colums {
  position: static;
  float: left;
}
</style>
