<template>
    <v-app>
        <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="2000" :value="true" :color="snackbarclr" absolute outlined top right>
            <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
            {{ errMsg }}
        </v-snackbar>
        <v-container class="pa-0 pa-sm-3 pa-md-3">
            <div class="px-sm-4">
                <v-toolbar dense class="elevation-0  mt-6 pr-4">
                    <v-toolbar-title>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-if="newtickt" class="font-weight-bold headline">Raise a
                                    Ticket</v-list-item-title>
                                <v-list-item-title v-if="oldticket" class="font-weight-bold headline">My
                                    Tickets</v-list-item-title>


                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-select v-if="oldticket" v-model="filterQuery" :items="autocompleteItems" clearable dense
                        hide-details outlined rounded placeholder="All" style="max-width:150px" class="mr-3 mb-1">
                    </v-select> -->
                    <!-- <v-btn class="text-none" @click="raisetic()" v-if="oldticket" text><v-icon
                            class="mr-2">mdi-ticket-confirmation-outline</v-icon>Create a
                        Ticket</v-btn> -->
                    <v-btn @click="ticketlistfun()" v-if="newtickt" class="text-none" text><v-icon
                            class="mr-2">mdi-ticket-confirmation-outline</v-icon>My Tickets</v-btn>
                </v-toolbar>
                <v-divider></v-divider>

                <div class="px-sm-4 py-4" v-if="oldticket">

                    <div>
                        <v-row>
                            <v-col cols="4">



                                <!-- <v-text-field clearable prepend-inner-icon="mdi-magnify" v-model="searchQuery" dense
                                    hide-details outlined placeholder="search" class=""></v-text-field> -->

                                <v-autocomplete v-model="searchQuery" :items="autocompleteItems"
                                    prepend-inner-icon="mdi-magnify" clearable dense hide-details outlined
                                    placeholder="Search or select" class="mr-3 mb-1" :filter="customFilter">
                                </v-autocomplete>



                                <v-card :loading="showlistload" elevation="0" outlined class="mt-3" tile
                                    style="height: 65vh;overflow: scroll">



                                    <v-list class="pa-0 ">
                                        <v-list-item-group v-model="selectedItem" color="primary">
                                            <v-list-item style="border-bottom: 1px solid #E0E0E0" elevation="0"
                                                :disabled="selectlist" @click="selectticket(show), ticketstaus(show)"
                                                v-for="show in filteredShowlist" :key="show.ticket_id">

                                                <v-list-item-content>
                                                    <v-list-item-title class="body-2 ">Ticket ID - {{ show.ticket_id
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle class="caption">{{ show.request_date_time
                                                        }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-chip v-if="show.ticket_status == 'open'" small color="green"
                                                        class="white--text">open</v-chip>
                                                    <v-chip v-if="show.ticket_status == 'close'" small color="red"
                                                        class="white--text">Close</v-chip>
                                                    <!-- <v-list-item-action-text>{{ show.ticket_id }}</v-list-item-action-text> -->

                                                </v-list-item-action>

                                            </v-list-item>

                                        </v-list-item-group>

                                    </v-list>
                                    <v-card elevation="0" v-if="filteredShowlist == ''" class="text-center pa-5 py-15">
                                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M90.0699 49.7871C89.5137 48.9418 88.6027 48.4371 87.6356 48.4371H84.3371C83.5137 28.1246 67.0277 12.498 46.8746 12.498C26.1965 12.498 9.37305 29.3215 9.37305 49.9996C9.37305 70.6777 26.1965 87.5012 46.8746 87.5012C56.6668 87.5012 65.9309 83.7512 72.9621 76.9402L70.7887 74.6934C64.3434 80.9371 55.8496 84.3762 46.8746 84.3762C27.9199 84.3762 12.498 68.9543 12.498 49.9996C12.498 31.0449 27.9199 15.623 46.8746 15.623C65.3059 15.623 80.3902 31.2496 81.2105 48.4371H77.9902C77.023 48.4371 76.1121 48.9418 75.5543 49.7871C74.934 50.7324 74.8262 51.9699 75.2777 53.0199L78.8215 61.2746C79.559 62.9934 81.0871 64.0605 82.8121 64.0605C84.5371 64.0605 86.0652 62.9918 86.8027 61.2746L90.3465 53.0199C90.798 51.9715 90.6918 50.7324 90.0699 49.7871ZM87.4746 51.7887L83.9309 60.0434C83.4418 61.1793 82.1824 61.1793 81.6934 60.0434L78.1496 51.7887C78.1137 51.7012 78.1246 51.6168 78.1402 51.5621H87.4855C87.5027 51.6168 87.5121 51.7012 87.4746 51.7887Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.6625 38.5064C47.2172 38.8721 47.875 39.0596 48.5359 39.0596C48.9984 39.0596 49.4625 38.9689 49.8937 38.783L58.15 35.2392C59.8703 34.5033 60.9375 32.9736 60.9375 31.2502C60.9375 29.5267 59.8687 27.9971 58.1516 27.2596L49.8953 23.7158C48.8469 23.2658 47.6078 23.3705 46.6641 23.9924C45.8172 24.5486 45.3125 25.458 45.3125 26.4267V29.7674C34.375 30.5689 26.5625 39.3267 26.5625 50.0002C26.5625 61.2002 35.675 70.3127 46.875 70.3127C58.075 70.3127 67.1875 60.9377 67.1875 50.0002H64.0625C64.0625 59.3752 56.3516 67.1877 46.875 67.1877C37.3984 67.1877 29.6875 59.4767 29.6875 50.0002C29.6875 41.0502 35.9375 33.6846 45.3125 32.8908V36.0721C45.3125 37.0408 45.8172 37.9502 46.6625 38.5064ZM48.4375 26.5768C48.4375 26.558 48.5734 26.5502 48.6625 26.5877L56.9188 30.1314C57.4859 30.3752 57.8125 30.783 57.8125 31.2502C57.8125 31.7174 57.4859 32.1252 56.9188 32.3689L48.6625 35.9127C48.5734 35.9486 48.4375 35.9408 48.4375 35.9221V26.5768Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.875 43.75C45.2174 43.75 43.6277 44.4085 42.4556 45.5806C41.2835 46.7527 40.625 48.3424 40.625 50C40.625 51.6576 41.2835 53.2473 42.4556 54.4194C43.6277 55.5915 45.2174 56.25 46.875 56.25C48.5326 56.25 50.1223 55.5915 51.2944 54.4194C52.4665 53.2473 53.125 51.6576 53.125 50C53.125 48.3424 52.4665 46.7527 51.2944 45.5806C50.1223 44.4085 48.5326 43.75 46.875 43.75Z"
                                                fill="#CCCCCC" />
                                        </svg>
                                        <p>No Tickets  Available</p>
                                    </v-card>


                                </v-card>
                                <v-footer class="pa-0 mt-2" v-bind="localAttrs" :padless="padless">
                                    <v-btn color="primary" elevation="0" class="text-none" @click="raisetic()" block
                                        v-if="oldticket"><v-icon
                                            class="mr-2">mdi-ticket-confirmation-outline</v-icon>Create a
                                        Ticket</v-btn>
                                </v-footer>



                            </v-col>
                            <v-divider vertical></v-divider>

                            <v-col cols="8">
                                <v-progress-circular indeterminate v-if="showlistload" color="primary"
                                    class="mx-16 my-16"></v-progress-circular>
                                <div v-if="selectparttick != ''">
                                    <v-card outlined class=" pa-3" :loading="showlistload" elevation="0"
                                        style="height: 100%;">

                                        <v-card elevation="0" style="height: 65vh;overflow: scroll">
                                            <v-list subheader v-for="(ticket, index) in selectparttick" :key="index">


                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar size="38px" v-if="ticket.sender_id === clientCode"
                                                            color="#3B82F6">
                                                            <span
                                                                class="white--text font-weight-bold title text-uppercase">
                                                                {{ clientCode.slice(0, 1) }}
                                                            </span>
                                                        </v-avatar>

                                                        <v-avatar v-else size="36px">
                                                            <img height="30px" width="47px" alt="Avatar"
                                                                src="https://zebuetrade.com/img/zebu.ee5ba741.svg">
                                                        </v-avatar>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold"
                                                            v-if="ticket.sender_id === 'ADMIN'">Zebu
                                                            Support</v-list-item-title>
                                                        <v-list-item-title v-else>{{ clientCode }}</v-list-item-title>
                                                        <v-list-item-title v-if="ticket.sender_id !== 'ADMIN'">{{
                                                            ticket.subject }}</v-list-item-title>


                                                    </v-list-item-content>

                                                    <v-list-item-action>
                                                        <v-list-item-action-text>{{ ticket.request_date_time
                                                            }}</v-list-item-action-text>
                                                        <v-list-item-action-text
                                                            v-if="ticket.sender_id !== 'ADMIN'">Ticket
                                                            ID: {{ ticket.ticket_id == "" ? ticket.reply_ticket_id :
                                                                ticket.ticket_id
                                                            }}</v-list-item-action-text>

                                                    </v-list-item-action>

                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <!-- <p class="title mt-3 font-weight-medium mb-2  px-5">{{ticket.subject}} </p> -->

                                                <p class="caption grey--text font-weight-medium mb-0 mt-3 px-5"
                                                    v-if="ticket.sender_id == 'ADMIN'">Dear Customer,</p>
                                                <p class="caption grey--text font-weight-medium mb-0 mt-3 px-5"
                                                    v-if="ticket.sender_id == 'ADMIN'">Greetings from Zebu!</p>


                                                <p class="subtitle-1  font-weight-medium mb-0 mt-4 px-5">{{
                                                    ticket.message
                                                }}</p>

                                                <p v-if="Object.keys(ticket.file_path).length"
                                                    class="body-2 font-weight-medium mb-1 mt-3 px-5">
                                                    {{ ticket.sender_id === 'ADMIN' ? 'Admin' : 'User' }} Screenshot
                                                </p>

                                                <div v-if="Object.keys(ticket.file_path).length"
                                                    class="d-flex flex-wrap">
                                                    <div v-for="(path, idx) in ticket.file_path" :key="idx"
                                                        class="ml-4">
                                                        <v-card style="width: 350px; height: auto;  cursor: pointer;"
                                                            elevation="0">
                                                            <div @click="openDialog(fullUrl(path))"
                                                                style="cursor: pointer; display: inline-block; width: 100%; height: 100%;">
                                                                <iframe :src="fullUrl(path)"
                                                                    style="width: 100%; height: 250px; object-fit: contain; pointer-events: none;"
                                                                    frameborder="0">
                                                                </iframe>
                                                            </div>
                                                        </v-card>


                                                        <v-btn elevation="0" dense x-small class="text-none ml-2"
                                                            :href="fullUrl(path)" :download="getFilename(path)"
                                                            target="_blank">
                                                            <v-icon size="15">mdi-download</v-icon> Download
                                                        </v-btn>
                                                    </div>
                                                </div>

                                                <!-- Dialog to display full-size image in iframe -->
                                                <v-dialog v-model="dialogpopp" width="90%" height="70%">
                                                    <v-card elevation="0">
                                                        <div class="d-flex">
                                                            <span></span>
                                                            <v-btn class="ml-auto mr-3 " @click="dialogpopp = false"
                                                                icon><v-icon>mdi-close</v-icon></v-btn>
                                                        </div>
                                                        <v-card-text>
                                                            <iframe :src="dialogImage"
                                                                style="width: 100%; height: 500px;"
                                                                frameborder="0"></iframe>
                                                        </v-card-text>

                                                    </v-card>
                                                </v-dialog>


                                                <v-divider class="mt-3 ml-5" width="400px"></v-divider>
                                                <p class="caption grey--text mb-0 mt-4 font-weight-medium px-5"
                                                    v-if="ticket.sender_id !== 'ADMIN'">We have sent a confirmation
                                                    email to the address you provided. Our team is working on your query
                                                    and will get back to you within 24 hours.</p>

                                                <!-- <p class="caption font-weight-medium mb-0 mt-4 px-5"
                                                    v-if="ticket.sender_id !== 'ADMIN'">Regarding your query, our team
                                                    is already working on it. You will get a response from us within the
                                                    next 24 hours.
                                                </p> -->

                                                <p class="caption mb-0 mt-3 grey--text px-5">Best regards,</p>
                                                <!-- <p class="caption mb-0 px-5">  </p> -->
                                                <p class="caption mb-0 grey--text px-5">Zebu</p>
                                                <v-divider class="mt-3"></v-divider>


                                            </v-list>


                                        </v-card>
                                        <v-divider></v-divider>
                                        <v-footer class="pa-0 mt-2" v-bind="localAttrs" :padless="padless">
                                            <v-card width="100%" elevation="0">

                                                <v-text-field v-if="particuladata.ticket_status
                                                    != 'close'" dense outlined elevation="0" hide-details
                                                    placeholder="Write your Message" v-model="messagetext">
                                                    <template v-slot:append>
                                                        <v-file-input class="pa-0 mt-0" v-model="files" hide-details :hide-input="files == '' ? true : false"
                                                            truncate-length="15"></v-file-input>
                                                        <v-btn :disabled="messagetext == ''" color="black" :loading="raiseloader"
                                                            class="ml-4 text-none" text icon elevation="0"
                                                            @click="messagesendfun(particuladata)">
                                                            <v-icon>mdi-send</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-text-field>
                                                <v-btn v-if="particuladata.ticket_status
                                                    != 'close'" block @click="dialog = true"
                                                    class="mt-2 rounded-lg text-none #F23645--text" elevation="0" dense
                                                    color="#FFCDCD"> {{ particuladata.ticket_status
                                                        == 'close' ? ' Ticket is closed ' : 'Close Ticket' }}</v-btn>
                                                <v-btn v-if="particuladata.ticket_status
                                                    == 'close'" block class="mt-2 rounded-lg  text-none #F23645--text"
                                                    elevation="0" dense color="#FFCDCD"> {{ particuladata.ticket_status
                                                        == 'close' ? 'Ticket is closed ' : 'Close Ticket' }} By {{
                                                        ticketstatus == 'user' ? clientCode : 'Admin' }}</v-btn>
                                            </v-card>
                                        </v-footer>

                                    </v-card>
                                    <v-card elevation="0" v-if="selectparttick == ''" class="text-center pa-5 py-15">
                                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M90.0699 49.7871C89.5137 48.9418 88.6027 48.4371 87.6356 48.4371H84.3371C83.5137 28.1246 67.0277 12.498 46.8746 12.498C26.1965 12.498 9.37305 29.3215 9.37305 49.9996C9.37305 70.6777 26.1965 87.5012 46.8746 87.5012C56.6668 87.5012 65.9309 83.7512 72.9621 76.9402L70.7887 74.6934C64.3434 80.9371 55.8496 84.3762 46.8746 84.3762C27.9199 84.3762 12.498 68.9543 12.498 49.9996C12.498 31.0449 27.9199 15.623 46.8746 15.623C65.3059 15.623 80.3902 31.2496 81.2105 48.4371H77.9902C77.023 48.4371 76.1121 48.9418 75.5543 49.7871C74.934 50.7324 74.8262 51.9699 75.2777 53.0199L78.8215 61.2746C79.559 62.9934 81.0871 64.0605 82.8121 64.0605C84.5371 64.0605 86.0652 62.9918 86.8027 61.2746L90.3465 53.0199C90.798 51.9715 90.6918 50.7324 90.0699 49.7871ZM87.4746 51.7887L83.9309 60.0434C83.4418 61.1793 82.1824 61.1793 81.6934 60.0434L78.1496 51.7887C78.1137 51.7012 78.1246 51.6168 78.1402 51.5621H87.4855C87.5027 51.6168 87.5121 51.7012 87.4746 51.7887Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.6625 38.5064C47.2172 38.8721 47.875 39.0596 48.5359 39.0596C48.9984 39.0596 49.4625 38.9689 49.8937 38.783L58.15 35.2392C59.8703 34.5033 60.9375 32.9736 60.9375 31.2502C60.9375 29.5267 59.8687 27.9971 58.1516 27.2596L49.8953 23.7158C48.8469 23.2658 47.6078 23.3705 46.6641 23.9924C45.8172 24.5486 45.3125 25.458 45.3125 26.4267V29.7674C34.375 30.5689 26.5625 39.3267 26.5625 50.0002C26.5625 61.2002 35.675 70.3127 46.875 70.3127C58.075 70.3127 67.1875 60.9377 67.1875 50.0002H64.0625C64.0625 59.3752 56.3516 67.1877 46.875 67.1877C37.3984 67.1877 29.6875 59.4767 29.6875 50.0002C29.6875 41.0502 35.9375 33.6846 45.3125 32.8908V36.0721C45.3125 37.0408 45.8172 37.9502 46.6625 38.5064ZM48.4375 26.5768C48.4375 26.558 48.5734 26.5502 48.6625 26.5877L56.9188 30.1314C57.4859 30.3752 57.8125 30.783 57.8125 31.2502C57.8125 31.7174 57.4859 32.1252 56.9188 32.3689L48.6625 35.9127C48.5734 35.9486 48.4375 35.9408 48.4375 35.9221V26.5768Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.875 43.75C45.2174 43.75 43.6277 44.4085 42.4556 45.5806C41.2835 46.7527 40.625 48.3424 40.625 50C40.625 51.6576 41.2835 53.2473 42.4556 54.4194C43.6277 55.5915 45.2174 56.25 46.875 56.25C48.5326 56.25 50.1223 55.5915 51.2944 54.4194C52.4665 53.2473 53.125 51.6576 53.125 50C53.125 48.3424 52.4665 46.7527 51.2944 45.5806C50.1223 44.4085 48.5326 43.75 46.875 43.75Z"
                                                fill="#CCCCCC" />
                                        </svg>
                                        <p>No Tickets  Available</p>
                                    </v-card>
                                </div>


                            </v-col>
                        </v-row>
                    </div>

                </div>
                <div class="px-sm-4 py-4" v-if="newtickt">
                    <v-row>

                        <v-col cols="12">
                            <v-card outlined class=" pa-3" elevation="0" style="height: 100%;">

                                <v-card elevation="0" style="height: 70vh;overflow: scroll">
                                    <v-toolbar dense class="elevation-0   pr-4">
                                        <v-toolbar-title>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold title">Customer
                                                        Support
                                                        Request</v-list-item-title>


                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>

                                    </v-toolbar>

                                    <v-divider></v-divider>

                                    <div class="mt-6 px-4 d-flex">
                                        <p><v-icon class="mr-2">mdi-email-outline</v-icon> {{ clientEmail }}</p>

                                        <v-file-input v-model="files" placeholder="Upload your documents"
                                            label="Add Screenshot" multiple prepend-inner-icon="mdi-paperclip"
                                            prepend-icon="" class="ml-auto ml-3" style="max-width: 200px;" hide-details>
                                        </v-file-input>

                                    </div>

                                    <div class="d-flex mt-8 px-4">
                                        <v-select v-model="newlist" label="Issue" dense hide-details
                                            :items="subjectlist" style="max-width: 300px;"></v-select>
                                        <v-text-field style="max-width: 300px;" label="What is your Issue" class="ml-4"
                                            hide-details dense v-if="newlist == 'Others'"
                                            v-model="otherfieldsub"></v-text-field>
                                    </div>
                                    <v-textarea outlined v-model="querymessage" name="input-7-4"
                                        label="Describe your query in detail" class="px-4 mt-5"></v-textarea>

                                    <v-btn :disabled="querymessage == '' || subjectlist == ''" color="primary" class="text-none ml-4 px-4" @click="raiseticketfun()"
                                        :loading="raiseloader" elevation="0">Send</v-btn>

                                </v-card>

                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <v-dialog persistent v-model="dialog" width="350">

                    <v-card>
                        <v-card-title class="text-h5 ">
                            Close Ticket
                        </v-card-title>

                        <v-card-text>
                            Are you sure you want to close this ticket? </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>

                            <v-spacer></v-spacer>
                            <v-btn class="text-none" color="black" text @click="dialog = false">
                                No
                            </v-btn>
                            <v-btn class="text-none" :loading="closeload" color="black" text
                                @click="closeticketfun(particuladata)">
                                Yes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>

        </v-container>
    </v-app>
</template>

<script>
import { ticketurl } from "../apiUrl";

export default {
    data() {
        return {
            sendmessload: false,
            snackbar: false,
            padless: false,
            snackbarclr: "#000",
            variant: 'default',

            messagetext: "",

            errMsg: "",
            clientCode: "",
            clientEmail: "",
            oldticket: true,
            newtickt: false,
            querymessage: "",
            files: [],
            raiseloader: false,
            selectedItem: 0,
            showlist: [],
            showlistload: true,
            selectlist: false,
            selectparttick: [],
            baseUrl: "https://zebu-pd.web.app/",
            particuladata: [],
            dialog: false,
            closeload: false,
            ticketstatus: "",
            subjectlist: [
                'Account Opening',
                'General query',
                'Back Office',
                'KYC',
                'Charges',
                'Software',
                'Others'
            ],
            newlist: '',
            otherfieldsub: '',
            dialogpopp: false,
            dialogImage: '',
            searchQuery: "",
            filterQuery: '',
        };
    },

    methods: {
        fullUrl(path) {
            return `https://pdbe.mynt.in/${path}`; // Full URL of the file
        },
        getFilename(path) {
            return path.split('/').pop();  // Extract filename from path
        },
        openDialog(imageUrl) {
            this.dialogImage = imageUrl;
            this.dialogpopp = true;  // Open the dialog
        },
        raisetic() {
            this.oldticket = false
            this.newtickt = true
        },
        ticketlistfun() {
            this.newtickt = false
            this.oldticket = true
        },
        raiseticketfun() {
            this.raiseloader = true
            const axios = require('axios');
            const FormData = require('form-data');

            let data = new FormData();
            data.append('sender_id', this.clientCode);
            data.append('message', this.querymessage);
            data.append('replied', 'no');
            data.append('ticket_id', '');
            data.append('reciver_id', 'ADMIN');
            data.append('reply_ticket_id', '');
            data.append('subject', this.newlist == 'Others' ? this.newlist + " - " + this.querymessage : this.newlist);


            // data.append('files', this.files);
            Array.from(this.files).forEach(file => {
                data.append('files', file);
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/raiseticket`,
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.raiseloader = false
                    //   console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = "Ticket Raised"
                        axiosThis.snackbarclr = "green"
                        axiosThis.querymessage = ""
                        axiosThis.files = {}
                        setTimeout(() => {
                            axiosThis.ticketlistfun()
                            axiosThis.showticketlist()
                        }, 300);

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.raiseloader = false
                    axiosThis.networkerrfun()

                });

        },

        showticketlist() {
            this.showlist = []
            const axios = require('axios');
            let data = JSON.stringify({
                "pd_id": this.clientCode
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/show_client_outlook`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.showlistload = false
                    // console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok") {
                        axiosThis.showlist = response.data.data
                        if (axiosThis.showlist.length > 0) {
                            axiosThis.selectticket(axiosThis.showlist[0]);
                        }

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.showlistload = false
                    axiosThis.networkerrfun()
                });

        },
        ticketstaus(show, particuladata) {
            // this.selectparttick = []
            if (show.ticket_status == 'open') {
                this.ticketstatus = ''

                this.particuladata = show
                this.selectlist = true
                const axios = require('axios');
                let data = JSON.stringify({

                    "ticket_id": show != '' ? show.ticket_id : particuladata,
                    "log": this.clientCode
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${ticketurl}/update_message_status`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                var axiosThis = this
                axios.request(config)
                    .then((response) => {
                        //   console.log(JSON.stringify(response.data));
                        axiosThis.selectlist = false
                        if (response.data.stat === "Ok") {
                            console.log('.');
                        }
                        else if (response.data.error == "ticket is already closed") {
                            axiosThis.showticketlist()
                        }
                        else {
                            axiosThis.snackbar = true
                            axiosThis.errMsg = response.data
                            axiosThis.snaclr = "error"
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        this.selectlist = false
                        this.networkerrfun()
                    });
            }
        },

        selectticket(show, particuladata) {
            this.ticketstatus = ''

            // this.selectparttick = []
            this.particuladata = show
            this.selectlist = true
            const axios = require('axios');
            let data = JSON.stringify({
                "pd_id": this.clientCode,
                "ticket_id": show != '' ? show.ticket_id : particuladata
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/show_client_par_ticket`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    //   console.log(JSON.stringify(response.data));
                    axiosThis.selectlist = false
                    if (response.data.stat === "Ok") {
                        axiosThis.ticketstatus = response.data.closed_by;
                        axiosThis.selectparttick = response.data.data
                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.selectlist = false
                    this.networkerrfun()
                });

        },

        closeticketfun(particuladata) {
            this.closeload = true
            const axios = require('axios');
            let data = JSON.stringify({
                "ticket_id": particuladata.ticket_id,
                "log": "user"
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/ticket_status_updated`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    axiosThis.closeload = false
                    axiosThis.dialog = false
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.msg
                        axiosThis.snackbarclr = "green"
                        axiosThis.querymessage = ""
                        axiosThis.files = {}
                        setTimeout(() => {
                            // axiosThis.ticketlistfun()
                            axiosThis.showticketlist()
                            axiosThis.selectticket(particuladata)
                        }, 500);

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.closeload = false
                    axiosThis.dialog = false
                    axiosThis.networkerrfun()
                });


        },

        networkerrfun() {
            this.snackbar = true
            this.errMsg = "Axios:Network Error"
            this.snackbarclr = "error"
        },


        messagesendfun(particuladata) {
            this.raiseloader = true
            const axios = require('axios');
            const FormData = require('form-data');

            let data = new FormData();
            data.append('sender_id', this.clientCode);
            data.append('message', this.messagetext);
            data.append('replied', 'yes');
            // data.append('ticket_id', particuladata.ticket_id);
            data.append('reciver_id', 'ADMIN');
            data.append('reply_ticket_id', particuladata.ticket_id);
            data.append('subject', particuladata.subject);


            data.append('files', this.files);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/raiseticket`,
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.raiseloader = false
                    //   console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok" || response.data.msg == 'sent successfully') {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.msg
                        axiosThis.snackbarclr = "green"
                        axiosThis.messagetext = ""
                        axiosThis.files = {}
                        setTimeout(() => {
                            axiosThis.selectticket(particuladata)
                            axiosThis.ticketstaus(particuladata)
                            // axiosThis.showticketlist()
                        }, 500);

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snackbarclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.raiseloader = false
                    axiosThis.networkerrfun()

                });

        },
        customFilter(item, queryText) {
            return item.toLowerCase().includes(queryText.toLowerCase());
        },

    },
    computed: {


        localAttrs() {
            const attrs = {}

            if (this.variant === 'default') {
                attrs.absolute = false
                attrs.fixed = false
            } else {
                attrs[this.variant] = true
            }
            return attrs
        },
        autocompleteItems() {
            const subjects = this.showlist.map(ticket => ticket.subject).filter(Boolean);
            const statuses = this.showlist.map(ticket => ticket.ticket_status).filter(Boolean);
            return [...new Set([...subjects, ...statuses])];  // Combine and remove duplicates
        },
        // Filters the showlist based on the search query (typing or selecting)
        filteredShowlist() {
            const query = this.searchQuery ? this.searchQuery.toLowerCase() : '';  // Make search case-insensitive

            return this.showlist.filter(ticket =>
                (ticket.subject && ticket.subject.toLowerCase().includes(query)) ||  // Search in subject
                (ticket.ticket_status && ticket.ticket_status.toLowerCase().includes(query)) ||  // Search in ticket_status
                (ticket.ticket_id && ticket.ticket_id.toString().toLowerCase().includes(query))  // Ensure ticket_id is string and search
            );
        }


    },
    mounted() {
        this.clientCode = localStorage.getItem("clientid")
        this.clientEmail = localStorage.getItem("email")
        this.showticketlist()
    }



};
</script>

<style>
/* .headline { */
/* font-family: 'DM Sans' !important; */
/* font-weight: 500; */
/* } */
.trdditl::-webkit-scrollbar {
    width: 6px;
}

.trdditl::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.trdditl::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.6);
    border-radius: 16px;
}

.toolbar .v-toolbar__content {
    padding: 0 !important;
}

.datedetailshead .v-data-table-header__sort-badge {
    display: none !important;
}

.v-menu__content.theme--light.menuable__content__active {
    overflow-y: hidden !important;
}
</style>
<style lang="scss">
.colums {
    position: static;
    float: left;
}
</style>