<template>
  <v-app>
    <div class="">
      <v-snackbar style="z-index: 2 !important;" transition="slide-x-reverse-transition" v-model="snackbar"
        timeout="2000" :value="true" :color="snakclr" absolute outlined top right>
        <v-icon :color="snakclr">mdi-alert-outline</v-icon>
        {{ errMsg }}
      </v-snackbar>
    </div>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="px-sm-4">
        <v-toolbar dense class="elevation-0 mb-4 mt-6">
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold headline">User profile</v-list-item-title>
                <v-list-item-subtitle class="body-1 mb-0 mt-1" color="#878B93">
                  your personal details here.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="pt-2 px-4">
          <v-row class="d-flex flex-column-reverse flex-md-row">
            <v-col class="" cols="12" md="7">
              <p class="mb-2">Personal</p>
              <v-card outlined>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>PAN</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.PAN_NO }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Email ID</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.EMAIL_ID }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Mobile number</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.MobileNo }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <!-- <p class="mb-2">Personal info</p>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <p class="mb-2">Name</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.REMESHIRE_NAME"
                    label="Your display name" single-line outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <p class="mb-2">Remeshire code</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.REMESHIRE_CODE"
                    label="Your display name" single-line outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">Email ID</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.EMAIL_ID" label="Your email"
                    single-line outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">Agreement date</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.Agreement_date"
                    label="Your Agreement date" single-line outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">PAN</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.PAN_NO" label="Your PAN" single-line
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">Mobile Number</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.MobileNo" type="number"
                    label="Your Mobile Number" single-line outlined>
                  </v-text-field>
                </v-col>
              </v-row> -->
              <p class="mb-2 mt-6">Address</p>
              <v-card wi class="elevation-0 rounded-md pa-2" outlined><span v-if="UserDetails.STATE">{{
                UserDetails.Address }}, {{ UserDetails.address2 }}, {{ UserDetails.CITY }}, {{ UserDetails.STATE
  }}</span><span v-else>Your address</span></v-card>
              <p class="mb-2 mt-6">Bank</p>
              <v-card outlined>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Bank name</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.REM_BANK_NAME }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Account.No</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.RemBankAcBNo }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>IFSC CODE</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ UserDetails.REM_IFSC_CODE }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

              <!-- <v-row>
                      <v-col cols="6">
                    <p class="mb-2">Bank Name</p>
                    <v-text-field dense :readonly="userdataview" v-model=""
                      label="Your Bank name" single-line outlined>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <p class="mb-2">Account.No</p>
                    <v-text-field dense :readonly="userdataview" v-model=""
                      label="Your Account.No" single-line outlined>
                    </v-text-field>
                  </v-col>
                </v-row>
                <p class="mb-2">IFSC CODE</p>
                <v-text-field dense :readonly="userdataview" v-model="" label="Your IFSC CODE"
                  single-line outlined>
                </v-text-field> -->

              <!-- <v-divider class="d-none d-md-flex"></v-divider>
                <h3 class="mt-4 mb-2">Company codes</h3>
                <v-list-item class="pl-0" v-for="(n, i) in UserDetails.company_codes" :key="n">
                  <v-list-item-content>
                    <v-list-item-title>{{ i+1 }}. {{ n }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

              <!-- <v-row>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">City</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.CITY" label="Your City" single-line
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">State</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.STATE" label="Your state" single-line
                    outlined>
                  </v-text-field>
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">SEBI REGS NO</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.SEBI_REGS_NO"
                    label="Your SEBI REGS NO" single-line outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <p class="mb-2">SEBI ISSUE DATE</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.SEBI_ISSUE_DATE"
                    label="Your SEBI ISSUE DATE" single-line outlined>
                  </v-text-field>
                </v-col>
              </v-row> -->
            </v-col>
            <v-col cols="12" md="5" class="text-center mb-6 mt-8 mt-md-16">

              <v-avatar size="160" color="#3B82F6">
                <span style="font-size:88px;" class="white--text font-weight-bold text-uppercase">{{
                  UserDetails.REMESHIRE_CODE.slice(0, 1)
                }}</span>
              </v-avatar>
              <p class="font-weight-bold mb-0 mt-2">{{ UserDetails.REMESHIRE_NAME }}</p>
              <p class="subtitle-1 mb-0">{{ UserDetails.REMESHIRE_CODE }}</p>
              <p class="mb-1">since : {{ UserDetails.Agreement_date.slice(0, 10) }}</p>
              <p @click="copylink()" class="body-2">
                <span style="background-color:#EFF6FF;" class="py-1 px-2 rounded-lg">
                  https://oa.mynt.in/?ref={{
                    UserDetails.REMESHIRE_CODE }}<v-icon size="14">mdi-content-copy</v-icon>
                </span>
              </p>
            </v-col>
            <!-- <v-divider class="d-none d-md-flex" vertical></v-divider> -->
            <!-- <v-col class="pl-md-6" cols="12" md="5">
              <h3 class="mb-4">Bank info</h3>
              <v-row>
                <v-col cols="6">
                  <p class="mb-2">Bank Name</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.REM_BANK_NAME" label="Your Bank name"
                    single-line outlined>
                  </v-text-field>
                </v-col>
                <v-col>
                  <p class="mb-2">Account.No</p>
                  <v-text-field dense :readonly="userdataview" v-model="UserDetails.RemBankAcBNo" label="Your Account.No"
                    single-line outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <p class="mb-2">IFSC CODE</p>
              <v-text-field dense :readonly="userdataview" v-model="UserDetails.REM_IFSC_CODE" label="Your IFSC CODE"
                single-line outlined>
              </v-text-field>

              <v-divider class="d-none d-md-flex"></v-divider>
              <h3 class="mt-4 mb-2">Company codes</h3>
              <v-list-item class="pl-0" v-for="(n, i) in UserDetails.company_codes" :key="n">
                <v-list-item-content>
                  <v-list-item-title>{{ i+1 }}. {{ n }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col> -->
          </v-row>
        </div>
      </div>
    </v-container>

  </v-app>
</template>
  
<script>
import { apiurl } from "../apiUrl";
//   import FormData from "form-data";
// import fs from fs-extra
import axios from "axios";

export default {
  data() {
    return {
      imageUrll: '',
      previewImagee: '',
      addimageprofile: true,
      preview: null,
      image: null,
      snackbar: false,
      snakclr: 'default',
      signinemailname: "",
      imageUrl: '',
      date: null,
      name: "",
      email: "",
      dobmenu: "",
      mobile: "",
      userdataview: true,
      getUserDetails: [],
      UserDetails: [],
      switch1: false,
      bankInfo: false
    };
  },

  mounted() {
    this.profileValidation()
  },
  methods: {
    profileValidation() {
      var clientCode = localStorage.getItem('clientid')
      var auth = localStorage.getItem('session')
      // if(clientCode) {
      var axiosThis = this
      var data = JSON.stringify({
        "code": clientCode
      });

      var config = {
        method: 'post',
        // url: 'http://192.168.5.163:5511/user_details',
        url: `${apiurl}/user_details`,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": auth
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log("user_details",response.data);
          if (response.data.msg != "Token is Expired." || response.data.msg != "Token is Invalid.") {
            let userdata = response.data[0]
            userdata['company_codes'] = []
            // userdata['sebireg'] = []
            // userdata['sebino'] = []
            for (let u = 0; u < response.data.length; u++) {
              userdata.company_codes.push(response.data[u].COMPANY_CODE);
              // userdata.sebireg.push(response.data[u].SEBI_REGS_NO);
              // userdata.sebino.push(response.data[u].SEBI_ISSUE_DATE);
            }
            axiosThis.UserDetails = userdata;
            console.log("user_details", axiosThis.UserDetails);
          } else {
            axiosThis.errMsg = response.data.msg;
            axiosThis.snackbar = true;
            axiosThis.snakclr = 'warning'
            setTimeout(() => {
              axiosThis.$router.push("/")
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      //   }
      navigator.clipboard.writeText(this.text1);
    },
    copylink() {
      navigator.clipboard.writeText(`https://oa.mynt.in/?ref=${this.UserDetails.REMESHIRE_CODE}`);
      this.errMsg = 'Link as copied ';
      this.snackbar = true;
      this.snakclr = 'success'
    }
  },
  computed: {
  }
};
</script>
<style lang="scss">
.file-input {
  display: none
}

.profile-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
</style>