<template>
    <div>
        <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important" transition="slide-x-reverse-transition"
            v-model="snackbar" timeout="2000" :value="true" :color="snaclr" absolute outlined top right>
            <v-icon class="mr-2" :color="snaclr">mdi-alert-outline</v-icon>
            {{ errMsg }}
        </v-snackbar>
        <v-snackbar v-model="snackbarfie" color="error">
            <p> File size exceeds 4MB, please upload a smaller file.</p>
        </v-snackbar>
        <v-app-bar :dark="$vuetify.theme.dark"
            :style="{ 'border-bottom': $vuetify.theme.dark ? '1px soild white' : '1px solid #e0e0e0', backgroundColor: $vuetify.theme.dark ? '#121212' : 'white' }"
            elevation="0" app height="50px" fixed class="pa-0">
            <div>
                <a>
                    <v-img src="https://zebu.mynt.in/static/logo/mainlogo.svg" class="textfnt mt-2 mb-4"
                        width="80px"></v-img>
                </a>
            </div>
            <v-spacer></v-spacer>



            <v-menu close-on-click offset-y class="table-menu">
                <!--  -->
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab
                        :style="{ backgroundColor: $vuetify.theme.dark ? '#121212' : '#f5f5f5' }" small
                        class="mx-1 elevation-0">
                        <span class="title font-weight-bold">
                            {{
                                first ? first.slice(0, 1) : "-"
                            }}
                        </span>
                    </v-btn>
                </template>
                <v-card class="table-menu-list">
                    <v-list>
                        <v-list-item class="mb-2">

                            <v-list-item-content>
                                <v-list-item-title class="fs-14 font-weight-bold mb-1">{{ firstt ? firstt : "-"
                                    }}</v-list-item-title>
                                <v-list-item-subtitle class="caption txt-666">{{ first ? first : ''
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list-item @click="logOut()">
                        <v-list-item-icon class="mr-3 text-center">
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="txt-454 font-weight-medium fs-14">Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-menu>



        </v-app-bar>
        <div class="ml-14 mt-15 mr-14">
            <v-tabs>

                <v-tab class="text-none">
                    <v-badge :content="fullfeedata.length > 0 ? fullfeedata.length : '0'"
                        :value="fullfeedata.length > 0 ? fullfeedata.length : '0'" color="primary">Feeds
                    </v-badge></v-tab>

                <v-tab class="text-none">
                    <v-badge :content="fulluploaddataa.length > 0 ? fulluploaddataa.length : '0'"
                        :value="fulluploaddataa.length > 0 ? fulluploaddataa.length : '0'" color="primary">Collateral
                    </v-badge>
                </v-tab>

                <v-tab class="text-none">
                    <v-badge :content="showlist.length > 0 ? showlist.length : '0'"
                        :value="showlist.length > 0 ? showlist.length : '0'" color="primary">Support
                    </v-badge></v-tab>
                <v-tab class="text-none">
                    <v-badge :content="websitedat.length > 0 ? websitedat.length : '0'"
                        :value="websitedat.length > 0 ? websitedat.length : '0'" color="primary"> Landing page - Data
                    </v-badge>
                </v-tab>


                <v-tab-item>
                    <v-card elevation="0" outlined class=" pa-4">
                        <v-toolbar dense class="elevation-0  ">
                            <v-toolbar-title>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold headline">Feeds
                                            Admin</v-list-item-title>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>

                            <v-btn @click="dialog = true" color="#EFF6FF" elevation="0" class="text-none rounded-lg"
                                style="color:#3B82F6;border:1px solid #3B82F6">
                                <v-icon color="#3B82F6" class="mr-1">mdi-folder-plus-outline</v-icon> Create
                                Posts</v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>

                        <v-data-table :loading="feedresloader" disable-sort :headers="headers" :items="fullfeedata"
                            class="elevation-0 mt-4" outlined>
                            <template v-slot:[`item.flietype`]="{ item }">
                                {{ Object.keys(item.images).length > 0 ? "Images" : item.videos ? "Videos" : "" }}


                            </template>
                            <template v-slot:[`item.viewcommet`]="{ item }">
                                <v-btn @click="commnet(item)" text small class="text-none" color="primary">View
                                    Comment</v-btn>


                            </template>
                            <template v-slot:[`item.deletefeed`]="{ item }">
                                <v-btn @click="deletefun(item)" icon small class="text-none" color="black">
                                    <v-icon>mdi-delete</v-icon></v-btn>


                            </template>
                            <template v-slot:[`item.liked_counts`]="{ item }">
                                {{ item.liked_counts == "" ? "0" : item.liked_counts }}
                            </template>
                        </v-data-table>


                        <!-- <p>Editor Content:</p>
    <div v-html="content"></div> -->
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card elevation="0" outlined class=" pa-4">
                        <v-toolbar dense class="elevation-0  ">
                            <v-toolbar-title>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="font-weight-bold headline">Collateral</v-list-item-title>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>

                            <v-btn @click="fileuploadpop = true" color="#EFF6FF" elevation="0"
                                class="text-none rounded-lg" style="color:#3B82F6;border:1px solid #3B82F6">
                                <v-icon color="#3B82F6" class="mr-1">mdi-folder-plus-outline</v-icon>Upload File</v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-data-table disable-sort :headers="headersuplo" :items="fulluploaddataa"
                            class="elevation-0 mt-4" outlined>

                            <!-- <template v-slot:[`item.viewpage`]="{ item }">
                                <v-btn @click="openPopup(item.files)" text small class="text-none" color="primary">View
                                    file</v-btn>


                            </template> -->
                            <template v-slot:[`item.viewpage`]="{ item }">
                                <v-btn @click="openFilesInNewTab(Object.values(item.files))" target="_blank" text small
                                    class="text-none" color="primary">View
                                    file</v-btn>


                            </template>
                            <template v-slot:[`item.deletefeed`]="{ item }">
                                <v-btn @click="deletefileconn(item)" icon small class="text-none" color="black">
                                    <v-icon>mdi-delete</v-icon></v-btn>


                            </template>
                        </v-data-table>

                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card elevation="0" outlined class=" pa-4" style="height:100vh">
                        <v-toolbar dense class="elevation-0  ">
                            <v-toolbar-title>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold headline">Tickets List
                                        </v-list-item-title>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div> <v-autocomplete v-model="filterQuery" :items="autocompleteItems" dense hide-details
                                    outlined placeholder="All" style="width:200px;" class="mr-3 mb-1">
                                </v-autocomplete>
                            </div>
                            <v-btn @click="showticketlist()" icon><v-icon>mdi-refresh</v-icon></v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="4">

                                <v-text-field clearable prepend-inner-icon="mdi-magnify" v-model="searchQuery" dense
                                    hide-details outlined placeholder="search" class=""></v-text-field>


                                <v-card :loading="showlistload" elevation="0" class="mt-3" outlined tile>
                                    <v-list class="pa-0">
                                        <v-list-item-group v-model="selectedItem" color="primary">
                                            <v-list-item :disabled="selectlist"
                                                @click="selectticket(show), ticketstaus(show)"
                                                v-for="show in filteredShowlist" :key="show.ticket_id">

                                                <v-list-item-content>
                                                    <v-list-item-title class="body-2 "><span class="font-weight-bold">{{
                                                        show.pd_id }}</span> - {{
                                                                show.ticket_id
                                                            }}</v-list-item-title>
                                                    <v-list-item-subtitle class="caption">{{ show.request_date_time
                                                        }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-chip v-if="show.ticket_status == 'open'" small color="green"
                                                        class="white--text">open</v-chip>
                                                    <v-chip v-if="show.ticket_status == 'close'" small color="red"
                                                        class="white--text">Close</v-chip>
                                                    <!-- <v-list-item-action-text>{{ show.ticket_id }}</v-list-item-action-text> -->

                                                </v-list-item-action>

                                            </v-list-item>
                                            <v-divider></v-divider>
                                        </v-list-item-group>
                                    </v-list>

                                    <v-card elevation="0" v-if="filteredShowlist == ''" class="text-center pa-5 py-15">
                                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M90.0699 49.7871C89.5137 48.9418 88.6027 48.4371 87.6356 48.4371H84.3371C83.5137 28.1246 67.0277 12.498 46.8746 12.498C26.1965 12.498 9.37305 29.3215 9.37305 49.9996C9.37305 70.6777 26.1965 87.5012 46.8746 87.5012C56.6668 87.5012 65.9309 83.7512 72.9621 76.9402L70.7887 74.6934C64.3434 80.9371 55.8496 84.3762 46.8746 84.3762C27.9199 84.3762 12.498 68.9543 12.498 49.9996C12.498 31.0449 27.9199 15.623 46.8746 15.623C65.3059 15.623 80.3902 31.2496 81.2105 48.4371H77.9902C77.023 48.4371 76.1121 48.9418 75.5543 49.7871C74.934 50.7324 74.8262 51.9699 75.2777 53.0199L78.8215 61.2746C79.559 62.9934 81.0871 64.0605 82.8121 64.0605C84.5371 64.0605 86.0652 62.9918 86.8027 61.2746L90.3465 53.0199C90.798 51.9715 90.6918 50.7324 90.0699 49.7871ZM87.4746 51.7887L83.9309 60.0434C83.4418 61.1793 82.1824 61.1793 81.6934 60.0434L78.1496 51.7887C78.1137 51.7012 78.1246 51.6168 78.1402 51.5621H87.4855C87.5027 51.6168 87.5121 51.7012 87.4746 51.7887Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.6625 38.5064C47.2172 38.8721 47.875 39.0596 48.5359 39.0596C48.9984 39.0596 49.4625 38.9689 49.8937 38.783L58.15 35.2392C59.8703 34.5033 60.9375 32.9736 60.9375 31.2502C60.9375 29.5267 59.8687 27.9971 58.1516 27.2596L49.8953 23.7158C48.8469 23.2658 47.6078 23.3705 46.6641 23.9924C45.8172 24.5486 45.3125 25.458 45.3125 26.4267V29.7674C34.375 30.5689 26.5625 39.3267 26.5625 50.0002C26.5625 61.2002 35.675 70.3127 46.875 70.3127C58.075 70.3127 67.1875 60.9377 67.1875 50.0002H64.0625C64.0625 59.3752 56.3516 67.1877 46.875 67.1877C37.3984 67.1877 29.6875 59.4767 29.6875 50.0002C29.6875 41.0502 35.9375 33.6846 45.3125 32.8908V36.0721C45.3125 37.0408 45.8172 37.9502 46.6625 38.5064ZM48.4375 26.5768C48.4375 26.558 48.5734 26.5502 48.6625 26.5877L56.9188 30.1314C57.4859 30.3752 57.8125 30.783 57.8125 31.2502C57.8125 31.7174 57.4859 32.1252 56.9188 32.3689L48.6625 35.9127C48.5734 35.9486 48.4375 35.9408 48.4375 35.9221V26.5768Z"
                                                fill="#CCCCCC" />
                                            <path
                                                d="M46.875 43.75C45.2174 43.75 43.6277 44.4085 42.4556 45.5806C41.2835 46.7527 40.625 48.3424 40.625 50C40.625 51.6576 41.2835 53.2473 42.4556 54.4194C43.6277 55.5915 45.2174 56.25 46.875 56.25C48.5326 56.25 50.1223 55.5915 51.2944 54.4194C52.4665 53.2473 53.125 51.6576 53.125 50C53.125 48.3424 52.4665 46.7527 51.2944 45.5806C50.1223 44.4085 48.5326 43.75 46.875 43.75Z"
                                                fill="#CCCCCC" />
                                        </svg>
                                        <p>No Tickets  Available</p>
                                    </v-card>
                                </v-card>

                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="8">
                                <div v-if="selectparttick != ''">
                                    <v-card outlined class=" pa-3" elevation="0" style="height: 100%;">

                                        <v-card elevation="0" style="height: 70vh;overflow: scroll">
                                            <v-list subheader v-for="(ticket, index) in selectparttick" :key="index">


                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar size="38px" v-if="ticket.sender_id !== 'ADMIN'"
                                                            color="#3B82F6">
                                                            <span
                                                                class="white--text font-weight-bold title text-uppercase">
                                                                {{ ticket.sender_id.slice(0, 1) }}
                                                            </span>
                                                        </v-avatar>

                                                        <v-avatar v-else size="36px">
                                                            <img height="30px" width="47px" alt="Avatar"
                                                                src="https://zebuetrade.com/img/zebu.ee5ba741.svg">
                                                        </v-avatar>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold"
                                                            v-if="ticket.sender_id === 'ADMIN'">Zebu
                                                            Support</v-list-item-title>
                                                        <v-list-item-title v-else>{{ ticket.sender_id
                                                            }}</v-list-item-title>
                                                        <v-list-item-title v-if="ticket.sender_id !== 'ADMIN'">{{
                                                            ticket.subject
                                                            }}</v-list-item-title>


                                                    </v-list-item-content>

                                                    <v-list-item-action>
                                                        <v-list-item-action-text>{{ ticket.request_date_time
                                                            }}</v-list-item-action-text>
                                                        <v-list-item-action-text
                                                            v-if="ticket.sender_id !== 'ADMIN'">Ticket
                                                            ID: {{ ticket.ticket_id == "" ? ticket.reply_ticket_id :
                                                                ticket.ticket_id
                                                            }}</v-list-item-action-text>

                                                    </v-list-item-action>

                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <!-- <p class="title mt-3 font-weight-medium mb-2  px-5">{{ticket.subject}} </p> -->

                                                <p class="caption grey--text font-weight-medium mb-0 mt-3 px-5"
                                                    v-if="ticket.sender_id == 'ADMIN'">Dear Customer,</p>
                                                <p class="caption grey--text font-weight-medium mb-0 mt-3 px-5"
                                                    v-if="ticket.sender_id == 'ADMIN'">Greetings from Zebu!</p>


                                                <p class="subtitle-1  font-weight-medium mb-0 mt-4 px-5">{{
                                                    ticket.message
                                                }}</p>

                                                <p v-if="Object.keys(ticket.file_path).length"
                                                    class="body-2 font-weight-medium mb-1 mt-3 px-5">
                                                    {{ ticket.sender_id === 'ADMIN' ? 'Admin' : 'User' }} Screenshot
                                                </p>

                                                <div v-if="Object.keys(ticket.file_path).length"
                                                    class="d-flex flex-wrap">
                                                    <div v-for="(path, idx) in ticket.file_path" :key="idx"
                                                        class="ml-4">
                                                        <v-card style="width: 350px; height: auto;  cursor: pointer;"
                                                            elevation="0">
                                                            <div @click="openDialog(fullUrl(path))"
                                                                style="cursor: pointer; display: inline-block; width: 100%; height: 100%;">
                                                                <iframe :src="fullUrl(path)"
                                                                    style="width: 100%; height: 250px; object-fit: contain; pointer-events: none;"
                                                                    frameborder="0">
                                                                </iframe>
                                                            </div>
                                                        </v-card>


                                                        <v-btn elevation="0" dense x-small class="text-none ml-2"
                                                            :href="fullUrl(path)" :download="getFilename(path)"
                                                            target="_blank">
                                                            <v-icon size="15">mdi-download</v-icon> Download
                                                        </v-btn>
                                                    </div>
                                                </div>

                                                <!-- Dialog to display full-size image in iframe -->
                                                <v-dialog v-model="dialogpopp" width="90%" height="70%">
                                                    <v-card elevation="0">
                                                        <div class="d-flex">
                                                            <span></span>
                                                            <v-btn class="ml-auto mr-3 " @click="dialogpopp = false"
                                                                icon><v-icon>mdi-close</v-icon></v-btn>
                                                        </div>
                                                        <v-card-text>
                                                            <iframe :src="dialogImage"
                                                                style="width: 100%; height: 500px;"
                                                                frameborder="0"></iframe>
                                                        </v-card-text>

                                                    </v-card>
                                                </v-dialog>


                                                <v-divider class="mt-3 ml-5" width="400px"></v-divider>
                                                <p class="caption grey--text mb-0 mt-4 font-weight-medium px-5"
                                                    v-if="ticket.sender_id !== 'ADMIN'">We have sent a confirmation
                                                    email to the
                                                    address you provided. Our team is working on your query and will get
                                                    back to
                                                    you within 24 hours.</p>

                                                <!-- <p class="caption font-weight-medium mb-0 mt-4 px-5"
                                                    v-if="ticket.sender_id !== 'ADMIN'">Regarding your query, our team
                                                    is already working on it. You will get a response from us within the
                                                    next 24 hours.
                                                </p> -->

                                                <p class="caption mb-0 mt-3 grey--text px-5">Best regards,</p>
                                                <!-- <p class="caption mb-0 px-5">  </p> -->
                                                <p class="caption mb-0 grey--text px-5">Zebu</p>
                                                <v-divider class="mt-3"></v-divider>


                                            </v-list>


                                        </v-card>
                                        <v-divider></v-divider>
                                        <v-footer class="pa-0 mt-2" v-bind="localAttrs" :padless="padless">
                                            <v-card width="100%" elevation="0">

                                                <v-text-field v-if="particuladata.ticket_status
                                                    != 'close'" dense outlined elevation="0" hide-details
                                                    placeholder="Write your Message" v-model="messagetext">
                                                    <template v-slot:append>
                                                        <v-file-input class="pa-0 mt-0" v-model="files"
                                                            :hide-input="files == '' ? true : false"
                                                            truncate-length="15"></v-file-input>
                                                        <v-btn :disabled="messagetext == ''" color="black"
                                                            :loading="raiseloader" class="ml-4 text-none" text icon
                                                            elevation="0" @click="messagesendfun(particuladata)">
                                                            <v-icon>mdi-send</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-text-field>
                                                <v-btn v-if="particuladata.ticket_status
                                                    != 'close'" block @click="dialogclose = true"
                                                    class="mt-2 rounded-lg text-none #F23645--text" elevation="0" dense
                                                    color="#FFCDCD"> {{ particuladata.ticket_status
                                                        == 'close' ? ' Ticket is closed ' : 'Close Ticket' }}</v-btn>
                                                <v-btn v-if="particuladata.ticket_status
                                                    == 'close'" block class="mt-2 rounded-lg  text-none #F23645--text"
                                                    elevation="0" dense color="#FFCDCD"> {{ particuladata.ticket_status
                                                        == 'close' ? 'Ticket is closed ' : 'Close Ticket' }} By {{
                                                        ticketstatus == 'user' ? 'User' : 'Admin' }}</v-btn>
                                            </v-card>
                                        </v-footer>

                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card elevation="0" outlined class=" pa-4">
                        <v-toolbar dense class="elevation-0  ">
                            <v-toolbar-title>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold headline">Landing Page -
                                            Admin</v-list-item-title>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div>
                                <v-text-field label="search" prepend-inner-icon="mdi-magnify" v-model="searchlpdata"  class="mr-3" hide-details
                                    dense style="max-width:200px;"></v-text-field>
                            </div>
                            <v-btn @click="newdownl1()" icon> <v-icon>mdi-download</v-icon></v-btn>

                            <v-btn @click="websiteadmin()" icon> <v-icon>mdi-refresh</v-icon></v-btn>

                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-data-table items-per-page="100" height="80vh" disable-sort :search="searchlpdata" :headers="headersuplolp" :items="websitedat"
                            class="elevation-0 mt-4" outlined>

                            <template v-slot:[`item.email`]="{ item }">
                                <span>{{ item.email == 'Bookacall@gmail.com' ? 'Book a call' : item.email }}
                                </span>

                            </template>
                        </v-data-table>

                    </v-card>
                </v-tab-item>


            </v-tabs>

        </div>
        <v-dialog v-model="dialog" persistent max-width="790" class="pa-0 rounded-xl">
            <v-card elevation="0" outlined class="rounded-xl ">
                <v-toolbar color="#F7F7F7" dense class="elevation-0 pl-3 pr-3 pt-1 pa-0">
                    <v-toolbar-title>
                        <v-list-item class="pa-0">
                            <v-list-item-content class="pa-0">
                                <v-list-item-title class="font-weight-bold title pa-0">Create a
                                    Post</v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn @click="dialog = false" icon elevation="0">
                        <v-icon color="black" class="">mdi-close-circle-outline</v-icon> </v-btn>
                </v-toolbar>
                <v-divider class="mb-1 ml-2 mr-2"></v-divider>
                <!-- <v-card elevation="0" class="pa-0 mt-1 ml-3 mr-3">
                                <quill-editor class="mb-1 custom-editor" v-model="editorContent"
                                    :options="editorOptions" ref="myQuillEditor"></quill-editor>
                            </v-card> -->
                <div class="pa-4">
                    <span class="subtitle-2 ">Title</span>
                    <v-text-field outlined hide-details class="mt-2 mb-3" dense label="Title"
                        v-model="titlepost"></v-text-field>

                    <span class="subtitle-2 mt-6">Description</span>
                    <quill-editor class="mt-3 mb-3" v-model="content" :options="editorOptions"
                        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)" />
                    <!-- <v-textarea v-model="descritiondata" hide-details class="" outlined name="input-7-4"
                        label="Description"></v-textarea> -->

                    <span class="subtitle-2 mb-2 mt-4">File Type </span>
                    <v-chip-group v-model="selection" active-class="" class="mb-3" mandatory>
                        <v-chip small
                            :style="{ 'border': selection == 0 ? '1px solid #3B82F6' : '', color: selection == 0 ? '#3B82F6' : '' }">
                            Image
                        </v-chip>
                        <v-chip small
                            :style="{ 'border': selection == 1 ? '1px solid #3B82F6' : '', color: selection == 1 ? '#3B82F6' : '' }">
                            video
                        </v-chip>
                        <v-chip small
                            :style="{ 'border': selection == 2 ? '1px solid #3B82F6' : '', color: selection == 2 ? '#3B82F6' : '' }">
                            Youtube Video
                        </v-chip>
                    </v-chip-group>
                    <span class="subtitle-2 mt-2" v-if="selection == 0">Upload a Image </span>

                    <div class=" d-flex" v-if="selection == 0">
                        <p class="ml-6 mt-5  grey--text">Add : </p>
                        <v-file-input class="" label="Upload a file (max 4MB)" multiple v-model="imageuplo"
                            accept="image/*" @change="checkFileSize"></v-file-input>
                    </div>
                    <span class="subtitle-1 mt-2" v-if="selection == 1">Upload a video </span>

                    <div class=" d-flex" v-if="selection == 1">
                        <p class="ml-6 mt-5  grey--text">Add : </p>
                        <v-file-input class="" label="Upload a file (max 4MB)" v-model="videouplod" accept="video/*"
                            truncate-length="15" @change="checkFileSize"></v-file-input>
                    </div>
                    <span class="subtitle-1 mt-2" v-if="selection == 2">Youtube video Link</span>

                    <div class=" d-flex" v-if="selection == 2">
                        <v-text-field class="" outlined hide-details dense label="link"
                            v-model="youtubelink"></v-text-field>
                    </div>
                </div>
                <div class="ml-3 mb-2 ">
                    <v-row>
                        <v-col cols="6">
                            <v-btn block outlined rounded elevation="0" @click="dialog = false" class="text-none">
                                Cancel</v-btn>

                        </v-col>
                        <v-col cols="6">
                            <v-btn block rounded @click="uploadfeed()" :loading="feedloader" elevation="0"
                                :disabled="titlepost == '' || content == '' || (selection == 0 ? imageuplo == '' : selection == 1 ? videouplod == '' : selection == 2 ? youtubelink == '' : '')"
                                color="black" class="text-none white--text ml-3"> Send</v-btn>

                        </v-col>

                    </v-row>

                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showComments" persistent max-width="450px">
            <v-card>
                <div class="d-flex">
                    <v-card-title>Comments</v-card-title>
                    <v-btn color="blach" class="ml-auto mt-2 mr-5" icon
                        @click="showComments = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
                <v-card-text>
                    <v-data-table :headers="commtheaders" :items="commentsData" class="elevation-0" outlined>

                    </v-data-table>
                </v-card-text>

            </v-card>
        </v-dialog>

        <v-dialog v-model="deletepop" persistent max-width="450px">
            <v-card elevation="0" outlined class="pa-5">
                <p class="headline font-weight-bold text-center">Are You sure you want to Delete this Post ? </p>
                <v-row>
                    <v-col><v-btn outlined class="text-none" elevation="0" block
                            @click="deletepop = false">Cancel</v-btn></v-col>
                    <v-col><v-btn class="text-none white--text" color="black" elevation="0" :loading="deleteload" block
                            @click="deleteproc()">Delete</v-btn></v-col>

                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="fileuploadpop" persistent max-width="450px">
            <v-card class="pa-3">
                <div class="d-flex">
                    <v-card-title>Upload File</v-card-title>
                    <v-btn color="blach" class="ml-auto mt-2 mr-5" icon
                        @click="fileuploadpop = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
                <v-divider class="mb-3"></v-divider>
                <div class="">
                    <span class="subtitle-2 mt-4">File Name</span>
                    <v-text-field outlined hide-details class="mt-2 mb-3" dense label="file name"
                        v-model="filename"></v-text-field>

                    <span class="subtitle-2 pt-4 mt-4 mb-3">Upload a File</span>
                    <!-- <v-file-input  :rules="fileRules"  v-model="uploadfiledon" outlined dense 
                        label="File input"  @change="checkFileSize()"></v-file-input> -->
                    <!-- <v-form ref="form" v-model="valid"> -->
                    <v-file-input v-model="uploadfiledon" label="Upload a file (max 4MB)" outlined dense ref="fileInput"
                        @change="checkFileSize"></v-file-input>


                    <!-- </v-form> -->
                </div>

                <div class=" mr-3 ml-3 mt-2 ">
                    <v-row>
                        <v-col cols="6">
                            <v-btn rounded block outlined elevation="0" @click="fileuploadpop = false"
                                class="text-none">
                                Cancel</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn rounded block @click="uploadfilees()" :loading="uploadloader" elevation="0"
                                :disabled="filename == '' || uploadfiledon == ''" color="black"
                                class="text-none white--text ml-3">
                                Upload</v-btn>
                        </v-col>
                    </v-row>


                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogviewfile" width="75%">
            <v-card>
                <div class="d-flex">
                    <v-card-title>View File</v-card-title>
                    <!-- <v-spacer></v-spacer> -->
                    <v-btn class="ml-auto mt-3 mr-3" color="primary" elevation="0" icon
                        @click="dialogviewfile = false"><v-icon size="25">mdi-close</v-icon></v-btn>
                </div>
                <!-- <v-card-text> -->
                <!-- <v-responsive> -->
                <iframe v-if="selectedFile" :src="selectedFile" width="100%" height="400px" frameborder="0"></iframe>
                <!-- </v-responsive> -->
                <!-- </v-card-text> -->
                <v-card-actions>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deletefilepop" max-width="450px">
            <v-card elevation="0" outlined class="pa-5">
                <p class="headline font-weight-bold text-center">Are You sure you want to Delete this file ? </p>
                <v-row>
                    <v-col><v-btn outlined class="text-none" elevation="0" block
                            @click="deletefilepop = false">Cancel</v-btn></v-col>
                    <v-col><v-btn class="text-none white--text" color="black" elevation="0" :loading="deleteload" block
                            @click="delefilefun()">Delete</v-btn></v-col>

                </v-row>
            </v-card>
        </v-dialog>


        <v-dialog persistent v-model="dialogclose" width="350">

            <v-card>
                <v-card-title class="text-h5 ">
                    Close Ticket
                </v-card-title>

                <v-card-text>
                    Are you sure you want to close this ticket? </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="black" text @click="dialogclose = false">
                        No
                    </v-btn>
                    <v-btn class="text-none" :loading="closeload" color="black" text
                        @click="closeticketfun(particuladata)">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>
import { feedsurl } from "../apiUrl";
import { ticketurl } from "../apiUrl";
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import exportFromJSON from 'export-from-json';
// import { search } from "core-js/fn/symbol";
export default {
    components: {
        quillEditor
    },
    data() {
        return {
            snackbar: false,
            errMsg: "",
            dialog: false,
            selection: "",
            descritiondata: "",
            imageuplo: [],
            videouplod: "",
            feedloader: false,
            snaclr: "black",
            fullfeedata: [],
            rules: [
                value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
            ],
            headers: [
                {
                    align: 'start',
                    text: 'Post Id', value: 'post_id'
                },

                {
                    text: 'Date & Time',
                    value: 'uploaded_date_time',
                },
                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Title', value: 'title' },
                { text: 'Like count', value: 'liked_counts' },
                { text: 'File Type', value: 'flietype' },
                { text: 'Comment', value: 'viewcommet' },
                { text: 'Delete', value: 'deletefeed' },

            ],
            headersuplo: [
                {
                    align: 'start',
                    text: 'file Id', value: 'collateral_id'
                },

                {
                    text: 'Date & Time',
                    value: 'uploded_date_time',
                },

                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Title', value: 'title' },
                { text: 'view', value: 'viewpage' },
                { text: 'Delete', value: 'deletefeed' },

            ],
            showComments: false,
            commtheaders: [
                { text: 'Comment', value: 'comment' },
                { text: 'Client ID', value: 'comments_client_id' },
                { text: 'Date Time', value: 'comments_date_time' }
            ],
            headersuplolp: [
                { text: 'Date & Time', value: 'register_date' },
                { text: 'Name', value: 'name' },
                { text: 'Email Id', value: 'email' },
                { text: 'Mobile Number', value: 'modilenumber' },
                { text: 'Source', value: 'source' }

            ],
            commentsData: [],
            deletepop: false,
            deletevalue: "",
            deleteload: false,
            feedresloader: true,
            titlepost: "",
            youtubelink: "",
            fileuploadpop: false,
            uploadfiledon: null,
            filename: "",
            uploadloader: false,
            fulluploaddataa: [],
            dialogviewfile: false,
            baseURL: 'https://pdbe.mynt.in/',
            selectedFile: null,
            deletefilepop: false,
            content: '',
            editorOptions: {
                theme: 'snow'
            },
            userdeatdata: "",
            email: "",
            first: "",
            firstt: "",
            //   uploadfiledon: null,
            valid: false,
            uploadFile: null,
            snackbarfie: false,
            padless: false,
            variant: 'default',
            selectedItem: "",
            showlist: [],
            showlistload: true,
            selectparttick: [],
            selectlist: false,

            baseUrl: "https://zebu-pd.web.app//",
            particuladata: [],
            dialogclose: false,
            sendmessload: false,
            closeload: false,
            messagetext: "",
            ticketstatus: "",
            websitedat: [],
            baseUrltic: "https://pdbe.mynt.in/",
            subjectlist: [
                'Account Open Issue',
                'KYC Issue',
                'Dasboard Issue',
                'Others'
            ],
            newlist: '',
            otherfieldsub: '',
            dialogpopp: false,
            dialogImage: '',
            searchQuery: "",
            raiseloader: false,
            filterQuery: '',
            files: [],

            selectedSource: 'All',     // Default filter value
            sourceOptions: ['All', 'Insurance', 'IPO','Partner'],
            searchlpdata:""
        };
    },
    methods: {
        newdownl1() {
            let rows = [];
            //   rows.push({'source' : 'ipo','updateby':'app'});
            for (let t = 0; t < this.websitedat.length; t++) {
                const element = this.websitedat[t];
                rows.push({
                    "Date And Time": element.register_date,
                    "Name": element.name,
                    "Email Id": element.email,
                    "Mobile Number": element.modilenumber,
                    "Source": element.source,
                });
            }
            const exportType = exportFromJSON.types.csv;

            exportFromJSON({
                data: rows,
                fileName: `landing page data`,
                exportType: exportType,
            });
        },
        fullUrl(path) {
            return `https://pdbe.mynt.in/${path}`; // Full URL of the file
        },
        getFilename(path) {
            return path.split('/').pop();  // Extract filename from path
        },
        openDialog(imageUrl) {
            this.dialogImage = imageUrl;
            this.dialogpopp = true;  // Open the dialog
        },
        networkerrfun() {
            this.snackbar = true
            this.errMsg = "Axios:Network Error"
            this.snaclr = "error"
        },

        selectticket(show, particuladata) {
            // this.selectparttick = []
            this.ticketstatus = ''

            this.particuladata = show
            this.selectlist = true
            const axios = require('axios');
            let data = JSON.stringify({

                "ticket_id": show != '' ? show.ticket_id : particuladata
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/show_admin_par_ticket`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    //   console.log(JSON.stringify(response.data));
                    axiosThis.selectlist = false
                    if (response.data.stat === "Ok") {
                        axiosThis.ticketstatus = response.data.closed_by;
                        axiosThis.selectparttick = response.data.data
                    }
                    else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snaclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.selectlist = false
                    this.networkerrfun()
                });

        },

        ticketstaus(show, particuladata) {
            // this.selectparttick = []
            if (show.ticket_status == 'open') {

                this.ticketstatus = ''

                this.particuladata = show
                this.selectlist = true
                const axios = require('axios');
                let data = JSON.stringify({

                    "ticket_id": show != '' ? show.ticket_id : particuladata,
                    "log": "ADMIN"
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${ticketurl}/update_message_status`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                var axiosThis = this
                axios.request(config)
                    .then((response) => {
                        //   console.log(JSON.stringify(response.data));
                        axiosThis.selectlist = false
                        if (response.data.stat === "Ok") {
                            console.log('.');

                        }
                        else {
                            axiosThis.snackbar = true
                            axiosThis.errMsg = response.data
                            axiosThis.snaclr = "error"
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        this.selectlist = false
                        this.networkerrfun()
                    });
            }
        },


        closeticketfun(particuladata) {
            this.closeload = true
            const axios = require('axios');
            let data = JSON.stringify({
                "ticket_id": particuladata.ticket_id,
                "log": `admin ${this.first}`
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/ticket_status_updated`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    axiosThis.closeload = false
                    axiosThis.dialogclose = false
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.msg
                        axiosThis.snaclr = "green"
                        axiosThis.querymessage = ""
                        axiosThis.files = {}
                        setTimeout(() => {
                            // axiosThis.ticketlistfun()
                            axiosThis.showticketlist()
                            axiosThis.selectticket(particuladata)

                        }, 500);

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snaclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.closeload = false
                    axiosThis.dialogclose = false
                    axiosThis.networkerrfun()
                });


        },

        showticketlist() {
            this.showlist = []
            const axios = require('axios');


            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/show_admin_outlook`,
                headers: {

                },

            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.showlistload = false
                    // console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok") {


                        axiosThis.showlist = response.data.data
                        axiosThis.showlist = response.data.data
                        if (axiosThis.showlist.length > 0) {
                            axiosThis.selectticket(axiosThis.showlist[0]);
                        }

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.showlistload = false
                    axiosThis.networkerrfun()
                });

        },





        messagesendfun(particuladata) {
            this.raiseloader = true,

                this.sendmessload = true

            const axios = require('axios');
            const FormData = require('form-data');

            let data = new FormData();
            data.append('sender_id', "ADMIN");
            data.append('message', this.messagetext);
            data.append('replied', 'yes');
            // data.append('ticket_id', "");
            data.append('reciver_id', particuladata.pd_id);
            data.append('reply_ticket_id', particuladata.ticket_id);
            data.append('subject', particuladata.subject);

            data.append('files', this.files);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${ticketurl}/raiseticket`,
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.sendmessload = false
                    axiosThis.raiseloader = false

                    //   console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok" || response.data.msg == 'sent successfully') {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.msg
                        axiosThis.snaclr = "green"
                        axiosThis.messagetext = ""
                        axiosThis.files = {}
                        setTimeout(() => {
                            axiosThis.selectticket(particuladata)
                            axiosThis.ticketstaus(particuladata)

                            // axiosThis.showticketlist()
                        }, 500);

                    }
                    else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.sendmessload = false
                    axiosThis.raiseloader = false

                    axiosThis.networkerrfun()

                });

        },

        websiteadmin() {
            this.websitedat = []
            const axios = require('axios');

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://zebu.mynt.in/get_admin_data',
                headers: {}
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok") {
                        axiosThis.websitedat = response.data.data
                        // this.initializeSourceOptions();
                        // this.applyFilter();

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.networkerrfun()

                });

        },
      

        


        // --------------------------------------------------------------------------------------------
        checkFileSize() {
            if (this.uploadfiledon && this.uploadfiledon.size >= 4000000) {
                this.uploadfiledon = {};


                this.$nextTick(() => {
                    if (this.$refs.fileInput) {
                        this.$refs.fileInput.$el.querySelector('input').value = '';
                    }
                });
                this.snackbarfie = true;
            }

            if (Array.isArray(this.imageuplo)) {
                for (let file of this.imageuplo) {
                    if (file.size >= 4000000) {
                        this.showFileSizeError();
                        return;
                    }
                }
            } else if (this.imageuplo && this.imageuplo.size >= 4000000) {
                this.showFileSizeError();
                this.snackbarfie = true;
                console.log('elseeiffff');
            }


            if (this.videouplod && this.videouplod.size >= 4000000) {
                this.videouplod = {};
                this.$nextTick(() => {
                    if (this.$refs.fileInput) {
                        this.$refs.fileInput.$el.querySelector('input').value = '';
                    }
                });
                this.snackbarfie = true;
            }
        },
        showFileSizeError() {
            console.log("insideeee - file too large");
            this.snackbarfie = true;

            this.imageuplo = Array.isArray(this.imageuplo) ? [] : {};
            this.$nextTick(() => {
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.$el.querySelector('input').value = '';
                }
            });

            //   this.snackbarfie = true; 
        },

        openFilesInNewTab(filePaths) {
            if (!Array.isArray(filePaths)) {
                console.error('Invalid filePaths input. Expected an array of file paths.');
                return;
            }

            filePaths.forEach(filePath => {
                if (filePath) {
                    const fullURL = this.baseURL + filePath;

                    try {
                        console.log(`Attempting to open in new tab: ${fullURL}`);

                        // Open the file URL in a new tab
                        window.open(fullURL, '_blank');
                    } catch (error) {
                        console.error('Error opening file:', error);
                    }
                }
            });
        },
        logOut() {
            localStorage.clear();
            window.location.assign("/adminlogin");
        },
        onEditorChange({ html }) {
            //   console.log('Editor content changed:', html);
            this.content = html;
        },
        openPopup(file) {
            // Set the selected file and open the dialog
            this.selectedFile = this.baseURL + file;
            this.dialogviewfile = true;
        },
        feedresposdata() {
            this.feedresloader = true
            this.fullfeedata = []
            const axios = require('axios');
            let data = JSON.stringify({
                "client_id": ""
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/show_feeds`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    axiosThis.feedresloader = false

                    // console.log(JSON.stringify(response.data));
                    // axiosThis.fullfeedata = response.data.data
                    axiosThis.fullfeedata = response.data.data.map(post => ({
                        ...post,
                        showComment: false
                    }));
                    // console.log(axiosThis.fullfeedata);


                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.feedresloader = false

                });

        },
        uploadfeed() {
            // const formattedMessage = this.descritiondata.trim()
            this.feedloader = true
            const axios = require('axios');
            const FormData = require('form-data');
            // const fs = require('fs');
            let data = new FormData();
            data.append('title', this.titlepost);
            data.append('text_data', this.content);
            data.append('uploaded_by', this.first);
            data.append('video_ext', this.selection == 1 ? "mp4" : "");
            if (this.selection === 0 && this.imageuplo) {

                Array.from(this.imageuplo).forEach(file => {
                    data.append('images', file);
                });
            }

            data.append('videos', this.selection == 1 ? this.videouplod : "");
            data.append('youtube_links', this.selection == 2 ? this.youtubelink : "None");



            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/upload_feeds`,
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.feedloader = false
                    axiosThis.dialog = false
                    axiosThis.clearfuntion()
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "green"
                        axiosThis.feedresposdata()

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.feedloader = false
                    axiosThis.dialog = false
                    axiosThis.snackbar = true
                    axiosThis.errMsg = error
                    axiosThis.snaclr = "error"
                });

        },

        uploadfilees() {

            this.uploadloader = true
            const axios = require('axios');
            const FormData = require('form-data');
            // const fs = require('fs');
            let data = new FormData();
            data.append('title', this.filename);
            data.append('uploaded_by', this.first);
            data.append('files', this.uploadfiledon);



            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/collateral_upload`,
                headers: {

                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.uploadloader = false
                    axiosThis.fileuploadpop = false
                    axiosThis.clearfuntion()
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "green"
                        axiosThis.showuploadfiles()
                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "error"
                    }

                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.uploadloader = false
                    axiosThis.fileuploadpop = false
                    axiosThis.snackbar = true
                    axiosThis.errMsg = error
                    axiosThis.snaclr = "error"


                });

        },

        clearfuntion() {
            this.uploadfiledon = ""
            this.filename = ""
            this.youtubelink = ""
            this.videouplod = ""
            this.imageuplo = ""
            this.descritiondata = ""
            this.titlepost = ""
            this.content = ""

        },

        showuploadfiles() {
            this.fulluploaddataa = []
            const axios = require('axios');
            let data = '';

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/download_collateral`,
                headers: {},
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.stat == "Ok") {
                        axiosThis.fulluploaddataa = response.data.data

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.snackbar = true
                    axiosThis.errMsg = error
                    axiosThis.snaclr = "error"
                });

        },


        commnet(item) {
            this.showComments = true;

            // Check if comments, comments_client_id, and comments_date_time are arrays
            if (Array.isArray(item.comments) && Array.isArray(item.comments_client_id) && Array.isArray(item.comments_date_time)) {
                this.commentsData = item.comments.map((comment, index) => {
                    return {
                        comment: comment,
                        comments_client_id: item.comments_client_id[index],
                        comments_date_time: item.comments_date_time[index]
                    };
                });
            } else {
                this.commentsData = [];
            }
        },
        deletefun(item) {
            this.deletepop = true
            this.deletevalue = item.post_id
        },
        deleteproc() {
            this.deleteload = true
            const axios = require('axios');
            let data = JSON.stringify({
                "post_id": this.deletevalue,
                "mail_id": this.first
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/delete_feeds`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.deleteload = false
                    axiosThis.deletepop = false
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "green"
                        axiosThis.feedresposdata()
                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.deleteload = false
                    axiosThis.deletepop = false
                });

        },
        deletefileconn(item) {
            this.deletefilepop = true
            this.deletevalue = item.collateral_id
        },
        delefilefun() {
            this.deleteload = true
            const axios = require('axios');
            let data = JSON.stringify({
                "collateral_id": this.deletevalue,
                "mail_id": this.first

            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${feedsurl}/delete_collateral`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            var axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    axiosThis.deleteload = false
                    axiosThis.deletefilepop = false
                    if (response.data.stat == "Ok") {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "green"
                        axiosThis.showuploadfiles()
                        // this.showuploadfiles()

                    } else {
                        axiosThis.snackbar = true
                        axiosThis.errMsg = response.data.data
                        axiosThis.snaclr = "error"
                    }
                })
                .catch((error) => {
                    console.log(error);
                    axiosThis.deleteload = false
                    axiosThis.deletefilepop = false
                    axiosThis.snackbar = true
                    axiosThis.errMsg = error
                    axiosThis.snaclr = "error"
                });

        }


    },
    mounted() {
        this.first = localStorage.getItem("adminemail");
        this.firstt = localStorage.getItem("adminname");
        if (this.first == null & this.firstt == null) {
            this.$router.push("/adminlogin");
        }
        this.feedresposdata()
        this.showuploadfiles()
        this.showticketlist()
        this.websiteadmin()

    },
    computed: {
       
        fileSizeRules() {
            return [
                v => !v || v.size < 2000000 || 'File size should be less than 4MB',
            ];
        },
        filteredShowlist() {
            const query = this.searchQuery.toLowerCase();

            return this.showlist.filter(ticket =>
                (ticket.subject && ticket.subject.toLowerCase().includes(query)) ||
                (ticket.ticket_status && ticket.ticket_status.toLowerCase().includes(query)) ||
                (ticket.pd_id && ticket.pd_id.toLowerCase().includes(query)) ||
                (this.filterQuery && (ticket.subject === this.filterQuery ||
                    ticket.ticket_status === this.filterQuery ||
                    ticket.pd_id === this.filterQuery))
            );
        },


        autocompleteItems() {
            const subjects = this.showlist.map(ticket => ticket.subject).filter(Boolean);
            const statuses = this.showlist.map(ticket => ticket.ticket_status).filter(Boolean);
            const pd_ids = this.showlist.map(ticket => ticket.pd_id).filter(Boolean);

            return [...new Set([...subjects, ...statuses, ...pd_ids.map(id => id.toUpperCase())])];
        },

        localAttrs() {
            const attrs = {}

            if (this.variant === 'default') {
                attrs.absolute = false
                attrs.fixed = false
            } else {
                attrs[this.variant] = true
            }
            return attrs
        },
    },
}
</script>
<style scoped>
.row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: 0px;
}
</style>
