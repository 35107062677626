export const apiurl = "https://pdbe.mynt.in"
// export const apiurl = "http://192.168.5.189:8081"
// export const apiurl = "http://192.168.5.163:5512"

// export const feedsurl = "http://192.168.5.107:5555"
export const feedsurl = "https://pdbe.mynt.in"
// export const ticketurl = "http://192.168.5.107:7000"
export const ticketurl = "https://pdbe.mynt.in"



