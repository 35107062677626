<template>
  <div class="signupbgclr">
    <v-container fill-height class="SsoView">
      <v-snackbar style="z-index: 6 !important" transition="slide-x-reverse-transition" v-model="snackbar" timeout="2000"
        :value="true" class="pt-6 pr-6" color="warning" absolute outlined top right>
        <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon>
        {{ errmsg }}
      </v-snackbar>
      <v-card max-width="400px" height="80vh" class="mx-auto my-auto elevation-0 py-8 pb-1 rounded-lg text-center">
        <div class="px-4 px-sm-6 px-md-8">
          <img src="@/assets/zebulogo.svg" width="30%" />

          <div>
            <v-card width="400px" class="mx-auto px-6 elevation-0 text-center">
              <h3 class="pa-6">Login with Zebu Partner</h3>
              <v-form v-if="inputForm" ref="form" v-model="valid" lazy-validation @submit.prevent="validate()">
                <v-text-field v-model="userId" :rules="userIdRules" label="User Id" required
                  oninput="this.value = this.value.toUpperCase()" outlined dense></v-text-field>

                <v-btn :disabled="!valid" :loading="btnloading" block class="btn my-2 rounded-md" color="primary"
                  type="submit"><b> Submit</b>
                </v-btn>
              </v-form>
              <!-- <div v-if="otpInput"> -->
              <v-form v-if="otpInput" ref="formm" v-model="validd" lazy-validation @submit.prevent="validateotp()">
                <p>
                  Enter the 4-digit OTP received on your registered email or
                  mobile
                </p>

                <v-otp-input :disabled="btnloadingg" v-model="otp" @change="validateotp" :length="length"
                  dense></v-otp-input>
                <!-- <v-btn :disabled="!isActive" :loading="btnloadingg" class="btn my-2 rounded-md" block color="primary"
                type="submit">
                Submit
              </v-btn> -->
              </v-form>
              <!-- </div> -->
            </v-card>
            <v-card-subtitle style="font-size: 11px" class="text-center">
              <p style="color: #858b95">
                By continuing with ZEBU, you agree our Terms and Privacy Policy
              </p>
            </v-card-subtitle>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { apiurl } from "./../apiUrl.js";

export default {
  data: () => ({
    show1: false,
    btnloadingg: false,
    btnloading: false,
    errmsg: "",
    snackbar: false,
    valid: true,
    validd: true,

    length: 4,
    otp: "",
    inputForm: true,
    otpInput: false,
    userId: "",
    userIdRules: [(v) => !!v || "User ID is required"],
    otpRules: [(v) => !!v || "OTP is required"],
    password: "",
    passwordRules: [
      (v) => !!v || "Your mobile number is required",
      (v) => /^[6-9]\d{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
  }),
  computed: {
    isActive() {
      return this.otp.length === this.length;
    },
  },
  created() {
    var url = new URL(window.location.href);
    var ids = url.searchParams.get("pdid");
    var key = url.searchParams.get("key");

    if (ids && key) {
      this.userId = ids;
      this.otp = key;
      this.validateotp('bug')
    } else {
      this.bugValid(ids)

    }
  },
  methods: {
    bugValid(ids) {
      var body = JSON.stringify({
        code: ids,
      });
      var config = {
        method: "post",
        url: `${apiurl}/client_verify1`,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
    //  let axiosthis = this
      axios(config)
        .then(function () {
          // console.log(JSON.stringify(response.data));
          // if (response.data.msg != "Invalid key") {
          //   axiosthis.snackbar = true;
          //   axiosthis.errmsg = "Invalid Partner ID";
          //   axiosthis.btnloading = false;
          // } else {
          //   axiosthis.snackbar = true;
          //   axiosthis.errmsg = "Invalid Partner ID";
          //   axiosthis.btnloading = false;
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validate() {
      this.$refs.form.validate();
      var axiosthis = this;
      if (this.$refs.form.validate()) {
        this.btnloading = true;
        var body = JSON.stringify({
          code: this.userId,
        });
        var config = {
          method: "post",
          url: `${apiurl}/client_verify`,
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        };
        console.log("method", config);
        axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            if (response.data.msg == "Invalid key") {
              axiosthis.snackbar = true;
              axiosthis.errmsg = "Invalid Partner ID";
              axiosthis.btnloading = false;
            } else {
              axiosthis.inputForm = false;
              axiosthis.otpInput = true;
              axiosthis.btnloading = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            axiosthis.btnloading = false;
          });
      }
    },
    validateotp(bug) {
      //   console.log("validate", this.$refs.formm.validate());

      if (bug == 'bug' || this.$refs.formm.validate()) {
        this.btnloadingg = true;
        var axiosthis = this;
        var data = JSON.stringify({
          code: this.userId,
          otp: this.otp,
        });
        var config = {
          method: "post",
          url: `${apiurl}/otp_verify`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            // console.log(response.data);
            if (response.data.res.stat == "ok") {
              // let config = {
              //   method: "post",
              //   url: `${apiurl}/updateleder?code=${axiosthis.userId}`,
              //   headers: {},
              // };
              // axios
              //   .request(config)
              //   .then((response) => {
              //     console.log(response.data);
              //     // if (response.data.emsg == "New client") {
              //     //   setTimeout(() => {
              //     //     axiosthis.$router.push("/dashboard");
              //     //   }, 20000);
              //     // } else {
              //     //   axiosthis.$router.push("/dashboard");
              //     // }
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              localStorage.setItem("clientid", response.data.res.clientid);
              localStorage.setItem("email", response.data.res.email);
              localStorage.setItem("mobile", response.data.res.mobile);
              localStorage.setItem("session", response.data.res.token);
              axiosthis.$router.push("/dashboard");
            } else if (response.data.res == "Invalid otp") {
              axiosthis.snackbar = true;
              axiosthis.errmsg = "Invalid OTP";
              axiosthis.btnloadingg = false;
              axiosthis.otp = "";
            } else {
              axiosthis.snackbar = true;
              axiosthis.errmsg = response.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },

  mounted() {
    localStorage.clear();
  },
};
</script>
<style>
.btn {
  box-shadow: 0px 8px 16px 2px rgba(22, 34, 51, 0.08) !important;
}

.signupbgclr {
  background-color: #eceef0 !important;
  height: 100vh !important;
  overflow-y: hidden !important;
}

.SsoView {
  height: 100vh !important;
  overflow: auto !important;
}

@media only screen and (max-width: 600px) {
  .signupbgclr {
    background-color: white !important;
  }

  .btn:hover {
    background-color: #eceef0 !important;
  }
}
</style>