<template>
    <div>
        <v-row class="pa-4">
            <v-col sm="12" md="6" lg="6">
                <v-card class="pa-4">
                    <v-card-title>
                        State
                    </v-card-title>
                    <div id="pieChartState">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>

                </v-card>
            </v-col>
            <v-col sm="12" md="6" lg="6"> <v-card class="pa-4"> <v-card-title>
                        City
                    </v-card-title>
                    <div id="pieChartCity">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>

            </v-col>
        </v-row>
        <v-row class="pa-4">
            <v-col sm="12" md="6" lg="6">

                <v-card class="pa-4">
                    <v-card-title>
                        Occupation
                    </v-card-title>
                    <div id="barChartQual">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-col>
            <v-col sm="12" md="6" lg="6">
                <v-card class="pa-4">
                    <!-- <canvas id="myChart" ref="horizBarChart"></canvas> -->
                    <v-card-title>
                        Qualification
                    </v-card-title>
                    <div id="barChartOccup">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-col>
        </v-row> <v-row class="pa-4">
            <v-col sm="12" md="6" lg="6">

                <v-card class="pa-4">
                    <v-card-title>
                        Gender
                    </v-card-title>
                    <div id="genderBarChart">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-col>
            <v-col sm="12" md="6" lg="6">
                <v-card class="pa-4">
                    <!-- <canvas id="myChart" ref="horizBarChart"></canvas> -->
                    <v-card-title>
                        Age
                    </v-card-title>
                    <div id="ageBarChart">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-col>
        </v-row><v-row class="pa-4">
            <v-col sm="12" md="6" lg="6">

                <v-card class="pa-4">
                    <v-card-title>
                        Active
                    </v-card-title>
                    <div id="isActiveBarChart">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                </v-card>
            </v-col>
            <v-col sm="12" md="6" lg="6">

            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import ApexCharts from 'apexcharts';
import { apiurl } from '../apiUrl.js'

// import Chart from 'chart.js/auto';

export default {
    data() {
        return {
            userid: "",

            items: ['2021-2022', '2022-2023', '2023-2024'],
            select: '2022-2023',
            Analysis_data: [],
            Analysis_dataState: [],
            Analysis_dataGender: [],
            Analysis_dataQual: [],
            Analysis_dataOccu: [],
            Analysis_dataAct: [],
            Analysis_dataAge: [],
            Analysis_dataCity: [],
            Analysis_dataOccuKey: [],
            Analysis_dataOccuValues: [],
            Analysis_dataQualKey: [],
            Analysis_dataQualValues: [],
            Analysis_dataCityKey: [],
            Analysis_dataCityValue: [],
            Analysis_dataStateKey: [],
            Analysis_dataStateValue: [], Analysis_dataAgeKey: [],
            Analysis_dataAgeValues: [],
            Analysis_dataGenderKey: [],
            Analysis_dataGenderValue: [],
            Analysis_dataActiveKey: [],
            Analysis_dataActiveValue: [],
        };
    },
    mounted() {

        this.clientCode = localStorage.getItem("clientid")
        this.session = localStorage.getItem("session")
        if ((this.clientCode) && (this.session)) {
            this.emailId = localStorage.getItem("email")
            this.mobile = localStorage.getItem("mobile")
            console.log("declocalstore mounted", this.clientCode);
            this.analyseData();



        } else {
            this.$router.push("/")
        }

    },
    methods: {

        horiBarChartGender() {
            var genderBarChartOptions = {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        borderRadius: 2, distributed: true, expandOnClick: true
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: this.Analysis_dataGenderKey
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                series: [{
                    data: this.Analysis_dataGenderValue,
                }],

            }
            var genderBarChart = new ApexCharts(document.querySelector("#genderBarChart"), genderBarChartOptions);
            genderBarChart.render();
        }, horiBarChartAge() {
            var ageBarChartOptions = {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        columnWidth: '10%',
                        endingShape: 'rounded',
                        borderRadius: 2, distributed: true, expandOnClick: true
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: this.Analysis_dataAgeKey
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                series: [{
                    data: this.Analysis_dataAgeValues,
                }],

            }
            var ageBarChart = new ApexCharts(document.querySelector("#ageBarChart"), ageBarChartOptions);
            ageBarChart.render();
        },
        horiBarChartActive() {
            var isactiveBarChartOptions = {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        borderRadius: 2, distributed: true, expandOnClick: true
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: this.Analysis_dataActiveKey
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                series: [{
                    data: this.Analysis_dataActiveValue,
                }],

            }
            var isActiveBarChart = new ApexCharts(document.querySelector("#isActiveBarChart"), isactiveBarChartOptions);
            isActiveBarChart.render();
        },

        donutChartState() {
            var pieChartOptionsState = {
                dataLabels: {
                    enabled: false,
                },
                series: this.Analysis_dataStateValue,
                labels: this.Analysis_dataStateKey,
                chart: {
                    type: 'donut',
                }, plotOptions: {
                    pie: {
                        customScale: 0.8, distributed: true,
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                },
                                value: {
                                }
                            }

                        }, expandOnClick: true
                    }
                }
            }
            var pieChartState = new ApexCharts(document.querySelector("#pieChartState"), pieChartOptionsState);
            pieChartState.render();
        },
        donutChartCity() {
            var pieChartOptionsCity = {
                dataLabels: {
                    enabled: false,
                },
                series: this.Analysis_dataCityValue,
                labels: this.Analysis_dataCityKey,
                chart: {
                    type: 'donut',
                }, plotOptions: {
                    pie: {
                        customScale: 0.8, distributed: true,
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                },
                                value: {
                                }
                            }

                        }, expandOnClick: true
                    }
                }
            }
            var pieChartCity = new ApexCharts(document.querySelector("#pieChartCity"), pieChartOptionsCity);
            pieChartCity.render();
        },

        horiBarChartQual() {
            var qualBarChartOptions = {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        columnWidth: '10%',
                        endingShape: 'rounded',
                        borderRadius: 2, distributed: true, expandOnClick: true
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: this.Analysis_dataOccuKey,

                }, tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                series: [{
                    data: this.Analysis_dataOccuValues,
                }],

            }
            var qualBarChart = new ApexCharts(document.querySelector("#barChartQual"), qualBarChartOptions);
            qualBarChart.render();
        },

        horiBarChartOccup() {
            var occupBarChartOptions = {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        columnWidth: '10%',
                        endingShape: 'rounded',
                        borderRadius: 2, distributed: true, expandOnClick: true
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    categories: this.Analysis_dataQualKey
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                series: [{
                    data: this.Analysis_dataQualValues,
                }],

            }
            var occupBarChart = new ApexCharts(document.querySelector("#barChartOccup"), occupBarChartOptions);
            occupBarChart.render();
        },

        analyseData() {
            var clientCode = localStorage.getItem('clientid')
            var usession = localStorage.getItem('session')
            var data = JSON.stringify({
                "code": clientCode
            });
            var axiosThis = this

            var config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: `${apiurl}/analysis_data`,
                // url: 'http://192.168.5.163:5512/analysis_data',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': usession

                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log("ANALYSIS DATA: ", response.data);
                    axiosThis.Analysis_data = response.data
                    axiosThis.Analysis_dataState = response.data.STATE
                    axiosThis.Analysis_dataGender = response.data.GENDER
                    axiosThis.Analysis_dataCity = response.data.CITY
                    axiosThis.Analysis_dataAge = response.data.AGE

                    console.log('Gender', axiosThis.Analysis_dataGender)
                    axiosThis.Analysis_dataAct = response.data.ACTIVE_INACTIVE_STATUS
                    axiosThis.Analysis_dataOccu = response.data.OCCUPATION
                    axiosThis.Analysis_dataQual = response.data.QUALIFICATION
                    axiosThis.Analysis_dataOccuKey = Object.keys(axiosThis.Analysis_dataOccu)
                    axiosThis.Analysis_dataOccuValues = Object.values(axiosThis.Analysis_dataOccu)

                    axiosThis.Analysis_dataQualKey = Object.keys(axiosThis.Analysis_dataQual)
                    axiosThis.Analysis_dataQualValues = Object.values(axiosThis.Analysis_dataQual)
                    axiosThis.Analysis_dataCityKey = Object.keys(axiosThis.Analysis_dataCity)
                    axiosThis.Analysis_dataCityValue = Object.values(axiosThis.Analysis_dataCity)
                    axiosThis.Analysis_dataStateKey = Object.keys(axiosThis.Analysis_dataState)
                    axiosThis.Analysis_dataStateValue = Object.values(axiosThis.Analysis_dataState)


                    axiosThis.Analysis_dataAgeKey = Object.keys(axiosThis.Analysis_dataAge)
                    axiosThis.Analysis_dataAgeValues = Object.values(axiosThis.Analysis_dataAge)
                    axiosThis.Analysis_dataActiveKey = Object.keys(axiosThis.Analysis_dataAct)
                    axiosThis.Analysis_dataActiveValue = Object.values(axiosThis.Analysis_dataAct)
                    axiosThis.Analysis_dataGenderKey = Object.keys(axiosThis.Analysis_dataGender)
                    axiosThis.Analysis_dataGenderValue = Object.values(axiosThis.Analysis_dataGender)
                    console.log('Gender Value', axiosThis.Analysis_dataGenderValue)

                    axiosThis.horiBarChartOccup();
                    axiosThis.horiBarChartQual();
                    axiosThis.donutChartCity();
                    axiosThis.donutChartState(); axiosThis.horiBarChartActive();
                    axiosThis.horiBarChartGender();
                    axiosThis.horiBarChartAge();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

